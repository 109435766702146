export const QUERY_KEYS = {
  code_details: 'code_details',
  suggestions: 'suggestions',
  search: 'search',
  search_provider_details: 'search_provider_details',
  search_map: 'search_map',
  saved_searches: 'saved_searches',
  SEARCH_RESULTS: 'SEARCH_RESULTS',
  SEARCH_RESULTS_PROVIDER: 'SEARCH_RESULTS_PROVIDER',
};
