import { useRouter } from 'next/router';
import { SideNav } from 'src/components';
import Filters from '../Filters';
import { Box } from '@mui/material';
import { useProfile } from 'src/context';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const categoryTitles = {
  affiliations: 'Provider Affiliations',
  drg: 'DRG Procedures',
  apc: 'APC Procedures',
  cpt: 'CPT® Procedures',
  icd: 'ICD Diagnoses',
  icdp: 'ICD Procedures',
  hcpcs: 'HCPCS Level II',
  prescriptions: 'Prescriptions',
  patient_patterns: 'Referrals',
  payments: 'Open Payments',
  education: 'Education Network',
  relationships: 'Relationships',
  referrals: '2015 Inbound Referrals',
};

const CategoryNav = () => {
  const { newProfileHeader, useNewProviderAffiliations } = useFlags();
  const router = useRouter();
  const { details } = useProfile();

  const { available_data_categories: categories } = details;
  const { id, type, view } = router.query;
  const profileCategories = [
    {
      label: 'Profile',
      path: `/profiles/${type}/${id}`,
      id: null,
    },
    ...Object.keys(categoryTitles)
      .filter(
        (view) =>
          !!categories[view] ||
          !!categories[`inbound_${view}`] ||
          !!categories[`outbound_${view}`]
      )
      .map((view) => ({
        label: categoryTitles[view],
        path: `/profiles/${type}/${id}/${view}`,
        id: view,
      })),
  ];

  const canShowFilters = !useNewProviderAffiliations || view !== 'affiliations';
  return (
    !newProfileHeader && (
      <Box sx={{ display: 'flex' }}>
        <SideNav options={profileCategories} />
        {canShowFilters && <Filters />}
      </Box>
    )
  );
};

export default CategoryNav;
