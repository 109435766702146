import React from 'react';
import { Box, Typography } from '@mui/joy';

const NewVolumeHeader = ({ field, ...props }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '0px 0.5rem',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <Typography
        level="title-sm"
        sx={{
          fontWeight: 600,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          width: '100%',
        }}
      >
        {props?.colDef?.headerName}
      </Typography>
    </Box>
  );
};

export default NewVolumeHeader;
