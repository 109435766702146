import { Box } from '@mui/joy';
import React from 'react';

const CardRow = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #E0E0E0',
        padding: '0.25rem 0',
        '&: last-of-type': {
          borderBottom: 'none',
        },
      }}
    >
      {children}
    </Box>
  );
};

export default CardRow;
