import { useQuery, UseQueryResult } from 'react-query';
import { request } from 'src/api/request';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { INTEGRATION_KEYS } from './constants';
interface GetMappingsProps extends MedScout.Pagination, MedScout.Sortable {
  crm?: string;
  enabled?: boolean;
}

interface GetMappingsResponse extends MedScout.PaginatedResponse {
  results: MedScout.Mapping[];
}

const useGetMappings = ({
  sort,
  order,
  page,
  pageSize,
  crm,
  enabled = true,
}: GetMappingsProps) => {
  const queryParams = getSearchQueryParamsForAPI({
    sort,
    order,
    page: page + 1,
    pageSize,
    crm,
  });
  return useQuery(
    [INTEGRATION_KEYS.GET_MAPPINGS, queryParams],
    () => {
      return request({
        url: `/v1/integrations/mappings/${queryParams}`,
        method: 'GET',
      });
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled,
    }
  ) as UseQueryResult<GetMappingsResponse>;
};

export default useGetMappings;
