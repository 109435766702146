import { Button } from 'react-bootstrap';
import { FaBullhorn } from 'react-icons/fa';

const ReportDataIssueButton = ({ onClick }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <Button size="sm" variant="link" onClick={onClick}>
        <FaBullhorn style={{ marginBottom: 2, marginRight: 6 }} />
        Report Data Issue
      </Button>
    </div>
  );
};

export default ReportDataIssueButton;
