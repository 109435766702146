import useSWR from 'swr';
import { QUERY_KEY } from './constants';
import { getProfileSummary } from './profileApi';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { useProspectSearch } from 'src/context';

interface UseGetFocusedProps {
  id: string;
}

const useGetFocused = ({ id }: UseGetFocusedProps) => {
  const { prospectSearch } = useProspectSearch();
  const queryParams = getSearchQueryParamsForAPI({
    saved_search: prospectSearch?.id,
    biographical: 0,
  });
  return useSWR(
    [QUERY_KEY.FOCUSED, id, queryParams],
    () => getProfileSummary(id, queryParams),
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false, //TODO:  we may want to remove this, but it was causing a lot of re-renders
    }
  );
};

export default useGetFocused;
