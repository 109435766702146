import useSWR from 'swr';
import { getActivityById } from 'src/api';
import { QUERY_KEYS } from './constants';

const useGetActivitiesById = (activityId: string) => {
  const shouldFetch = !!activityId;
  return useSWR(
    shouldFetch ? [QUERY_KEYS.GET_ACTIVITY_BY_ID, activityId] : null,
    () => getActivityById({ activityId })
  );
};

export default useGetActivitiesById;
