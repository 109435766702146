import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useGetNotes, createNote } from 'src/hooks';
import { useLogging, useNotification, useProfile } from 'src/context';
import { Box, Button, Card, CardContent, List } from '@mui/joy';
import { StickyNote2Rounded } from '@mui/icons-material';
import { amber } from '@mui/material/colors';
import { ControlledJoyTextarea } from 'src/components';
import NoteItem from './NoteItem';

const Notes = () => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const { details } = useProfile();
  const { data: notes, mutate } = useGetNotes(details?.provider_id);

  const [isNotesLoading, setIsNotesLoading] = React.useState(false);

  const methods = useForm({
    values: {
      note: '',
    },
  });

  const {
    reset,
    handleSubmit,
    formState: { isDirty, isValid },
  } = methods;

  const onSubmit = async (data: { note: string }) => {
    if (!data) return;
    setIsNotesLoading(true);
    try {
      await createNote(Number(details?.provider_id), data);
      log.event('profileSaveNoteSubmitted', {
        providerId: details?.provider_id,
        note: data.note,
      });
      setNotification({
        title: 'Success',
        message: 'Note added successfully',
        type: 'success',
      });
    } catch (err) {
      log.exception('Error adding note', {
        tags: {
          providerId: details?.provider_id,
          note: data.note,
        },
      });
      setNotification({
        title: 'Error',
        message: 'Error adding note',
        type: 'error',
      });
    } finally {
      setIsNotesLoading(false);
      reset();
      mutate();
    }
  };

  return (
    <Card>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
        <StickyNote2Rounded fontSize="small" sx={{ color: amber[500] }} />
        <span>Notes</span>
      </Box>
      <CardContent>
        <FormProvider {...methods}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
            <ControlledJoyTextarea
              name="note"
              placeholder="Add a note"
              aria-label="New Note Input"
            />
            <Button
              loading={isNotesLoading}
              variant="solid"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={!isDirty || !isValid}
              aria-label="Save Note Button"
            >
              Save
            </Button>
          </Box>
        </FormProvider>
        <List sx={{ maxHeight: 200, overflowY: 'auto' }}>
          {notes?.results?.map((note) => (
            <NoteItem key={note.id} note={note} />
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default Notes;
