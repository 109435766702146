import React, { useEffect, useState } from 'react';
import { Box } from '@mui/joy';
import { useRouter } from 'next/router';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { getHexUrl } from 'src/api';
import { ReportIFrame } from './components';

const CenterCaseLeakage = () => {
  const router = useRouter();
  const { id } = router.query as { id: string };
  const [hexUrl, setHexUrl] = useState(null);

  useEffect(() => {
    if (id) {
      handleHexUrl();
    }
  }, [id]);

  const handleHexUrl = async () => {
    const queryParams = getSearchQueryParamsForAPI({
      provider_id: id,
    });

    try {
      const response = await getHexUrl({
        reportId: 'hospital_leakage',
        queryParams,
      });
      if (response) {
        setHexUrl(response);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box
      sx={{
        height: '100%',
      }}
    >
      <ReportIFrame url={hexUrl?.embedded_url} />
    </Box>
  );
};

export default CenterCaseLeakage;
