import { Box, CircularProgress } from '@mui/joy';
import React from 'react';

const CardLoadingIndicator = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(255, 255, 255, 0.8)',
        zIndex: 1,
      }}
    >
      <CircularProgress color="neutral" size="sm" />
    </Box>
  );
};

export default CardLoadingIndicator;
