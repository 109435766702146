import { styled } from '@mui/material';

const SectionHeader = styled('div')`
  display: flex;
  align-items: center;
  padding: 0 1rem 0.5rem;
  margin-bottom: 0.25rem;
  & > svg {
    margin-right: 0.5rem;
  }
`;

export default SectionHeader;
