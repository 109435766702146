import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  useLogging,
  useNotification,
  useProspectSearch,
  useProspectSelection,
  useSite,
} from 'src/context';
import {
  DataGridPremium,
  GridColumnMenuProps,
  GridFilterModel,
  GridRowId,
  useGridApiRef,
  GridColumnMenu,
  GridColDef,
} from '@mui/x-data-grid-premium';
import {
  baseColumns,
  NewTableToolbar,
  TableCheckbox,
  TableColumn,
  TableFooter,
  TableLoadingIndicator,
  TableSettings,
  NewTablePrimaryHeader,
  NewTableControls,
} from 'src/components';
import { GridSortDirection } from '@mui/x-data-grid-premium';
import {
  useLocalStorage,
  useGetReferringOut,
  useGetSavedSearches,
  useGetMappings,
  useSyncTableScroll,
  useSyncRowHovered,
} from 'src/hooks';

import { useRouter } from 'next/router';
import { NoVolumeOverlay } from '../components/NoVolumeOverlay';
import { TableVolumeHeader } from '../components/TableVolumeHeader';
import { grey } from '@mui/material/colors';
import {
  PrimaryTableContainer,
  InnerContainer,
  VolumeTableContainer,
} from '../components/TableContainers';
import NewVolumeHeader from 'src/components/Table/NewVolumeHeader';
import { Typography } from '@mui/joy';
import { getTreatingReferringExport } from 'src/api/affiliations/exportApi';

const listColumns: GridColDef[] = [
  TableColumn?.tags('tags', 'Tags'),
  TableColumn?.notes('notes', 'Notes'),
  TableColumn?.affiliations('affiliations', 'Affiliations'),
  TableColumn?.groups('groups', 'Groups'),
  TableColumn?.streetAddress('street_address', 'Street Address'),
  TableColumn?.secondaryAddresses(
    'secondary_addresses_v2',
    'Secondary Address'
  ),
  TableColumn?.dateAdded('created_at', 'Date Added'),
  TableColumn?.systems('systems', 'Hospital System'),
];

const ReferringTable = () => {
  const router = useRouter();
  const { id, type: location } = router.query as { id: string; type: string };
  const log = useLogging();
  const apiRef = useGridApiRef();
  const apiRef2 = useGridApiRef();

  const { state } = useSite();

  // Sync the scroll of the two tables
  useSyncTableScroll(apiRef, apiRef2);
  const { isRowHovered } = useSyncRowHovered(apiRef, apiRef2);

  const { setNotification } = useNotification();
  const { prospectSearch, prospectVolumeType } = useProspectSearch();

  const [columnVisibilityModel, setColumnVisibilityModel] = useLocalStorage(
    'referringTable',
    {
      name: true,
      crm: true,
      specialty: true,
      rep_name: false,
      tags: false,
      provider_id: false,
      address: false,
      street_address: false,
      secondary_addresses_v2: false,
      city: false,
      state: false,
      zip_code: false,
      affiliations: false,
      groups: false,
      actions: false,
      notes: false,
      phone: false,
      email: false,
      created_at: false,
      systems: false,
    }
  );

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  const [isExporting, setIsExporting] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [sort, setSort] = useState({
    field: null,
    order: null,
    sort_search: null,
    sort_value: null,
  });

  const { data: savedSearches = [] } = useGetSavedSearches();

  const mainColumns = useMemo(() => {
    return [
      ...baseColumns.slice(0, 2),
      ...listColumns,
      ...baseColumns.slice(2),
    ];
  }, [baseColumns, listColumns]);

  // Get the selected searches from the state
  const searchIds = useMemo(() => {
    return state?.siteProvider[location]?.selectedSearches?.map((search) =>
      search?.id?.toString()
    );
  }, [location, state?.siteProvider[location]?.selectedSearches]);

  const volumeColumns = useMemo(() => {
    if (!searchIds) return [];

    return searchIds.map((id) => {
      const search = savedSearches.find((s) => s.id === Number(id));
      return {
        field: `${search?.id}`,
        headerName: search?.name,
        groupable: false,
        sortable: true,
        headerClassName: 'MuiDataGrid-lightHeader',
        cellClassName: 'MuiDataGrid-lightCell',
        renderHeader: (params) => <NewVolumeHeader {...params} />,
        renderCell: (params) => (
          <Typography
            level="body-md"
            sx={{ fontWeight: 400, padding: '0 0.5rem' }}
          >
            {params.row.volume?.[search?.id]}
          </Typography>
        ),
        type: 'string',
        sortingOrder: ['desc', 'asc', null] as GridSortDirection[],
        width: 150,
        disableColumnMenu: true,
      };
    });
  }, [searchIds]);

  const queryParams = {
    providerId: id,
    sort: sort?.field,
    order: sort?.order,
    sort_search: sort?.sort_search,
    sort_value: sort?.sort_value,
    page: paginationModel.page,
    pageSize: paginationModel.pageSize,
    enabledSearchIds: searchIds,
    q: state?.siteProvider[location]?.search,
    volume_type: state?.siteProvider[location]?.selectedType,
    dateRange: state?.siteProvider[location]?.selectedDate,
  };

  const { data: referringData, isLoading } = useGetReferringOut(queryParams);

  // Get all mappings to determine if CRM column should be visible
  const { data: allMappings } = useGetMappings({
    sort: 'label',
    order: 'asc',
    page: 0,
    pageSize: 100,
  });

  const crmTypes = useMemo(() => {
    return Array.from(
      new Set(
        allMappings?.results
          ?.filter((mapping) => mapping.enabled)
          .map((mapping) => mapping.crm?.toLowerCase())
      )
    );
  }, [allMappings]);

  // Update the new prospects state when the selection model changes
  const {
    state: [, setNewProspects],
  } = useProspectSelection();

  useEffect(() => {
    if (!apiRef.current?.getSelectedRows) return;
    setNewProspects(Array.from(apiRef.current.getSelectedRows().values()));

    return () => setNewProspects([]);
  }, [selectionModel]);

  // Calculate row height based on address and/or affiliations
  const getRowHeight = useCallback(
    ({ model, densityFactor }) => {
      const addressMultiplyer = 75;
      const affiliationMultiplyer = 32;

      let addressHeight = 0;
      let affiliationHeight = 0;

      if (model.address_line_1) {
        addressHeight = addressMultiplyer * densityFactor;
      }

      if (
        columnVisibilityModel.affiliations !== false &&
        model.affiliations &&
        model.affiliations?.length > 0
      ) {
        affiliationHeight =
          affiliationMultiplyer * model.affiliations?.length * densityFactor;
      }

      return Math.max(addressHeight, affiliationHeight) || 'auto';
    },
    [columnVisibilityModel]
  );

  // maintain column visibility state when searchIds, volumeColumns, or prospectSearch changes
  useEffect(() => {
    if (!volumeColumns.length) return;

    setColumnVisibilityModel((columnVisibilityState) => ({
      ...columnVisibilityState,
      ...serializeColumnVisibility(),
    }));
  }, [prospectSearch, searchIds, volumeColumns]);

  const enhancedRows = useMemo(() => {
    if (!referringData?.results || !prospectSearch) return [];

    return referringData.results?.map((row) => {
      return row;
    });
  }, [referringData, prospectSearch]);

  const serializeColumnVisibility = () => {
    const visibilityMap = {};

    for (const col of volumeColumns) {
      if (col.field === 'crm') continue;
      // Set the column visibility based on searchIds
      visibilityMap[col.field] = searchIds?.includes(col.field);
    }

    // Hide CRM column if user does not have access to CRM
    visibilityMap['crm'] = crmTypes.length > 0;

    return visibilityMap;
  };
  const handleExportList = (type: string) => {
    const columns = Object.entries(columnVisibilityModel)
      .filter(([key, value]) => value === true)
      .map(([key]) => key)
      .concat(['volume_at_location', 'volume_total']);
    setIsExporting(true);
    try {
      const res = getTreatingReferringExport({
        searchParams: queryParams,
        tableType: 'referring',
        fileType: type,
        columns,
      });

      if (!res) return;
      setNotification({
        title: 'Success',
        message:
          'Export initiated - You will receive an email when it is ready for download',
        type: 'success',
      });
      log.event('Export', {
        type: 'affiliations',
        source: 'All Affiliated - Referring Out',
        columns,
      });
    } catch (err: any) {
      log.exception(
        `Error exporting All Affiliated - Referring Out: ${err.message}`
      );

      setNotification({
        title: 'Error',
        message: 'Error exporting, please try again.',
        type: 'error',
      });
    } finally {
      setIsExporting(false);
    }
  };

  const handleSortChange = (model) => {
    let newField = 'name';
    let sort_search = '';
    let sort_value = '';

    if (model.length) {
      const { field } = model[0];
      newField =
        isNaN(Number(field)) && field !== 'volume' ? field : 'saved_search';

      if (newField === 'saved_search') {
        sort_search = field !== 'volume' ? field : `${prospectSearch?.id}`;
        sort_value = prospectVolumeType;
      }
    }

    setSort({
      field: newField,
      order: model.length ? model[0].sort : 'desc',
      sort_search,
      sort_value,
    });
  };

  const handlePageChange = (e, newPage) => {
    setPaginationModel((prev) => ({ ...prev, page: newPage }));
  };

  const handlePageSizeChange = (e) => {
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: parseInt(e.target.value, 10),
      page: 0,
    }));
  };

  return (
    <PrimaryTableContainer>
      <InnerContainer>
        <DataGridPremium
          disableVirtualization
          apiRef={apiRef}
          rows={enhancedRows || []}
          rowCount={referringData?.count || 0}
          columns={mainColumns}
          scrollbarSize={0}
          hideFooter={true}
          checkboxSelection
          getRowClassName={isRowHovered}
          sx={{
            // remove border from table and only show overflow on x axis
            border: 'none',
            borderRadius: 0,
            '& .MuiDataGrid-main, & .MuiDataGrid-footerContainer': {
              backgroundColor: 'white',
            },
            '& .MuiDataGrid-virtualScroller': {
              overflowY: 'scroll',
              overflowX: 'auto',
              marginRight: '-1rem', // hides the Y axis scrollbar
            },
            '& .MuiDataGrid-columnHeadersInner>div:first-of-type, & .MuiDataGrid-groupHeader, & .MuiDataGrid-columnHeader--emptyGroup':
              {
                // display: 'none !important',
                height: 'auto !important',
              },
            '& .MuiDataGrid-row:hover, & .Mui-hovered': {
              backgroundColor: grey[100],
              '&.Mui-hovered': {
                backgroundColor: grey[200],
                transition: 'background-color 0.3s ease-in-out',
              },
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none !important',
            },
          }}
          initialState={{
            columns: { columnVisibilityModel },
            pagination: { paginationModel: { page: 0, pageSize: 50 } },
          }}
          slots={{
            toolbar: NewTableToolbar,
            baseCheckbox: TableCheckbox,
            columnMenu: CustomColumnMenu,
            ...TableSettings.slots,
          }}
          slotProps={{
            columnsPanel: {
              disableHideAllButton: true,
              disableShowAllButton: true,
            },
            toolbar: {
              variant: 'primary',
              headerSlots: {
                slot1: <NewTablePrimaryHeader title="Referring Out" />,
                slot2: (
                  <NewTableControls
                    columns={mainColumns}
                    columnVisibilityModel={columnVisibilityModel}
                    setColumnVisibilityModel={setColumnVisibilityModel}
                    onExport={handleExportList}
                    isExporting={isExporting}
                  />
                ),
              },
            },
          }}
          // Pagination
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          // Sorting
          sortingMode="server"
          onSortModelChange={handleSortChange}
          // Filtering
          filterMode="server"
          onFilterModelChange={setFilterModel}
          filterModel={filterModel}
          // Column visibility
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={setColumnVisibilityModel}
          // Rows
          getRowHeight={getRowHeight}
          onRowSelectionModelChange={setSelectionModel}
          rowSelectionModel={selectionModel}
        />
        <VolumeTableContainer>
          <DataGridPremium
            disableVirtualization
            apiRef={apiRef2}
            rows={searchIds?.length > 0 ? enhancedRows : []}
            rowCount={referringData?.count || 0}
            columns={volumeColumns}
            hideFooter={true}
            getRowClassName={isRowHovered}
            sx={{
              // remove border from table
              border: 'none',
              borderRadius: 0,
              background: 'var(--secondary-outlined-ActiveBg, #D6F6F1)',
              '& .muiDataGrid-withBorderColor': {
                border: 'none',
              },
              '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                display: 'flex',
                alignItems: 'center',
                flex: '1 0 0',
                alignSelf: 'stretch',
              },
              '& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer':
                {
                  borderBottom: 'none',
                },
              // This fixes that little notch on the right created by the scrollbar
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: 'var(--secondary-outlined-ActiveBg, #D6F6F1)',

                '& .MuiDataGrid-columnSeparator': {
                  color: 'none',
                },
              },
              '& .MuiDataGrid-overlayWrapperInner': {
                backgroundColor: 'var(--secondary-outlined-ActiveBg, #D6F6F1)',
              },
              '& .MuiDataGrid-columnHeaderGroupCell': {
                borderBottom: 'none', // Remove group header cell borders if present
              },
              '& .MuiDataGrid-row:hover, & .Mui-hovered': {
                backgroundColor: grey[100],
                '&.Mui-hovered': {
                  backgroundColor: grey[200],
                },
              },
              '& .MuiDataGrid-columnSeparator': {
                display: 'none !important',
              },
            }}
            slots={{
              toolbar: NewTableToolbar,
              noRowsOverlay: NoVolumeOverlay,
              noResultsOverlay: NoVolumeOverlay,
              ...TableSettings.slots,
            }}
            slotProps={{
              columnsPanel: {
                disableHideAllButton: true,
                disableShowAllButton: true,
              },
              toolbar: {
                headerSlots: {
                  slot1: (
                    <TableVolumeHeader
                      type="REFERRED"
                      selectedType={state?.siteProvider[location]?.selectedType}
                      yearRange={state?.siteProvider[location]?.selectedDate}
                    />
                  ),
                },
              },
            }}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            // Sorting
            sortingMode="server"
            onSortModelChange={handleSortChange}
            // Filtering
            filterMode="server"
            onFilterModelChange={setFilterModel}
            filterModel={filterModel}
            // Column visibility
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={setColumnVisibilityModel}
            // Rows
            getRowHeight={getRowHeight}
          />
        </VolumeTableContainer>
        {isLoading && <TableLoadingIndicator />}
      </InnerContainer>
      <TableFooter
        page={paginationModel.page}
        pageSize={paginationModel.pageSize}
        rowCount={referringData?.count || 0}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />
    </PrimaryTableContainer>
  );
};

const CustomColumnMenu = (props: GridColumnMenuProps) => {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuFilterItem: null,
        columnMenuAggregationItem: null,
        columnMenuGroupingItem: null,
      }}
    />
  );
};

export default ReferringTable;
