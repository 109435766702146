import React, { useCallback, useMemo } from 'react';
import {
  Box,
  IconButton,
  Autocomplete,
  CircularProgress,
  Tooltip,
} from '@mui/joy';
import { EditRounded } from '@mui/icons-material';
import { AddEditTerritory, TerritoryItem } from './components';
import { TerritoryGroup } from './components/TerritoryGroup';
import { useAuth, useLogging, useMedScoutMap } from 'src/context';
import { useGetAllMedTerritories, getTerritory } from 'src/hooks';
import { HeatmapButton } from './components/HeatmapButton';
import { NO_TERRITORY_ACTIVE_SELECTION } from './constants';

const TerritoryPanel = () => {
  const log = useLogging();
  const { user, setUser } = useAuth();
  const {
    currentTerritory,
    setCurrentTerritory,
    isEditing,
    setIsEditing,
    isCreating,
    setIsCreating,
    setHeatMap,
  } = useMedScoutMap();

  const { data: myTerritoryData, isLoading: isLoadingTerritories } =
    useGetAllMedTerritories();

  const currentlySetTerritory = useMemo(() => {
    return (
      myTerritoryData?.find((territory) => {
        return territory.id === currentTerritory?.id;
      }) || null
    );
  }, [currentTerritory, myTerritoryData]);

  const combinedData = useCallback(() => {
    const currentTerritories =
      myTerritoryData && myTerritoryData?.length > 0 ? myTerritoryData : [];
    const repTerritories = currentTerritories
      ?.filter((rep) => rep.owner?.id !== user.id)
      ?.sort((a, b) => {
        if (a.is_canonical !== b.is_canonical) {
          return a.is_canonical ? -1 : 1;
        }
        return a.name.localeCompare(b.name);
      })
      ?.map((territory) => ({
        ...territory,
        repName: `${territory.owner.first_name} ${territory.owner.last_name}`,
      }))
      ?.sort((a, b) => a.repName.localeCompare(b.repName));

    const myTerritories = currentTerritories
      ?.filter((territory) => territory.owner?.id === user.id)
      .concat({
        id: NO_TERRITORY_ACTIVE_SELECTION.id,
        value: NO_TERRITORY_ACTIVE_SELECTION.id,
        name: NO_TERRITORY_ACTIVE_SELECTION.name,
      })
      ?.sort((a, b) => {
        if (a.value === 'USE_MAP_NO_TERRITORY') return -1;

        return a.name.localeCompare(b.name);
      });

    myTerritories?.unshift({
      id: 'addNew',
      value: 'addNew',
      name: 'Add a Territory',
    });

    return [...myTerritories, ...repTerritories];
  }, [myTerritoryData, user]);

  const handleTerritoryEdit = () => {
    setIsEditing(!isEditing);
    setHeatMap(false);
  };

  const handleTerritoryChange = async (
    e: React.ChangeEvent,
    territory: any
  ) => {
    if (!territory) return;

    if (territory.id === 'addNew') {
      setIsCreating(true);
      setHeatMap(false);
      return;
    } else if (territory.id === NO_TERRITORY_ACTIVE_SELECTION.id) {
      log.event('useMapWithNoTerritorySelected');
      setCurrentTerritory(null);
      setUser((oldUser) => ({
        ...oldUser,
        last_territory: null,
      }));
      setHeatMap(false);
      return;
    }

    try {
      const territoryData = await getTerritory(territory.id);
      if (territoryData) {
        setCurrentTerritory(territoryData);
        setUser((oldUser) => ({
          ...oldUser,
          last_territory: territoryData,
        }));
      } else {
        log.exception('Failed to get territory data', {
          tags: {
            territoryId: territory.id,
          },
        });
        setUser((oldUser) => ({
          ...oldUser,
          last_territory: null,
        }));
        setCurrentTerritory(null);
      }
    } catch (err) {
      log.exception('Failed to get territory data', {
        tags: {
          territoryId: territory.id,
          error: err,
        },
      });
      setCurrentTerritory(null);
    } finally {
      setHeatMap(false);
    }
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2,
        padding: '0.5rem',
      }}
    >
      {isEditing || isCreating ? (
        <AddEditTerritory />
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Autocomplete
            disableClearable
            value={currentlySetTerritory || NO_TERRITORY_ACTIVE_SELECTION}
            options={combinedData() || []}
            loadingText="Loading Territories..."
            loading={isLoadingTerritories}
            clearOnBlur
            getOptionLabel={(option) => option.name}
            onChange={handleTerritoryChange}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            groupBy={(option) => option.repName}
            renderGroup={(params) => (
              <Box key={params.key}>
                {params.group && (
                  <TerritoryGroup
                    params={params}
                    activeTerritory={currentTerritory}
                  />
                )}
                {!params.group && params.children}
              </Box>
            )}
            renderOption={(props, option) => (
              <TerritoryItem
                key={option.id}
                props={props}
                option={option}
                activeTerritory={currentTerritory}
              />
            )}
            slotProps={{
              input: {
                'aria-label': 'Territory Selection Dropdown',
                placeholder: 'Select a Territory',
              },
            }}
            sx={{
              minWidth: '300px',
              borderTopLeftRadius: 'sm', // Round top-left corner
              borderBottomLeftRadius: 'sm', // Round bottom-left corner
              borderTopRightRadius: 0, // Flat top-right corner
              borderBottomRightRadius: 0, // Flat bottom-right corner
            }}
          />
          <Tooltip
            color="neutral"
            title="Select a territory to edit"
            placement="bottom"
            arrow
            disableFocusListener={!!currentTerritory}
            disableHoverListener={!!currentTerritory}
            disableTouchListener={!!currentTerritory}
          >
            <span>
              <IconButton
                variant="outlined"
                color="neutral"
                onClick={handleTerritoryEdit}
                disabled={isLoadingTerritories || !currentTerritory}
                sx={{
                  backgroundColor: 'white',
                  borderRadius: 0,
                }}
              >
                {isLoadingTerritories ? (
                  <CircularProgress size="sm" />
                ) : (
                  <EditRounded fontSize="small" />
                )}
              </IconButton>
            </span>
          </Tooltip>
          <HeatmapButton inGroup />
        </Box>
      )}
    </Box>
  );
};

export default TerritoryPanel;
