import React from 'react';
import { useRouter } from 'next/router';
import { format } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { updateNote, useGetNotes } from 'src/hooks';
import { useLogging, useNotification } from 'src/context';

import { CloseRounded, DeleteRounded, EditRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  ListItem,
  Typography,
  ButtonGroup,
} from '@mui/joy';
import { grey } from '@mui/material/colors';
import { ControlledJoyTextarea } from 'src/components';
import DeleteNoteModal from './DeleteNoteModal';

const NoteItem = ({ note }) => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const router = useRouter();
  const { id } = router.query as { id: string };

  const { mutate } = useGetNotes(id);
  const [isNoteEditing, setIsNoteEditing] = React.useState(false);
  const [isNoteLoading, setIsNoteLoading] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const methods = useForm({
    values: {
      note: note.note,
    },
  });

  const {
    reset,
    handleSubmit,
    formState: { isDirty, isValid },
  } = methods;

  const toggleDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  const toggleIsNoteEditing = () => {
    setIsNoteEditing(!isNoteEditing);
  };

  const onSubmit = async (data: { note: string }) => {
    if (!data) return;
    setIsNoteLoading(true);
    try {
      await updateNote(Number(id), note.id, data);
      log.event('profileEditNoteSubmitted', {
        source: 'NoteItem',
        providerId: id,
        noteId: note.id,
        note: data.note,
      });
      setNotification({
        title: 'Success',
        message: 'Note updated successfully',
        type: 'success',
      });
    } catch (error) {
      log.exception('Error updating note', {
        tags: {
          source: 'NoteItem',
          providerId: id,
          noteId: note.id,
          note: data.note,
        },
      });
      setNotification({
        title: 'Error',
        message: 'Failed to update note',
        type: 'error',
      });
    } finally {
      setIsNoteLoading(false);
      reset();
      mutate();
      toggleIsNoteEditing();
    }
  };

  return (
    <Box aria-label="Note Item">
      <FormProvider {...methods}>
        <ListItem
          key={note.id}
          sx={{
            display: 'flex',
            padding: '0.25rem 0.125rem',
            justifyContent: 'space-between',
            borderTop: `1px solid ${grey[300]}`,
            '&:last-of-type': {
              borderBottom: `1px solid ${grey[300]}`,
            },
          }}
        >
          <Box sx={{ flex: 1 }}>
            {isNoteEditing ? (
              <ControlledJoyTextarea name="note" />
            ) : (
              <>
                {note.created_at && (
                  <Typography level="body-xs" sx={{ fontWeight: 600 }}>
                    {format(new Date(note.created_at), 'PPp')}
                  </Typography>
                )}

                <Typography level="body-sm" sx={{ fontWeight: 600 }}>
                  {note.note}
                </Typography>
              </>
            )}
          </Box>
          <Box
            sx={{ display: 'flex', justifyContent: 'flex-end', gap: '0.5rem' }}
          >
            {isNoteEditing ? (
              <ButtonGroup>
                <IconButton
                  size="sm"
                  color="neutral"
                  variant="outlined"
                  onClick={toggleIsNoteEditing}
                >
                  <CloseRounded fontSize="small" />
                </IconButton>
                <Button
                  loading={isNoteLoading}
                  size="sm"
                  color="primary"
                  variant="solid"
                  disabled={!isDirty || !isValid}
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </Button>
              </ButtonGroup>
            ) : (
              <>
                <IconButton
                  size="sm"
                  color="danger"
                  variant="outlined"
                  onClick={toggleDeleteDialog}
                  aria-label="Delete Note Button"
                >
                  <DeleteRounded fontSize="small" />
                </IconButton>
                <IconButton
                  size="sm"
                  color="neutral"
                  variant="outlined"
                  onClick={toggleIsNoteEditing}
                  aria-label="Edit Note Button"
                >
                  <EditRounded fontSize="small" />
                </IconButton>
              </>
            )}
          </Box>
        </ListItem>
      </FormProvider>
      <DeleteNoteModal
        open={showDeleteDialog}
        onClose={toggleDeleteDialog}
        note={note}
      />
    </Box>
  );
};

export default NoteItem;
