import { useEffect, useState } from 'react';
import { Box, Button, styled, Menu, Dropdown, Grid } from '@mui/joy';
import { ClickAwayListener } from '@mui/material';
import {
  ReferringTreatingToggle,
  VolumeTypeToggle,
  VolumeDateToggle,
  VolumeEntityTypeToggle,
} from './components';

const VolumeSelectorsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  flexShrink: 0,
  overflow: 'auto',
}));

interface NewVolumeSelectorsProps {
  showTreatingReferring?: boolean;
  showEntityType?: boolean;
  table?: string | null;
  enabledSearchIds?: number[] | string[];
  isMobile?: boolean;
}

// TODO: Joy UI dropdown does not work on Disocvery page, no ideas.. yet
const NewVolumeSelectors = ({
  showTreatingReferring = false,
  showEntityType = false,
  table = null,
  enabledSearchIds = [],
  isMobile = false,
}: NewVolumeSelectorsProps) => {
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (!anchorEl) return;
    setAnchorEl(null);
  }, [isMobile]);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        flex: 1,
        whiteSpace: 'nowrap',
      }}
    ></Box>
  );
};

export default NewVolumeSelectors;
