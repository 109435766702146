import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { AddRounded, CheckRounded } from '@mui/icons-material';
import { Button, Typography } from '@mui/joy';
import { addItemsToList } from 'src/hooks';

import { AddToListDialog } from 'src/components';
import { CircularProgress } from '@mui/material';
import { useLogging, useNotification } from 'src/context';
const NewAddToListButton = ({ providers }) => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const [showProviderDialog, setShowProviderDialog] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  useEffect(() => {
    if (!isSuccess) return;
    const timer = setTimeout(() => {
      setIsSuccess(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess]);

  const handleShowProviderDialog = () => {
    setShowProviderDialog(true);
  };

  const handleAddProvider = async (listId: number) => {
    if (!listId) return;
    setIsLoading(true);
    try {
      await addItemsToList(listId, providers);
      setIsSuccess(true);
      log.event('addProvider', {
        source: 'ProfileSummary',
        count: providers?.length,
      });

      setNotification({
        title: 'Providers added',
        message: `Added ${providers?.length} prospects to the list.`,
        type: 'success',
      });
    } catch (err) {
      log.exception('addProvider', {
        tags: {
          source: 'ProfileSummary',
          count: providers?.length,
          error: err.message,
        },
      });

      setNotification({
        title: 'Error',
        message:
          err.message ||
          'An error occurred while adding providers to the list.',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button
        variant="solid"
        size="sm"
        onClick={handleShowProviderDialog}
        startDecorator={
          isSuccess ? (
            <motion.span
              initial={{ y: 0 }}
              animate={{ y: [0, -10, 0, -4, 0], scale: [1, 1.1, 1] }} // Bounce up and down
              transition={{
                duration: 0.4, // Duration of the bounce
              }}
            >
              <CheckRounded fontSize="small" sx={{ color: 'white' }} />
            </motion.span>
          ) : !isLoading && !isSuccess ? (
            <AddRounded />
          ) : null
        }
        sx={{
          borderRadius: 'sm',
          background: isSuccess ? '#1F7A1F' : '#2491D9',
          minWidth: '7.688rem',
        }}
      >
        {isLoading ? (
          <CircularProgress size={20} sx={{ color: 'white' }} />
        ) : isSuccess ? (
          <motion.span
            initial={{ y: 0 }}
            animate={{ y: [0, -10, 0, -4, 0], scale: [1, 1.1, 1] }} // Bounce up and down
            transition={{
              duration: 0.4, // Duration of the bounce
            }}
            style={{ color: 'white', fontSize: '0.875rem', fontWeight: 600 }}
          >
            <Typography level="body-sm" sx={{ color: 'white' }}>
              Added!
            </Typography>
          </motion.span>
        ) : (
          <Typography level="body-sm" sx={{ color: 'white' }}>
            Add to List
          </Typography>
        )}
      </Button>
      <AddToListDialog
        open={showProviderDialog}
        onClose={() => setShowProviderDialog(false)}
        onAddProvider={handleAddProvider}
      />
    </>
  );
};

export default NewAddToListButton;
