import React from 'react';
import { Box } from '@mui/joy';

const PrimaryTableContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0,
        padding: 0,
        borderRadius: '0.5rem',
        height: '50rem',
        width: '100%',
        overflow: 'hidden',
        border: '1px solid var(--neutral-outlined-Border, #CDD7E1)',
      }}
    >
      {children}
    </Box>
  );
};

export default PrimaryTableContainer;
