import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/joy';
import { styled } from '@mui/material';
import { useLogging, useNotification, useProfile } from 'src/context';
import { ActivityItem } from '../ActivityItem';
import { deleteActivity } from 'src/api';
import { useGetEntityActivities } from 'src/hooks';

const ActivityListContainer = styled(Box)({
  position: 'relative',
  display: 'flex',
  padding: '0 var(--1, 0.5rem)',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  gap: 'var(--3, 1.5rem)',
  zIndex: 1,
  width: '100%',
});

const LineContainer = styled(Box)({
  position: 'relative',
  display: 'flex',
  width: '100%',
});

const Line = styled(Box)({
  position: 'absolute',
  width: '0.125rem',
  height: '100%',
  backgroundColor: 'var(--Neutral-200, #DDDFE065)',
  left: '1.44rem',
  top: '1px',
});

const NoDataContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
});

const ActivityList = () => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const { details } = useProfile();
  const {
    data: activties,
    isLoading,
    error,
    mutate,
  } = useGetEntityActivities(details?.provider_id);

  const handleDelete = async (id: string) => {
    if (!confirm('Are you sure you want to delete this activity?')) return;
    try {
      await deleteActivity({
        activityId: id,
      });
      mutate({
        ...activties,
        results: activties?.results?.filter((item) => item.id !== id),
      });

      log.event('Delete Activity', {
        location: 'Activity List',
        data: id,
      });
    } catch (err) {
      log.exception('Delete Activity', {
        tags: {
          location: 'Activity List',
          data: id,
          error: err,
        },
      });
      setNotification({
        title: 'Error',
        message: 'Failed to delete activity',
        type: 'error',
      });
    } finally {
      mutate();
    }
  };

  return (
    <LineContainer>
      <Line />
      <ActivityListContainer>
        {isLoading && (
          <NoDataContainer>
            <CircularProgress size="sm" />
          </NoDataContainer>
        )}
        {activties?.results?.length > 0 ? (
          activties?.results?.map((item) => (
            <ActivityItem key={item.id} item={item} onDelete={handleDelete} />
          ))
        ) : (
          <NoDataContainer>
            <Typography level="title-md">No activities found</Typography>
          </NoDataContainer>
        )}
      </ActivityListContainer>
    </LineContainer>
  );
};

export default ActivityList;
