import {
  AutocompleteOption,
  Box,
  ListItemContent,
  ListItemDecorator,
  Typography,
} from '@mui/joy';
import React from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

const NewFilterOption = ({ props, option, isSelected, isDisabled }) => {
  return (
    <AutocompleteOption
      {...props}
      sx={{
        ...(isSelected && {
          backgroundColor: '#D6F6F1 !important', // Ensure selected background color
          borderRadius: '0.5rem', // Adjust border radius for selected look
          margin: '0 0.75rem', // Adjust margin for selected look
          paddingInline: '0.5rem 0', // Adjust padding for selected look
        }),
        ...(isDisabled && {
          color: 'text.disabled', // Ensure disabled text color
          opacity: 0.5, // Adjust opacity for disabled look
          cursor: 'not-allowed',
        }),
      }}
    >
      <ListItemContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography
          level="title-sm"
          sx={{
            color: 'inherit',
            fontSize: '0.875rem',
            fontWeight: 500,
            lineHeight: '1.243rem',
          }}
        >
          {option.search_name}
        </Typography>
        {option?.matching_providers && !isSelected && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <Typography
              level="body-xs"
              sx={{ color: 'text.secondary', fontWeight: 400 }}
            >
              {option.matching_providers}
            </Typography>
            <Typography
              level="body-xs"
              sx={{ color: 'text.secondary', fontWeight: 400 }}
            >
              {option.matching_providers === 1 ? 'Provider' : 'Providers'}
            </Typography>
          </Box>
        )}
      </ListItemContent>

      {isSelected && (
        <ListItemDecorator>
          <Icon
            icon={faCheck}
            color={isSelected ? '#00A783' : '#555E68'}
            fontSize="1.2rem"
            fontWeight={500}
          />
        </ListItemDecorator>
      )}
    </AutocompleteOption>
  );
};

export default NewFilterOption;
