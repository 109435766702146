/**
 * @description Returns the number of days, years, etc since the given date
 * @param dateString
 * @returns string
 */
export const daysSince = (dateString) => {
  const givenDate = new Date(dateString);
  const currentDate = new Date();

  // Remove time part for accurate day comparison
  givenDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  // Calculate the difference in days
  const diffInMs = currentDate.getTime() - givenDate.getTime();
  const daysSince = diffInMs / (1000 * 60 * 60 * 24);

  if (daysSince < 1) {
    return 'Today';
  } else if (daysSince === 1) {
    return 'Yesterday';
  } else if (daysSince < 7) {
    return `${Math.floor(daysSince)} days ago`;
  } else if (daysSince < 30) {
    const weeks = Math.floor(daysSince / 7);
    return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
  } else if (daysSince < 365) {
    const months = Math.floor(daysSince / 30);
    return `${months} month${months > 1 ? 's' : ''} ago`;
  } else {
    const years = Math.floor(daysSince / 365);
    return `${years} year${years > 1 ? 's' : ''} ago`;
  }
};
