import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { rootReducer, initialState } from 'src/context/reducers';
import { useLocalStorage, useGetSavedSearchFolders } from 'src/hooks';
import { SavedSearchActionTypes } from 'src/context/reducers/actionTypes';

const SiteContext = createContext<{
  state: typeof initialState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const SiteProvider = ({ children }: { children: ReactNode }) => {
  const [storedSiteState, setStoredSiteState] = useLocalStorage(
    'pageFilterBar',
    initialState.siteProvider
  );

  // Initialize the state by combining the persisted `site` state and transient `savedSearches`
  const [state, dispatch] = useReducer(rootReducer, {
    ...initialState,
    siteProvider: storedSiteState,
  });

  // Persist only the `site` part of the state to localStorage
  useEffect(() => {
    setStoredSiteState(state.siteProvider);
  }, [state.siteProvider]);

  // set folders
  const { data: savedSearchFolders } = useGetSavedSearchFolders();

  useEffect(() => {
    if (savedSearchFolders) {
      dispatch({
        type: SavedSearchActionTypes.SET_FOLDERS,
        payload: savedSearchFolders?.results,
      });
    }
  }, [savedSearchFolders]);

  return (
    <SiteContext.Provider value={{ state, dispatch }}>
      {children}
    </SiteContext.Provider>
  );
};

export const useSite = () => {
  const context = useContext(SiteContext);
  if (!context) throw new Error('useSite must be used within a SiteProvider');
  return context;
};
