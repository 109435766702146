import useSWR from 'swr';
import { QUERY_KEYS } from './constants';
import { getSavedSearchesById } from 'src/api';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { useAuth } from 'src/context';

const useGetSavedSearchesById = ({ provider_id, serializer }) => {
  const { user } = useAuth();
  const queryParams = getSearchQueryParamsForAPI({
    provider_id,
    serializer,
    company_id: user?.company?.id,
  });
  return useSWR([QUERY_KEYS.saved_searches, queryParams], () =>
    getSavedSearchesById(queryParams)
  );
};

export default useGetSavedSearchesById;
