import React from 'react';
import { Button, IconButton, Dropdown, Menu } from '@mui/joy';
import { styled } from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/pro-solid-svg-icons';
import { useLogging } from 'src/context';
import { useOnClickOutside } from 'src/hooks';
import { AddEditActivity } from '../AddEditActivity';

const StyledMenu = styled(Menu)({
  width: '386px', // Specific to keep the menu width consistent
  background: 'transparent',
  border: 'none',
  boxShadow: 'none !important',
  zIndex: 9999,
});

const NewActivityLog = ({ smallHeader = false }) => {
  const log = useLogging();
  const menuRef = React.useRef(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    log.event('Activity Log Button Clicked');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useOnClickOutside(menuRef, anchorEl, handleClose);

  return (
    <Dropdown ref={menuRef}>
      {smallHeader ? (
        <IconButton
          variant="solid"
          onClick={toggleMenu}
          sx={{
            backgroundColor: '#2391D9',
          }}
        >
          <Icon icon={faBolt} />
        </IconButton>
      ) : (
        <Button
          startDecorator={<Icon icon={faBolt} />}
          variant="solid"
          onClick={toggleMenu}
          sx={{
            backgroundColor: '#2391D9',
            whiteSpace: 'nowrap',
          }}
        >
          Log Activity
        </Button>
      )}
      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        placement="bottom-start"
        onClose={handleClose}
      >
        <AddEditActivity onClose={handleClose} />
      </StyledMenu>
    </Dropdown>
  );
};

export default NewActivityLog;
