import React from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faPhoneArrowUpRight,
  faMobileRetro,
  faCarBuilding,
  faIdBadge,
  faPencil,
} from '@fortawesome/pro-solid-svg-icons';
import { Box } from '@mui/joy';

interface ActivityIconProps {
  type: string;
  isRound?: boolean;
  useColor?: boolean;
  height?: string;
  width?: string;
  iconSize?: string;
}

const ActivityIcon = ({ ...props }: ActivityIconProps) => {
  const {
    type,
    isRound = false,
    useColor = false,
    height = '32px', // specific to keep the icon size consistent
    width = '32px',
    iconSize = '0.875rem',
  } = props;

  const iconMap = {
    call: {
      icon: faPhoneArrowUpRight,
      color: '#FF561D',
      background: '#FFF0EB',
    },
    conference: {
      icon: faIdBadge,
      color: '#31AD00',
      background: '#E9FFE0',
    },
    text: {
      icon: faMobileRetro,
      color: '#985FFF',
      background: '#F2EBFF',
    },
    visit: {
      icon: faCarBuilding,
      color: '#E07A26',
      background: '#FCF2E2',
    },
    note: {
      icon: faPencil,
      color: '#2391D9',
      background: '#E2F6FF',
    },
  };

  const lowerType = type?.toLowerCase();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: isRound ? width : '100%',
        height: isRound ? height : '100%',
        borderRadius: isRound ? '100px' : '0', // Specific to make the icon round
        backgroundColor: isRound ? 'white' : 'transparent', // set the background to white so that we can mask the line
        color: useColor ? iconMap[lowerType]?.color : 'inherit',
        '::before': {
          content: '""',
          position: 'absolute',
          width: width, // Slightly larger than the icon area
          height: height, // Slightly larger than the icon area
          borderRadius: '100px', // Specific to make the icon round
          backgroundColor: isRound
            ? iconMap[lowerType]?.background
            : 'transparent', // Set the color the background is supposed to be.
          zIndex: 2, // Ensure it masks the line
        },
      }}
    >
      <Icon
        icon={iconMap[lowerType]?.icon}
        style={{
          width: iconSize,
          height: iconSize,
          color: useColor ? iconMap[lowerType]?.color : 'inherit',
          position: 'relative',
          zIndex: 3, // Ensure it is above the mask
        }}
      />
    </Box>
  );
};

export default ActivityIcon;
