import React, { ReactNode, useState } from 'react';
import { Container, Box, Table, Typography, IconButton } from '@mui/joy';
import { TableRow } from '@mui/material';
import { grey } from '@mui/material/colors';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faBolt, faLeftFromBracket } from '@fortawesome/pro-solid-svg-icons';
import ActivityTableToolbar from '../Tables/components/ActivityTable/ActivityTableToolbar';
import useGetActivities from 'src/hooks/activities/useGetActivities';
import { ActivityIcon } from './components';
import { activityData } from './constants';
import { daysSince } from 'src/utils';
import { useAuth, useSite } from 'src/context';
import { useDebounce } from 'use-debounce';
import ActivityTableHeader from '../Tables/components/ActivityTable/ActivityTableHeader';
import { TableFooter } from '../Tables';
import { ProfileBlade } from 'src/components';
import { TableRowItem } from '../Tables/components/ActivityTable/components/TableRowItem';

export const displayActivityType = (type: string) => {
  const activityLabel = activityData.find(
    (activity) => activity.value === type.toLowerCase()
  )?.label;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <ActivityIcon
        type={type}
        useColor
        isRound
        height="1.5rem"
        width="1.5rem"
        iconSize=".75rem"
      />
      <Typography sx={{ paddingLeft: '.5rem', color: '#2C2E2F' }}>
        {activityLabel}
      </Typography>
    </Box>
  );
};

const Activity = () => {
  const { user } = useAuth();
  const isManager =
    user.permissions.is_manager || user.permissions.is_superuser;

  const { dispatch } = useSite();

  const [sort, setSort] = useState<{
    sort_value: string;
    order: 'asc' | 'desc';
  }>({
    sort_value: null,
    order: null,
  });
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const [selectedReps, setSelectedReps] = useState<string[] | null>([]);
  const [selectedActivities, setSelectedActivities] = useState<string[] | null>(
    []
  );
  const [selectedTimeframe, setSelectedTimeframe] = useState<string | null>();
  const [hoveredRow, setHoveredRow] = useState(null);

  const queryParams = {
    sort: sort?.sort_value,
    order: sort?.order,
    page: paginationModel.page,
    pageSize: paginationModel.pageSize,
    q: debouncedSearchTerm,
  };
  const { data } = useGetActivities({
    reps: selectedReps?.length > 0 ? selectedReps : null,
    activityTypes: selectedActivities?.length > 0 ? selectedActivities : null,
    timeframe: selectedTimeframe,
    ...queryParams,
  });
  const activities = data?.results;

  const handleColSort = (col: string, order: 'asc' | 'desc') => {
    setSort({ sort_value: col, order });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const handlePageChange = (e, newPage) => {
    setPaginationModel((prev) => ({ ...prev, page: newPage }));
  };

  const handlePageSizeChange = (e) => {
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: parseInt(e.target.value, 10),
      page: 0,
    }));
  };

  const handleShowBlade = ({ providerId, type }) => {
    if (!providerId || !type) return;
    dispatch({
      type: 'SET_CURRENT_PROVIDER',
      payload: {
        id: providerId,
        type,
      },
    });
    dispatch({ type: 'OPEN_PROFILE_DRAWER' });
  };

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{ height: 'calc(100vh - 7.3rem)', backgroundColor: '#FFFFFF' }}
    >
      <Box
        sx={{
          padding: '1rem',
          borderBottom: '1px solid var(--Neutral-200, #DDDFE0)',
          position: 'sticky',
          top: 0,
          zIndex: 1,
          backgroundColor: '#FFFFFF',
        }}
      >
        <Typography level="h4">
          <Icon icon={faBolt} color={grey[600]} /> Activity
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          position: 'sticky',
          top: 0,
          zIndex: 1,
          backgroundColor: '#FFFFFF',
        }}
      >
        <ActivityTableToolbar
          searchTerm={searchTerm}
          onSearch={handleSearch}
          selectedReps={selectedReps}
          setSelectedReps={setSelectedReps}
          selectedActivities={selectedActivities}
          setSelectedActivities={setSelectedActivities}
          selectedTimeframe={selectedTimeframe}
          setSelectedTimeframe={setSelectedTimeframe}
        />
        <Box
          sx={{
            border: `1px solid ${grey[300]}`,
            borderRadius: '0.33rem',
            overflowY: 'auto',
            position: 'relative',
          }}
        >
          <Table
            noWrap
            stickyHeader
            stickyFooter
            sx={{
              tableLayout: 'auto',
              width: '100vw',
            }}
          >
            <ActivityTableHeader
              sort={sort}
              handleColSort={handleColSort}
              isManager={isManager}
            />
            <tbody
              style={{
                width: '100%',
                position: 'relative',
                overflowY: 'auto',
              }}
            >
              {activities?.map((act) => {
                return (
                  <TableRow
                    onMouseEnter={() => setHoveredRow(act.id)}
                    onMouseLeave={() => setHoveredRow(null)}
                    key={act.id}
                    sx={{
                      backgroundColor: '#fff',
                      width: '100vw',
                      display: 'grid',
                      gridTemplateColumns: '1rem 15rem 15rem 15rem 15rem auto',
                      alignItems: 'center',
                      height: '56px',
                    }}
                  >
                    <TableRowItem width="1rem"></TableRowItem>
                    <TableRowItem width="15rem">
                      {displayActivityType(act.type)}
                    </TableRowItem>
                    <TableRowItem width="15rem">
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          position: 'relative',
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              color: '#1B5FA3',
                              fontWeight: 600,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              width: '13.5rem',
                            }}
                          >
                            {act.provider.name}
                          </Typography>
                          <Typography
                            level="body-xs"
                            sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              width: '13rem',
                            }}
                          >
                            {act.provider.specialty}
                          </Typography>
                        </Box>
                        {hoveredRow === act.id && (
                          <button
                            style={{
                              position: 'absolute',
                              right: 0,
                              display: 'flex',
                              padding: '.5rem' /* 8px */,
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderRadius: 'var(--radius-md, 8px)',
                              border:
                                '1px solid var(--neutral-outlined-Border, #CDD7E1)',
                              background: 'var(--common-white, #FFF)',
                              /* shadow-sm */
                              boxShadow:
                                '0px 1px 2px 0px rgba(21, 21, 21, 0.08), 0px 2px 4px 0px rgba(21, 21, 21, 0.08)',
                            }}
                            onClick={() =>
                              handleShowBlade({
                                providerId: act?.provider?.id,
                                type: act?.provider?.type,
                              })
                            }
                          >
                            <Icon
                              height={'16px'}
                              width={'16px'}
                              icon={faLeftFromBracket}
                              color="#636B74"
                            />
                            <Typography
                              sx={{
                                color: 'var(--text-icon, #636B74)',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '700',
                                lineHeight: '114%' /* 15.96px */,
                                padding: '0 .25rem',
                              }}
                            >
                              Open
                            </Typography>
                          </button>
                        )}
                      </Box>
                    </TableRowItem>
                    {isManager && (
                      <TableRowItem width="15rem">
                        {`${act.owner.first_name} ${act.owner.last_name}`}
                      </TableRowItem>
                    )}
                    <TableRowItem width="15rem">
                      {daysSince(act.created_at)}
                    </TableRowItem>
                    <TableRowItem>
                      <div
                        style={{
                          flexGrow: 1,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: '100%',
                        }}
                      >
                        {act.details}
                      </div>
                    </TableRowItem>
                  </TableRow>
                );
              })}
              <tr></tr>
            </tbody>
            <tfoot
              style={{
                background: 'white',
              }}
            >
              <tr
                style={{
                  background: 'white',
                }}
              >
                <TableFooter
                  rowCount={data?.count || 0}
                  page={paginationModel.page}
                  pageSize={paginationModel.pageSize}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                />
              </tr>
            </tfoot>
          </Table>
        </Box>
      </Box>
      <ProfileBlade />
    </Container>
  );
};

export default Activity;
