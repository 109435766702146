import Link from 'next/link';
import { useRouter } from 'next/router';
import { useLogging } from 'src/context';
import { exists } from 'src/utils';
import { contentStyles } from '../styleConstants';
import { Box, Typography } from '@mui/joy';
import InfoUnavailable from '../InfoUnavailable';

const ProfessionalMixList = ({ professionalMixes }) => {
  const router = useRouter();
  const log = useLogging();

  return (
    <Box
      style={{
        maxHeight: 500,
        overflowY: 'auto',
        paddingLeft: '1rem',
      }}
    >
      {exists(professionalMixes) ? (
        professionalMixes
          ?.sort((a, b) => a.title.localeCompare(b.title))
          .map((mix, i) => {
            const filterId = mix.filter_id;

            return (
              <Box key={`${mix.title}-${i}`} sx={contentStyles(i)}>
                <Link
                  style={{ width: '100%' }}
                  href={{
                    pathname: './[id]/[view]',
                    query: {
                      ...router.query,
                      view: 'affiliations',
                      categories: filterId,
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                      padding: '0.25rem 1rem 0.25rem 0.25rem',
                    }}
                  >
                    <Typography
                      level="body-sm"
                      onClick={() => {
                        log.event('professionalMixCategoryClicked', {
                          category: mix.title,
                        });
                      }}
                    >
                      {mix.title}
                    </Typography>
                    <Typography level="body-sm">{mix.value}</Typography>
                  </Box>
                </Link>
              </Box>
            );
          })
      ) : (
        <InfoUnavailable />
      )}
    </Box>
  );
};

export default ProfessionalMixList;
