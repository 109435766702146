import { Container } from '@mui/joy';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAuth } from 'src/context';
import { Notes } from 'src/modules/Profile/components';
import { PayerMix } from 'src/modules/Profile/components/Views/components/PayerMix';
import ProfileDetails from './components/ProfileDetails/ProfileDetails';
import ProfileMap from './components/ProfileMap';
import ProfileOrganizations from './components/ProfileOrganizations';
import ProfileAffiliations from './components/ProfileAffiliations';
import ProfileEducationDetails from './components/ProfileEducationDetails';
import ProfileSummary from './components/ProfileSummary/ProfileSummary';
import {
  ProfileVolumeByLocation,
  GridContainer,
  GridColumn,
} from './components';

const ProviderProfile = ({
  singleColumn = false,
}: {
  singleColumn?: boolean;
}) => {
  const { user } = useAuth();
  const { useProfileAiSummary, newProfileHeader, useProfileActivityLogger } =
    useFlags();

  const userAiProvider = user?.company?.hcp_summary && useProfileAiSummary;

  return (
    <Container maxWidth="lg" disableGutters sx={{ overflow: 'hidden' }}>
      <GridContainer singleColumn={singleColumn}>
        <GridColumn>
          {userAiProvider && <ProfileSummary />}
          <ProfileDetails />
          <PayerMix />
          {!newProfileHeader && <ProfileMap />}
          <Notes />
          <ProfileEducationDetails />
        </GridColumn>
        <GridColumn>
          <ProfileVolumeByLocation />
          <ProfileOrganizations />
          <ProfileAffiliations />
        </GridColumn>
      </GridContainer>
    </Container>
  );
};

export default ProviderProfile;
