'use client';
import React from 'react';
import { Box, Option, Select, Typography, Radio } from '@mui/joy';

interface RadioButtonSelectProps {
  header: string;
  selectOnChange: (e) => void;
  selectedOption: string;
}

const RadioButtonSelect = ({
  header,
  selectOnChange,
  selectedOption,
}: RadioButtonSelectProps) => {
  const timeframeOptions = [
    { value: 'current_week', label: 'Current Week' },
    { value: 'current_month', label: 'Current Month' },
    { value: 'last_six_months', label: 'Past 6 Months' },
  ];

  const displayValue = timeframeOptions.find(
    (option) => option.value === selectedOption
  )?.label;

  return (
    <Select
      id="limited-select"
      variant="outlined"
      size="sm"
      value={displayValue}
      placeholder={displayValue}
      onChange={(e) => selectOnChange(e)}
      sx={{
        flex: 0.125,
        borderRadius: '1rem',
        display: 'flex',
        minHeight: '2rem',
        padding: '0.375rem .5rem 0.375rem .75rem',
        alignSelf: 'stretch',
        background: 'background: var(--background-body, #FFF)',
      }}
      slotProps={{
        listbox: { placement: 'bottom-start' },
      }}
    >
      <Box
        sx={{
          padding: '.5rem 1rem',
          background: 'var(--background-surface, #FBFCFE)',
          width: '240px',
        }}
      >
        <Typography level="body-sm" sx={{ fontWeight: 500 }}>
          {header}
        </Typography>
        <Box sx={{ paddingTop: '.5rem' }}>
          {timeframeOptions?.map((option) => {
            return (
              <Option
                sx={{ paddingLeft: 0 }}
                key={option.value}
                value={option.value}
              >
                <Radio
                  label={option.label}
                  value={option.value}
                  checked={selectedOption === option.value}
                />
              </Option>
            );
          })}
        </Box>
      </Box>
    </Select>
  );
};

export default RadioButtonSelect;
