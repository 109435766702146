import React, { FC } from 'react';

type SparkleProps = {
  width?: number;
  height?: number;
};

const MedAISparkles: FC<SparkleProps> = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || 74}
      height={height || 57}
      fill="none"
      viewBox="0 0 74 57"
    >
      <path
        fill="url(#paint0_linear_2120_1838)"
        d="M1.875 30.3A2.893 2.893 0 000 33c0 1.2.75 2.275 1.875 2.7 0 0 9.959 1.134 14.125 5.3s5.3 14.125 5.3 14.125A2.893 2.893 0 0024 57c1.2 0 2.275-.75 2.7-1.875 0 0 1.134-9.959 5.3-14.125s14.125-5.3 14.125-5.3A2.893 2.893 0 0048 33c0-1.2-.75-2.275-1.875-2.7 0 0-9.959-1.134-14.125-5.3s-5.3-14.125-5.3-14.125A2.893 2.893 0 0024 9c-1.2 0-2.275.75-2.7 1.875 0 0-1.134 9.959-5.3 14.125S1.875 30.3 1.875 30.3z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_2120_1838"
          x1="16.917"
          x2="46.701"
          y1="40.667"
          y2="0.113"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2491D9"></stop>
          <stop offset="0.43" stopColor="#30B0C8"></stop>
          <stop offset="1" stopColor="#32D9C3"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_2120_1838"
          x1="53.278"
          x2="73.134"
          y1="21.111"
          y2="-5.925"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2491D9"></stop>
          <stop offset="0.43" stopColor="#30B0C8"></stop>
          <stop offset="1" stopColor="#32D9C3"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MedAISparkles;
