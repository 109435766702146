import useSWR from 'swr';
import { getProviderLists } from 'src/api';
import { QUERY_KEYS } from './constants';

interface GetProviderListResult {
  results: MedScout.List[];
  count: number;
  next: string | null;
  previous: string | null;
}

/**
 * @description list of all provider lists for the signed in user
 * Standard pagination, sort, and search parameters apply
 */
const useGetProviderLists = () => {
  return useSWR<GetProviderListResult>([QUERY_KEYS.providerLists], async () =>
    getProviderLists()
  );
};

export default useGetProviderLists;
