import { useEffect, useMemo } from 'react';
import { GoogleMapsOverlay } from '@deck.gl/google-maps';
import { HeatmapLayer } from '@deck.gl/aggregation-layers';
import { useMap } from '@vis.gl/react-google-maps';
import { useMedScoutMap } from 'src/context';
import { useGetClusterObjects } from '../MedMapContent/hooks';

const HeatMapLayer = () => {
  const map = useMap();
  const activeMarker = null;
  const { clusterObjects } = useGetClusterObjects(activeMarker);
  const { zoom } = useMedScoutMap();

  let radius = 15;
  const minZoom = 4;
  const maxZoom = 20;
  const minRadius = 15;
  const maxRadius = 100;

  // Dynamically calculate the radius based on the zoom level
  if (zoom >= maxZoom) {
    radius = maxRadius;
  } else if (zoom <= minZoom) {
    radius = minRadius;
  } else {
    radius =
      minRadius +
      ((maxRadius - minRadius) * (zoom - minZoom)) / (maxZoom - minZoom);
  }

  // Create the heatmap layer using deck.gl
  const heatmapLayer = useMemo(() => {
    if (!clusterObjects) return null;

    return new HeatmapLayer({
      // id: 'heatmap-layer',
      data: clusterObjects,
      aggregation: 'SUM',
      getPosition: (d) => d.geometry.coordinates, // Extract position as [lng, lat]
      getWeight: (d) => d.properties.claims, // Extract weight or default to 1
      radiusPixels: radius, // Dynamic radius based on zoom
      intensity: 2,
      threshold: 0.07,
      opacity: 0.5,
    });
  }, [clusterObjects, radius]);

  // Use effect to add and update the deck.gl overlay to the Google Map
  useEffect(() => {
    if (!map) return;

    const overlay = new GoogleMapsOverlay({
      layers: [heatmapLayer], // Apply the deck.gl heatmap layer to the map
    });

    overlay.setMap(map);

    // Clean up the overlay when the component unmounts
    return () => {
      overlay.setMap(null);
      overlay.finalize();
    };
  }, [map, heatmapLayer]);

  return null; // No visible component; it's all in the overlay
};

export default HeatMapLayer;
