import React, { useEffect, useMemo } from 'react';
import { Box, Input, Typography, Tooltip } from '@mui/joy';
import { styled } from '@mui/material';
import { useProspectSearch, useAuth, useSite, useLogging } from 'src/context';
import { useGetSavedSearches } from 'src/hooks';
import { updateSavedSearch, deleteSavedSearch } from 'src/api';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faPencil,
  faTimes,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import { SavedSearchActionTypes } from 'src/context/reducers/actionTypes';
import { HoverButton, RoundButton } from '../buttons';

const SearchContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'inFolder',
})<{ isActive: boolean; inFolder: boolean }>(({ isActive, inFolder }) => ({
  display: 'flex',
  height: '2rem',
  padding: inFolder
    ? 'var(--none, 0px) var(--1, 8px) var(--none, 0px) 2.25rem'
    : '0px var(--1, 0.5rem)',
  alignItems: 'center',
  gap: 'var(--05, 0.25rem)',
  alignSelf: 'stretch',
  borderRadius: 'var(--radius-md, 0.5rem)',
  cursor: 'pointer',
  background: isActive ? 'var(--Primary-500, #2AB2FD)' : 'transparent',
  '&:hover': {
    background: 'var(--background-level1, #F0F4F8)',
  },
}));

const Title = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>(({ isActive }) => ({
  color: isActive ? 'var(--white, #FFFFFF)' : 'var(--text-primary, #171A1C)',
  fontSize: '0.875rem',
  fontWeight: 500,
  lineHeight: '1.243rem' /* 19.88px */,
  flex: '1 0 0',
  '&:hover': {
    color: 'var(--text-primary, #171A1C)',
  },
}));

const EditContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: 'var(--05, 0.25rem)',
  alignSelf: 'stretch',
});

const SavedSearch = ({ search, inFolder = false }) => {
  const log = useLogging();
  const { user } = useAuth();
  const {
    prospectFilters,
    prospectSearch,
    prospectType,
    setProspectSearch,
    setProspectFilters,
  } = useProspectSearch();
  const { state, dispatch } = useSite();

  const { mutate } = useGetSavedSearches();

  const isCompany = search?.org_search;
  const isManager = user?.permissions?.is_manager || user?.is_superuser;

  const [isDragging, setIsDragging] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [newName, setNewName] = React.useState('');

  const showEdit = isEditing;

  const handleEdit = (e) => {
    e.stopPropagation();
    setIsEditing(true);
    setNewName(search.name || '');
  };

  const handleNameChange = (e) => {
    setNewName(e.target.value);
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    setIsEditing(false);
    setNewName('');

    // If it was a new search and we are cancelling, we need to reset the filters
    if (!prospectSearch?.id) {
      setProspectFilters([]);
      setProspectSearch(state?.savedSearch?.prevSavedSearch || null);
      dispatch({
        type: SavedSearchActionTypes.SET_PREVIOUS_SAVED_SEARCH,
        payload: null,
      });
    }
  };

  const onSubmit = async () => {
    if (!newName) return;
    try {
      // update the search
      await updateSavedSearch({
        id: search.id,
        name: newName,
      });

      // update the current prospect search
      const updatedSearch = { ...search, name: newName };
      setProspectSearch(updatedSearch);

      log.event('savedSearchEditSuccess', {
        location: 'SavedSearch',
        action: 'onSubmit',
        object: updatedSearch,
        user: user,
      });
    } catch (err) {
      log.exception('Error saving search', {
        tags: {
          location: 'SavedSearch',
          action: 'onSubmit',
          user: user as any,
          error: err,
        },
      });
    } finally {
      mutate();
      setIsEditing(false);
    }
  };

  const handleClick = (e) => {
    // in these cases we do not want the click to do anything
    const canUpdate = !isEditing && !isDragging;
    if (!canUpdate) return;

    setProspectSearch(search);
    log.event('selectSavedSearch', {
      location: 'SavedSearch',
      action: 'handleClick',
      object: search,
      user: user,
    });
  };

  const handleDelete = async (e) => {
    e.stopPropagation();
    if (!confirm('Are you sure you want to delete this prospect search?')) {
      return;
    }
    try {
      // delete the search
      await deleteSavedSearch(search.id);
      log.event('savedSearchDeleteSuccess', {
        location: 'SavedSearch',
        action: 'handleDelete',
        object: search,
        user: user,
      });
    } catch (err) {
      log.exception('Error deleting search', {
        tags: {
          location: 'SavedSearch',
          action: 'handleDelete',
          user: user as any,
          error: err,
        },
      });
    } finally {
      mutate();
      setIsEditing(false);
      setProspectSearch(null);
    }
  };

  const handleDragStart = (e) => {
    setIsDragging(true);
    e.dataTransfer.setData('text/plain', search.id); // Attach file ID to the drag event
    log.event('Saved Search Dragged', {
      location: 'SavedSearch',
      action: 'handleDragStart',
      object: search,
      user: user,
    });
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    log.event('Saved Search Drag Ended', {
      location: 'SavedSearch',
      action: 'handleDragEnd',
      object: search,
      user: user,
    });
  };

  const isActive = prospectSearch?.id === search?.id && !isEditing;
  const isDisabled = !newName;

  return (
    <div
      draggable={!isEditing}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SearchContainer
        isActive={isActive}
        inFolder={inFolder}
        onClick={handleClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {showEdit ? (
          <EditContainer>
            <Input
              size="sm"
              color="neutral"
              placeholder="Saved search name"
              value={newName}
              onChange={handleNameChange}
              fullWidth
              autoFocus
              onFocus={(e) => e.target.select()}
            />
            <RoundButton icon={faTrash} onClick={handleDelete} sx />
            <RoundButton icon={faTimes} onClick={handleCancel} sx />
            <Tooltip
              variant="soft"
              color="neutral"
              title={
                <Typography level="body-xs">
                  Add a name to save the search.
                </Typography>
              }
              arrow
              placement="bottom"
              disableFocusListener={!isDisabled}
              disableHoverListener={!isDisabled}
              disableTouchListener={!isDisabled}
            >
              <span>
                <RoundButton
                  icon={faCheck}
                  variant="solid"
                  color="primary"
                  onClick={onSubmit}
                  disabled={isDisabled}
                  sx={{ background: 'var(--Primary-700, #2391D9)' }}
                />
              </span>
            </Tooltip>
          </EditContainer>
        ) : (
          <>
            <Title isActive={isActive}>{search?.name}</Title>
            {isCompany ? (
              <>
                {isManager && (
                  <HoverButton
                    isHovered={isHovered}
                    size="sm"
                    icon={faPencil}
                    onClick={handleEdit}
                  />
                )}
              </>
            ) : (
              <HoverButton
                isHovered={isHovered}
                size="sm"
                icon={faPencil}
                onClick={handleEdit}
              />
            )}
          </>
        )}
        {isActive && !isHovered && (
          <Icon
            icon={faCheck}
            style={{ color: 'var(--white, #FFFFFF)', marginRight: '0.5rem' }}
          />
        )}
      </SearchContainer>
    </div>
  );
};

export default SavedSearch;
