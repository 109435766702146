import useSWR from 'swr';
import { getTopProviders } from 'src/api';
import { QUERY_KEYS } from './constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { useNewProfile } from 'src/context';

const useGetTopProviders = ({
  providerId,
}: {
  providerId: string | number;
}) => {
  const { siteProviderQueryParams } = useNewProfile();
  const queryParams = getSearchQueryParamsForAPI({
    cpt: siteProviderQueryParams.cpt?.map((item) => item.value),
    hcpcs: siteProviderQueryParams.hcpcs?.map((item) => item.value),
    icd: siteProviderQueryParams.icd?.map((item) => item.value),
    drg: siteProviderQueryParams.drg?.map((item) => item.value),
    icdp: siteProviderQueryParams.icdp?.map((item) => item.value),
  });

  return useSWR(
    [QUERY_KEYS.GET_TOP_PROVIDERS, providerId, queryParams],
    () => getTopProviders({ providerId, queryParams }),
    {
      keepPreviousData: true,
    }
  );
};

export default useGetTopProviders;
