import { Box, Typography } from '@mui/joy';
import { formatPhoneNumber, hasContent } from 'src/utils';

import { contentStyles } from '../styleConstants';
import { useProfile } from 'src/context';

const AddressDisplay = ({ isPrimary }) => {
  const { details } = useProfile();
  const primary = details?.hcp?.general_v2?.Addresses?.primary_address ?? [];
  const secondary =
    details?.hcp?.general_v2?.Addresses?.secondary_addresses ?? [];

  const title = isPrimary
    ? 'Primary Address'
    : secondary.length > 1
    ? 'Secondary Addresses'
    : 'Secondary Address';
  const displayAddresses = isPrimary ? [primary] : secondary;

  return (
    hasContent(displayAddresses) && (
      <>
        <Box
          key={title}
          sx={{
            ...contentStyles(displayAddresses.length),
            alignItems: 'flex-start',
          }}
        >
          <Typography level="body-sm">{title}</Typography>
          <Box
            sx={{
              alignSelf: 'flex-end !important',
              marginTop: '0.25rem',
            }}
          >
            {displayAddresses?.map((address, idx) => {
              const formattedPhone = address.Phone
                ? formatPhoneNumber(address.Phone)
                : address.Phone;

              return (
                <Box
                  sx={{
                    textAlign: 'right',
                    marginBottom: '0.5rem',
                    color: '#3f4148',
                  }}
                  key={address.id || idx}
                >
                  <Typography level="body-xs" sx={{ color: '#3f4148' }}>
                    {address.Name || ''}
                    {address.Name && <br />}
                    {address.Address}
                    <br />
                    {address['City/State']}
                  </Typography>
                  {formattedPhone && (
                    <Typography level="body-xs">
                      <a href={`tel:${formattedPhone}`}>{formattedPhone}</a>
                    </Typography>
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      </>
    )
  );
};

export default AddressDisplay;
