import { Box, Typography } from '@mui/joy';
import React from 'react';

const SystemsCell = ({ row }) => {
  const systems = row?.systems || row?.provider?.systems;
  if (!systems) return null;

  return (
    <div
      style={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    >
      {systems?.map((system, index) => {
        return (
          <Box key={index}>
            <Typography
              level="body-sm"
              sx={{
                width: '100%',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                paddingRight: '0.5rem',
              }}
            >
              {system.name}
            </Typography>
          </Box>
        );
      })}
    </div>
  );
};

export default SystemsCell;
