import {
  GridToolbarColumnsButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium';
import React from 'react';
import ExportCommands from './ExportCommands';
import { Box } from '@mui/material';

const GridControls = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        gap: '1rem',
        pl: '0.5rem',
        pr: '0.5rem',
      }}
    >
      <GridToolbarColumnsButton />
      <ExportCommands />
      <GridToolbarQuickFilter />
    </Box>
  );
};

export default GridControls;
