import { useEffect, useState, useRef } from 'react';

/**
 * @description Detects if the table has a scrollbar
 * @param apiRef reference to the grid api
 * @param grid actual grid from classname
 * @returns Boolean
 */
function useTableScrollDetection(apiRef, grid) {
  const [hasScroll, setHasScroll] = useState(false);
  const debounceTimeout = useRef(null);

  useEffect(() => {
    if (!grid || !apiRef?.current || !apiRef.current.rootElementRef?.current)
      return;

    const rootElement = apiRef.current.rootElementRef.current;
    const viewport = rootElement.querySelector('.MuiDataGrid-virtualScroller');

    if (!viewport) return;

    let previousScrollState = false;

    const handleScrollChange = () => {
      if (debounceTimeout.current) clearTimeout(debounceTimeout.current);

      debounceTimeout.current = setTimeout(() => {
        const isVerticalScrollbarPresent =
          viewport.scrollHeight > viewport.clientHeight;

        const computedStyle = getComputedStyle(viewport);
        const areScrollbarsHidden = computedStyle.overflowY === 'hidden';

        const isScrollPresent =
          isVerticalScrollbarPresent && !areScrollbarsHidden;

        if (previousScrollState !== isScrollPresent) {
          setHasScroll(isScrollPresent);
          previousScrollState = isScrollPresent;
        }
      }, 100);
    };

    const resizeObserver = new ResizeObserver(() => {
      window.requestAnimationFrame(handleScrollChange);
    });
    resizeObserver.observe(viewport);

    handleScrollChange();

    return () => {
      resizeObserver.disconnect();
      if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
    };
  }, [apiRef, grid]);

  return hasScroll;
}

export default useTableScrollDetection;
