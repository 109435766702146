import { grey } from '@mui/material/colors';
import { Box, List, ListItem, styled, Typography } from '@mui/joy';

const RowLabel = styled(Typography)(() => ({
  color: grey[800],
  fontWeight: 400,
  fontSize: '0.75rem',
  fontStyle: 'italic',
}));

const ListItemRow = styled(ListItem)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: '0.5rem',
  width: '100%',
  padding: '0',
  minHeight: 'fit-content',
}));

const EducationItem = ({ item, isLast }) => {
  return (
    <Box
      sx={{
        width: '100%',
        borderBottom: isLast ? 'none' : `1px solid ${grey[200]}`,
        mb: 2,
        pb: 1,
        px: 2,
      }}
    >
      <Typography
        sx={{
          fontSize: '0.9rem',
          fontWeight: 500,
          borderBottom: `1px solid ${grey[200]}`,
        }}
      >
        {item.type}
      </Typography>
      <List>
        <ListItemRow>
          <RowLabel>Institution:</RowLabel>
          <Typography level="body-xs" textAlign="right">
            {item.institution || 'n/a'}
          </Typography>
        </ListItemRow>
        <ListItemRow>
          <RowLabel>Focus:</RowLabel>
          <Typography level="body-xs" textAlign="right">
            {item.focus || 'n/a'}
          </Typography>
        </ListItemRow>
        <ListItemRow>
          <RowLabel>Year:</RowLabel>
          <Typography level="body-xs" textAlign="right">
            {item.graduation_year || 'n/a'}
          </Typography>
        </ListItemRow>
      </List>
    </Box>
  );
};

export default EducationItem;
