import React, { useMemo } from 'react';
import { Box, Radio, Typography } from '@mui/joy';

const Lists = ({ title, lists }) => {
  const hasLists = lists?.length > 0;

  const orderedLists = useMemo(() => {
    return lists?.sort((a, b) => a.name?.localeCompare(b.name));
  }, [lists]);

  return (
    <>
      <Box
        sx={{
          width: '100',
          padding: '0.5rem',
          background: 'rgba(0,0,0,0.05)',
        }}
      >
        <Typography level="title-sm">{title}</Typography>
      </Box>
      {!hasLists && (
        <Box
          sx={{
            padding: '0.5rem',
            color: 'rgba(0,0,0,0.5)',
          }}
        >
          <Typography level="body-sm">No lists found</Typography>
        </Box>
      )}
      {hasLists &&
        orderedLists?.map((list, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              gap: '0.5rem',
              padding: '0.25rem 0',
              alignItems: 'center',
            }}
          >
            <Radio size="sm" value={list?.id} />
            {list?.name}
          </Box>
        ))}
    </>
  );
};

export default Lists;
