import React from 'react';

const ReportIFrame = ({ url }) => {
  return (
    <iframe
      src={url || ''}
      style={{
        width: '100%',
        height: '100%',
      }}
    />
  );
};

export default ReportIFrame;
