import { SiteActionTypes } from './actionTypes';

export type SiteState = {
  [key: string]: {
    search: string;
    selectedDate: {
      lte: string;
      gte: string;
    };
    selectedType: 'claims' | 'patients';
    selectedSearches: MedScout.SavedSearch[];
  };
};

const sharedState = {
  search: '',
  selectedDate: {
    lte: '2024',
    gte: '2019',
  },
  selectedType: 'claims' as 'claims' | 'patients',
  selectedSearches: [] as MedScout.SavedSearch[],
};

export const initialSiteState: SiteState = {
  hcp: { ...sharedState },
  center: { ...sharedState },
  clinic: { ...sharedState },
  hcp_groups: { ...sharedState },
};

export const siteReducer = (
  state: SiteState = initialSiteState,
  action: any
): SiteState => {
  const { location } = action.payload || {};
  switch (action.type) {
    case SiteActionTypes.SET_SEARCH:
      return {
        ...state,
        [location]: { ...state[location], search: action.payload.search },
      };
    case SiteActionTypes.SET_SELECTED_TYPE:
      return {
        ...state,
        [location]: {
          ...state[location],
          selectedType: action.payload.selectedType,
        },
      };
    case SiteActionTypes.SET_SELECTED_DATE:
      return {
        ...state,
        [location]: {
          ...state[location],
          selectedDate: action.payload.selectedDate,
        },
      };
    case SiteActionTypes.SET_SELECTED_SEARCHES:
      return {
        ...state,
        [location]: {
          ...state[location],
          selectedSearches: action.payload.selectedSearches,
        },
      };
    default:
      return state;
  }
};
