import { useEffect, RefObject } from 'react';

const useClickOutside = (
  menuRef: RefObject<HTMLElement> | RefObject<HTMLElement>[],
  anchorEl: HTMLElement | null,
  handleClose: () => void
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!Array.isArray(menuRef)) {
        if (
          menuRef.current &&
          !menuRef.current.contains(event.target as Node) &&
          anchorEl &&
          !anchorEl.contains(event.target as Node)
        ) {
          handleClose();
        }
      } else if (Array.isArray(menuRef)) {
        const refs = menuRef;
        const isOutside = refs
          .filter((ref) => ref.current) // Filter out refs where current doesn't exist
          .every((ref) => !ref.current.contains(event.target as Node));

        if (isOutside && anchorEl && !anchorEl.contains(event.target as Node)) {
          handleClose();
        }
      }
    };

    if (anchorEl) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchorEl, menuRef, handleClose]);
};

export default useClickOutside;
