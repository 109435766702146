import React from 'react';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Box, useTheme } from '@mui/material';
import { Link } from 'src/components';
import { useFlags } from 'launchdarkly-react-client-sdk';

const AffiliationCell = ({ row }) => {
  const { sameTabRouting } = useFlags();

  const theme = useTheme();
  if (!row.hcp?.affiliations && !row.affiliations) return null;

  const affiliations = (row) => {
    const affils = row.hcp?.affiliations || row.affiliations;

    return affils
      ?.filter((affil) => affil.name)
      ?.sort((a, b) => a.name.localeCompare(b.name));
  };

  return (
    <div>
      {affiliations(row)?.map((affil, index) => {
        return (
          <Box key={index}>
            <Link
              href={`/profiles/${affil.type}/${affil.provider_id}`}
              target={sameTabRouting ? '_self' : '_blank'}
              rel="noopener noreferrer"
              style={{
                fontSize: '0.65rem',
                color: theme.palette.primary.main,
                fontWeight: 500,
              }}
            >
              {affil.name} <Icon icon={faExternalLink} />
            </Link>
          </Box>
        );
      })}
    </div>
  );
};

export default AffiliationCell;
