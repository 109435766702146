import { Box } from '@mui/joy';
import React from 'react';

const CardHeader = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
      }}
      aria-label="Details"
    >
      {children}
    </Box>
  );
};

export default CardHeader;
