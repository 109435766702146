import React, { useMemo, useRef } from 'react';
import {
  Box,
  Chip,
  Dropdown,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/joy';
import { styled } from '@mui/material';
import { useOnClickOutside } from 'src/hooks';
import { grey } from '@mui/material/colors';

/**
 * NewProviderListTags
 * @param {*} { lists }
 * @return {*}
 */

const Tag = styled(Chip)({
  height: '1.75rem',
  borderRadius: '1rem',
  borderBottomLeftRadius: 0,
  borderTopRightRadius: 0,
  backgroundColor: '#e9e9e9',
  border: '1px #00000033 solid',
  minWidth: '3rem',
  whiteSpace: 'nowrap',
  marginLeft: '-1.25rem',
  paddingRight: '1rem',
  '&:only-child': {
    paddingRight: '0.5rem',
  },
  '&:first-of-type': {
    marginLeft: 0,
  },
  '&:hover': {
    backgroundColor: '#e9e9e9',
    cursor: 'pointer',
  },
});

const TagButton = styled(IconButton)({
  height: '1.75rem',
  maxHeight: '1.75rem',
  minHeight: '1.75rem',
  borderRadius: '1rem',
  borderBottomLeftRadius: 0,
  borderTopRightRadius: 0,
  color: 'unset',
  border: '1px dotted #000',
  backgroundColor: 'white',
  marginLeft: '-1.25rem !important',
  fontSize: '0.75rem',
  fontWeight: '600',
});

const NewProviderListTags = ({ lists = [] }: { lists: MedScout.List[] }) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const visibleTags = 3;

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (e: React.MouseEvent<HTMLElement>, list) => {
    e.stopPropagation();
    window.location.href = `/lists/${
      list.owner.id === list.owner.id ? 'my' : 'shared'
    }/${list.id}`;

    setAnchorEl(null);
  };

  const { displayedLists, remainingLists } = useMemo(() => {
    return {
      displayedLists: lists.slice(0, visibleTags),
      remainingLists: lists.slice(visibleTags),
    };
  }, [lists]);

  useOnClickOutside(menuRef, anchorEl, handleClose);

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        {displayedLists?.map((list, index) => {
          const fullName = [
            [list?.owner?.first_name, list.owner.last_name].join(' '),
            list.name,
          ].join(' > ');
          return (
            <Tooltip size="sm" key={index} title={fullName} arrow>
              <Tag
                key={index}
                onClick={(e) => handleItemClick(e, list)}
                aria-label="List Chip"
              >
                <Typography
                  level="body-xs"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    padding: '0 0.5rem',
                  }}
                >
                  {list.name}
                </Typography>
              </Tag>
            </Tooltip>
          );
        })}
        {remainingLists?.length > 0 && (
          <Dropdown>
            <TagButton onClick={handleClick}>
              <Typography level="body-xs" sx={{ fontWeight: 600 }}>
                +{lists.length - visibleTags}
              </Typography>
            </TagButton>
            <Menu
              placement="bottom-end"
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              sx={{
                boxShadow: 'sm',
                maxHeight: 300,
                overflow: 'auto',
                zIndex: 9999,
              }}
              ref={menuRef}
            >
              {remainingLists?.map((list, index) => (
                <MenuItem
                  key={index}
                  onClick={(e) => handleItemClick(e, list)}
                  sx={{
                    borderBottom: `1px solid ${grey[100]}`,
                    '&:last-child': {
                      borderBottom: 'none',
                    },
                  }}
                >
                  <Typography level="body-sm">{list.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Dropdown>
        )}
      </Box>
    </>
  );
};

export default NewProviderListTags;
