import React from 'react';
import { IconButton, VariantProp, ColorPaletteProp } from '@mui/joy';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

const RoundButton = ({
  color = 'neutral',
  disabled = false,
  icon,
  onClick,
  size = 'sm',
  sx,
  variant = 'plain',
}: {
  color?: ColorPaletteProp;
  disabled?: boolean;
  icon: any;
  onClick: (e: any) => void;
  size?: 'sm' | 'md' | 'lg';
  sx?: any;
  variant?: VariantProp;
}) => {
  return (
    <IconButton
      size={size}
      variant={variant}
      color={color}
      sx={{
        borderRadius: '100px',
        background: 'var(--Neutral-200, #DDDFE0)',
        ...sx,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon
        icon={icon}
        size="sm"
        style={{
          color: 'inherit',
        }}
      />
    </IconButton>
  );
};

export default RoundButton;
