import { Box } from '@mui/joy';
import React from 'react';
import {
  GridAddToListButton,
  GridColumnMenu,
  GridExportMenu,
} from './components';

const NewTableControls = ({
  columns,
  columnVisibilityModel,
  setColumnVisibilityModel,
  onExport,
  isExporting,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        gap: '0.5rem',
      }}
    >
      <GridAddToListButton />
      <Box sx={{ flex: 1 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            whiteSpace: 'nowrap',
            gap: '0.5rem',
          }}
        >
          <GridExportMenu onExport={onExport} isExporting={isExporting} />
          <GridColumnMenu
            columns={columns}
            columnVisibilityModel={columnVisibilityModel}
            setColumnVisibilityModel={setColumnVisibilityModel}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default NewTableControls;
