import {
  createContext,
  useReducer,
  useContext,
  useCallback,
  Dispatch,
  useEffect,
} from 'react';
import { useRouter } from 'next/router';
import { useGetSettings, useGetMappings } from 'src/hooks';
import { useAuth } from 'src/context';
import { exists } from 'src/utils';

const IntegrationActionTypes: { [key: string]: string } = {
  SET_MAPPINGS: 'SET_MAPPINGS',
  SET_CURRENT_MAPPING: 'SET_CURRENT_MAPPING',
  SET_CURRENT_OPTION: 'SET_CURRENT_OPTION',
  SET_SETTINGS: 'SET_SETTINGS',
};

type IntegrationState = {
  mappings: MedScout.Mapping[] | null;
  currentMapping: MedScout.Mapping | null;
  currentOption: MedScout.AutopopulateOption | MedScout.UserInputOption | null;
  settings: MedScout.Setting[] | null;
};

type IntegrationActions = {
  type: keyof typeof IntegrationActionTypes;
  payload: any;
};

const initialState: IntegrationState = {
  mappings: [],
  currentMapping: null,
  currentOption: null,
  settings: [],
};

const IntegrationContext = createContext<{
  state: IntegrationState;
  dispatch: Dispatch<IntegrationActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const integrationReducer = (
  state: IntegrationState,
  action: IntegrationActions
) => {
  const newState = { ...state };

  switch (action.type) {
    case IntegrationActionTypes.SET_MAPPINGS:
      newState.mappings = action.payload;
      break;
    case IntegrationActionTypes.SET_CURRENT_MAPPING:
      newState.currentMapping = action.payload;
      break;
    case IntegrationActionTypes.SET_CURRENT_OPTION:
      newState.currentOption = action.payload;
      break;
    case IntegrationActionTypes.SET_SETTINGS:
      newState.settings = action.payload;
      break;
  }

  return newState;
};

export const IntegrationsProvider = (props) => {
  const [state, dispatch] = useReducer(integrationReducer, initialState);
  const router = useRouter();
  const { integration } = router.query;

  const { user } = useAuth();
  const enableHooks = exists(user);

  const { data: settings } = useGetSettings({
    crm: integration?.toString(),
    enabled: enableHooks,
  });

  const { data: mappings } = useGetMappings({
    sort: 'label',
    order: 'asc',
    page: 0,
    pageSize: 100,
    crm: integration?.toString(),
    enabled: enableHooks,
  });

  useEffect(() => {
    if (!mappings?.results?.length) return;
    dispatch({
      type: IntegrationActionTypes.SET_MAPPINGS,
      payload: mappings?.results,
    });
  }, [mappings]);

  useEffect(() => {
    if (!settings?.results?.length) return;
    dispatch({
      type: IntegrationActionTypes.SET_SETTINGS,
      payload: settings?.results,
    });
  }, [settings]);

  return (
    <IntegrationContext.Provider value={{ state, dispatch }}>
      {props.children}
    </IntegrationContext.Provider>
  );
};

export const useIntegrations = () => {
  const context = useContext(IntegrationContext);
  if (!context) {
    throw new Error(
      'useIntegrations must be used within an IntegrationsProvider'
    );
  }

  const { state, dispatch } = context;

  const setMappings = useCallback(
    (mappings: MedScout.Mapping[]) => {
      dispatch({
        type: IntegrationActionTypes.SET_MAPPINGS,
        payload: mappings,
      });
    },
    [dispatch]
  );

  const setCurrentMapping = useCallback(
    (mapping: MedScout.Mapping) => {
      dispatch({
        type: IntegrationActionTypes.SET_CURRENT_MAPPING,
        payload: mapping,
      });
    },
    [dispatch]
  );

  const setCurrentOption = useCallback(
    (option: MedScout.AutopopulateOption | MedScout.UserInputOption) => {
      dispatch({
        type: IntegrationActionTypes.SET_CURRENT_OPTION,
        payload: option,
      });
    },
    [dispatch]
  );

  const setSettings = useCallback(
    (settings: MedScout.Setting[]) => {
      dispatch({
        type: IntegrationActionTypes.SET_SETTINGS,
        payload: settings,
      });
    },
    [dispatch]
  );

  return {
    ...state,
    setMappings,
    setCurrentMapping,
    setCurrentOption,
    setSettings,
  };
};
