import React from 'react';
import { useAuth } from 'src/context';
import { Tags } from 'src/components';
import { titleCase } from 'src/utils';

const ListNameCell = ({ row }) => {
  const { user } = useAuth();
  if (!row || !row.name) return null;

  const isSmart = row?.type === 'smart';
  const name = isSmart ? titleCase(row?.sub_type) : row?.name;
  const hrefType = isSmart
    ? 'smart'
    : row?.owner?.id === user?.id
    ? 'my'
    : 'team';

  const hrefMap = {
    my: `/lists/my/${row?.id}`,
    smart: `/lists/smart/${row?.id}`,
    team: `/lists/team/${row?.id}`,
  };

  const tag = {
    id: row?.id,
    label: name,
    fullText: name,
    href: hrefMap[hrefType],
    FormattedText: () => <span>{name}</span>,
    bgColor: isSmart ? '#F7E7A7' : row?.bgColor,
  };

  return <Tags tags={[tag]} tagType="list" />;
};

export default ListNameCell;
