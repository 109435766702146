import { request } from 'src/api/request';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

export const getProviderLists = async () => {
  const queryParams = getSearchQueryParamsForAPI({
    pageSize: 1500,
    sort: 'name',
    order: 'asc',
  });
  return await request({
    url: `/v1/provider-lists/lists/${queryParams}`, // accepts user_id
    method: 'GET',
  });
};

export const createList = async ({ listName }) => {
  if (!listName) return;
  return request({
    url: `/v1/provider-lists/lists/`,
    method: 'POST',
    data: {
      name: listName,
    },
  });
};

export const updateList = async ({ listId, listName, ownerId, sharedWith }) => {
  if (!listId) return;

  const newData = {};
  if (listName) newData['name'] = listName;
  if (ownerId) newData['owner_id'] = ownerId;
  if (sharedWith) newData['shared_user_ids'] = sharedWith;

  return request({
    url: `/v1/provider-lists/lists/${listId}/`,
    method: 'PATCH',
    data: newData,
  });
};

export const deleteList = async ({ listId }) => {
  if (!listId) return;

  return request({
    url: `/v1/provider-lists/lists/${listId}/`,
    method: 'DELETE',
  });
};

export const deleteListItem = async ({ listId, itemId }) => {
  if (!listId || !itemId) return;

  return request({
    url: `/v1/provider-lists/lists/${listId}/items/${itemId}/`,
    method: 'DELETE',
  });
};
