import { Button, Box, styled, useTheme } from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faPlusSquare,
  faPlusCircle,
  faPlus,
} from '@fortawesome/pro-solid-svg-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { IconButton } from '@mui/joy';

const StyledListButtonWrapper = styled(Box)<{
  variant: 'pill' | 'rectangle' | 'compact';
  newProfileHeader: boolean;
}>(({ theme, variant, newProfileHeader }) => ({
  border: `1px solid ${
    newProfileHeader ? '#CDD7E1' : theme.palette.primary.main
  }`,
  borderRadius: newProfileHeader
    ? '0.5rem'
    : variant === 'pill'
    ? '0'
    : '0.25rem',
  backgroundColor: 'white',
  borderTopLeftRadius: newProfileHeader
    ? '0.5rem'
    : variant === 'pill'
    ? '1rem'
    : '0.25rem',
  borderBottomRightRadius: newProfileHeader
    ? '0.5rem'
    : variant === 'pill'
    ? '1rem'
    : '0.25rem',
  display: 'flex',
  alignItems: 'center',
  gap: variant === 'pill' ? '0.25rem' : '0.5rem',
  paddingLeft: '0.5rem',
  overflow: 'hidden',
  cursor: 'pointer',
  '&:hover': { background: '#EFEFF1' },
}));

const StyledListButton = styled(Button)`
  font-size: 0.65rem;
  font-weight: 400;
  white-space: nowrap;
  border-radius: 0;
`;

const AddToListButton: React.FC<{
  onClick: () => void;
  variant?: 'rectangle' | 'pill' | 'compact';
}> = ({ onClick, variant = 'rectangle' }) => {
  const theme = useTheme();
  const { newProfileHeader } = useFlags();
  const iconVariant = newProfileHeader
    ? faPlus
    : variant === 'pill'
    ? faPlusCircle
    : faPlusSquare;

  const isCompact = variant === 'compact';
  return (
    <>
      {isCompact ? (
        <IconButton
          onClick={onClick}
          color="neutral"
          variant="outlined"
          aria-label="Add to List"
        >
          <Icon icon={iconVariant} size="sm" />
        </IconButton>
      ) : (
        <StyledListButtonWrapper
          variant={variant}
          onClick={onClick}
          newProfileHeader={newProfileHeader}
        >
          <Icon
            icon={iconVariant}
            color={newProfileHeader ? '#636B74' : theme.palette.primary.main}
            size={newProfileHeader ? 'sm' : 'lg'}
          />
          <StyledListButton
            variant={newProfileHeader ? 'text' : 'contained'}
            size="small"
            sx={
              variant === 'pill'
                ? {
                    borderTopLeftRadius: '0.95rem',
                    px: '1rem',
                  }
                : newProfileHeader
                ? {
                    pl: 0,
                    pr: '1rem',
                    color: '#636B74',
                    fontSize: '14px',
                    fontWeight: 600,
                    '&:hover': { background: 'none' },
                  }
                : { px: '1rem' }
            }
          >
            Add to List
          </StyledListButton>
        </StyledListButtonWrapper>
      )}
    </>
  );
};

export default AddToListButton;
