import { Typography } from '@mui/joy';
import React from 'react';

const Header = ({ field, ...props }) => {
  return (
    <Typography level="title-sm" sx={{ fontWeight: 500 }}>
      {props?.colDef?.headerName}
    </Typography>
  );
};

export default Header;
