import useSWR from 'swr';
import { getEntityActivities } from 'src/api';
import { QUERY_KEYS } from './constants';

const useGetEntityActivities = (providerId: string) => {
  const shouldFetch = !!providerId;
  return useSWR(
    shouldFetch ? [QUERY_KEYS.GET_ENTITY_ACTIVITIES, providerId] : null,
    () => getEntityActivities({ providerId })
  );
};

export default useGetEntityActivities;
