import { Box, Card, Typography } from '@mui/joy';
import { faUserTie } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { hasContent } from 'src/utils';
import { headerStyle } from './styleConstants';
import EducationItem from 'src/modules/Profile/components/Views/components/EducationItem';
import InfoUnavailable from './InfoUnavailable';
import { useProfile } from 'src/context';

const ProfileEducationDetails = () => {
  const { details } = useProfile();

  const educationRecords = details?.hcp?.education || [];

  return (
    <>
      <Card sx={{ padding: 0 }}>
        <Box sx={headerStyle} aria-label="Medical School">
          <Icon icon={faUserTie} />
          <Typography
            sx={{
              display: 'inline-block',
              marginLeft: '0.5rem',
            }}
          >
            Education
          </Typography>
        </Box>
        <Box>
          {hasContent(educationRecords) ? (
            educationRecords.map((item, i) => {
              const isLast = i === educationRecords.length - 1;

              return (
                <EducationItem
                  key={`${item.id}-${i}`}
                  item={item}
                  isLast={isLast}
                />
              );
            })
          ) : (
            <InfoUnavailable />
          )}
        </Box>
      </Card>
    </>
  );
};

export default ProfileEducationDetails;
