import useSWR from 'swr';
import { getActivities } from 'src/api';
import { QUERY_KEYS } from './constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { exists } from 'src/utils';

interface GetActivitiesParams {
  reps?: string[];
  activityTypes?: string[];
  timeframe?: string;
  sort?: string;
  order?: 'asc' | 'desc';
  page?: number;
  pageSize?: number;
  q?: string;
}

const useGetActivities = ({
  reps,
  activityTypes,
  timeframe,
  sort,
  order,
  page,
  pageSize,
  q,
}: GetActivitiesParams) => {
  const queryParams = getSearchQueryParamsForAPI({
    sort,
    order,
    page: page + 1,
    pageSize,
    q,
  });

  const compileFilterQuery = (key: string, items: any[]) =>
    items?.length ? items.map((item) => `${key}=${item}`).join('&') : undefined;

  const repQuery = compileFilterQuery('rep', reps);
  const activityQuery = compileFilterQuery('activity_type', activityTypes);
  const timeframeQuery = exists(timeframe)
    ? `time_range=${timeframe}`
    : undefined;

  const queryString = [queryParams, repQuery, activityQuery, timeframeQuery]
    .filter(Boolean)
    .join('&');

  return useSWR([QUERY_KEYS.GET_ACTIVITIES, queryString], () =>
    getActivities({ queryString })
  );
};

export default useGetActivities;
