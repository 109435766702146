import { Box } from '@mui/joy';
import React from 'react';

interface TableRowProps {
  color?: string;
  borderColor?: string;
  children: React.ReactNode;
}

const TableRow = ({ ...props }: TableRowProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${props.borderColor}`,
        padding: '0.5rem 1rem',

        backgroundColor: props.color,
      }}
    >
      {props.children}
    </Box>
  );
};

export default TableRow;
