import { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Select,
  Option,
  ListItemDecorator,
} from '@mui/joy';
import { format as formatDate } from 'date-fns';

import { useGetDataIssueDetails, useResolveDataIssue } from 'src/hooks';
import { useLogging, useNotification } from 'src/context';

import { DataIssueTypeSelector } from './DataIssueTypeSelector';
import { DataIssueAddressSuggestions } from './DataIssueAddressSuggestions';
import { DataIssueEmailSuggestions } from './DataIssueEmailSuggestions';
import { DataIssueComments } from './DataIssueComments';
import { DataIssuePhone } from './DataIssuePhone';
import { default as ProviderNameCell } from 'src/components/Table/ProviderNameCell';

interface ResolveDataIssueDialogProps {
  open: boolean;
  onClose: () => void;
  dataIssueId: string;
}

const ResolveDataIssueDialog = ({
  open,
  onClose,
  dataIssueId,
}: ResolveDataIssueDialogProps) => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const { mutateAsync: resolveDataIssue, isLoading: isSubmitLoading } =
    useResolveDataIssue();

  const { data: dataIssue, isLoading } = useGetDataIssueDetails({
    dataIssueId,
  });

  const issueType = dataIssue?.issue_type?.toLowerCase();

  const [email, setEmail] = useState('');
  const [emailId, setEmailId] = useState(null);
  const [phone, setPhone] = useState('');
  const [addressData, setAddressData] = useState(null);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (dataIssue) {
      setStatus(dataIssue.status);
    }
    setEmail(dataIssue?.new_data?.email || '');
    setEmailId(dataIssue?.new_data?.email || 'other');
    setPhone(dataIssue?.new_data?.phone || '');
    setAddressData(dataIssue?.new_data);
  }, [dataIssue]);

  const handleOnClose = () => {
    setEmail('');
    setEmailId(null);
    setPhone('');
    setAddressData(null);
    onClose();
  };

  const handleSubmit = async () => {
    const dataToSubmit = {
      phone: phone ? { phone } : null,
      address: addressData ? addressData : null,
      email: email ? { email } : null,
    };
    try {
      await resolveDataIssue({
        dataIssueId,
        newData: dataToSubmit[issueType],
        status,
      });

      handleOnClose();

      setNotification({
        title: 'Data Issue Resolved',
        message: 'The data issue has been updated.',
        type: 'success',
      });
    } catch (e) {
      setNotification({
        title: 'Error',
        message: `An error occurred while updating data issue. Our team has been notified. ${e}`,
        type: 'error',
      });
      log.exception(e);
    }
  };

  return isLoading ? (
    <Modal
      open={open}
      onClose={handleOnClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <ModalDialog minWidth={'md'}>Loading</ModalDialog>
    </Modal>
  ) : (
    <Modal
      open={open}
      onClose={handleOnClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <ModalDialog minWidth={'md'}>
        <DialogTitle>Data Issue Report</DialogTitle>
        <Grid container spacing={2}>
          <Grid xs={3}>
            <ProviderNameCell
              row={{
                name: dataIssue?.name,
                city: dataIssue?.city,
                state: dataIssue?.state,
                object_type: dataIssue?.content_type,
                provider_id: dataIssue?.provider_id,
              }}
            />
          </Grid>
          <Grid xs={3}>
            <Typography level="title-sm">Status</Typography>
            <Select
              size="sm"
              value={status}
              placeholder="Status"
              onChange={(e, value) => setStatus(value)}
            >
              <Option value="APPROVED">
                <ListItemDecorator>🟢</ListItemDecorator> Approved
              </Option>
              <Option value="REJECTED">
                <ListItemDecorator>🔴</ListItemDecorator> Rejected
              </Option>
              <Option value="UNRESOLVED">
                <ListItemDecorator>🟡</ListItemDecorator> Unresolved
              </Option>
              <Option value="REQUIRES_DATA_TEAM_REVIEW">
                <ListItemDecorator>🟡</ListItemDecorator> Resolved with Followup
              </Option>
              <Option value="RESOLVED" disabled={true}>
                <ListItemDecorator>🟢</ListItemDecorator> Resolved
              </Option>
              <Option value="RESOLVED_WITH_FOLLOWUP" disabled={true}>
                <ListItemDecorator>🟢</ListItemDecorator> Resolved with Followup
              </Option>
            </Select>
          </Grid>
          <Grid xs={3}>
            <Typography level="title-sm">Reported At</Typography>
            <Typography level="body-sm" sx={{ textOverflow: 'ellipsis' }}>
              {dataIssue?.reported_at
                ? formatDate(new Date(dataIssue?.reported_at), 'PPp')
                : '--'}
            </Typography>
          </Grid>
          <Grid xs={3}>
            <Typography level="title-sm">Reported By</Typography>
            <Typography
              level="body-sm"
              sx={{
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {dataIssue?.reporting_user_email}
            </Typography>
          </Grid>
        </Grid>

        <DialogContent>
          <DataIssueTypeSelector
            providerType={dataIssue?.content_type}
            issueType={issueType}
          />
          {dataIssue?.content_type && issueType === 'address' && (
            <DataIssueAddressSuggestions
              issueType={issueType}
              providerId={dataIssue?.provider_id}
              providerType={dataIssue?.content_type}
              dataIssue={dataIssue}
              setAddressData={setAddressData}
            />
          )}
          {dataIssue?.content_type && issueType === 'email' && (
            <DataIssueEmailSuggestions
              issueType={issueType}
              providerId={dataIssue?.provider_id}
              providerType={dataIssue?.content_type}
              setEmail={setEmail}
              email={email}
              emailId={emailId}
              setEmailId={setEmailId}
            />
          )}
          {issueType && issueType === 'phone' && (
            <DataIssuePhone phone={phone} setPhone={setPhone} />
          )}
          {dataIssue && <DataIssueComments comments={dataIssue?.comments} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} loading={isSubmitLoading}>
            Submit
          </Button>
          <Button color="danger" variant="soft" onClick={handleOnClose}>
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default ResolveDataIssueDialog;
