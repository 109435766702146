import React from 'react';
import { Box, Button, Input, Typography } from '@mui/joy';

const PatientJourney = () => {
  const [dashboardId, setDashboardId] = React.useState('');
  const [dashboardUrl, setDashboardUrl] = React.useState(null);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDashboardId(e.target.value);
  };

  const loadDashboard = () => {
    setDashboardUrl(
      `https://dbc-c28c6765-7012.cloud.databricks.com/embed/dashboardsv3/${dashboardId}?o=3290031117246624`
    );
  };
  return (
    <Box
      sx={{
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          padding: 2,
          maxWidth: '35rem',
        }}
      >
        <Input
          placeholder="Add a dashboard Id"
          value={dashboardId}
          onChange={onChange}
          sx={{
            flex: 1,
          }}
        />
        <Button onClick={loadDashboard}>Load Dashboard</Button>
      </Box>
      {dashboardUrl ? (
        <iframe
          src={dashboardUrl}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      ) : (
        <Box
          sx={{
            padding: 2,
          }}
        >
          <Typography level="title-md">
            Enter a dashboard id to load a dashboard.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default PatientJourney;
