import React from 'react';
import { APIProvider } from '@vis.gl/react-google-maps';

import { MapProvider, MedScoutMapProvider } from 'src/context';
import { DiscoveryMap } from 'src/components';

const Discovery = () => {
  return (
    <MapProvider>
      <APIProvider apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY}>
        <MedScoutMapProvider>
          <DiscoveryMap />
        </MedScoutMapProvider>
      </APIProvider>
    </MapProvider>
  );
};

export default Discovery;
