import { useRouter } from 'next/router';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { Toggle } from 'src/components';
import { faSignInAlt, faSignOutAlt } from '@fortawesome/pro-solid-svg-icons';
import { Select, MenuItem } from '@mui/material';
import { useProspectSearch, useLogging } from 'src/context';
import { useEffect } from 'react';

const RelationshipSwitch = ({ legacy = true }) => {
  const router = useRouter();
  const { type, id, view, relationship, mode } = router.query as {
    type: string;
    id: string;
    view: string;
    relationship: string;
    mode: 'code_volume' | 'sending' | 'receiving';
  };

  const { prospectMode, setProspectMode } = useProspectSearch();
  const log = useLogging();

  function switchRelationship(newRelationship) {
    if (!legacy) {
      const isOutbound = newRelationship === 'outbound';
      const isSending = prospectMode === 'sending' && isOutbound;
      const isReceiving = prospectMode === 'receiving' && !isOutbound;

      setProspectMode(
        isReceiving ? 'sending' : isSending ? 'receiving' : prospectMode
      );
    }
    const queryParams = getSearchQueryParamsForAPI({
      relationship: newRelationship,
    });

    router.push(`/profiles/${type}/${id}/${view}${queryParams}`, undefined, {
      shallow: true,
    });
  }

  useEffect(() => {
    if (legacy) return;
    if (!mode) {
      return setProspectMode(
        relationship === 'outbound' ? 'receiving' : 'sending'
      );
    }
    setProspectMode(mode);
  }, [mode]);

  return (
    <Toggle
      leftIcon={faSignInAlt}
      rightIcon={faSignOutAlt}
      leftLabel="Inbound"
      rightLabel="Outbound"
      onLeft={() => switchRelationship('inbound')}
      onRight={() => switchRelationship('outbound')}
      showLabels={true}
      status={relationship === 'outbound' ? 'right' : 'left'}
      slots={
        !legacy
          ? {
              action: () => null,
              thumb: ({ sx, ...props }) => (
                <Select
                  value={prospectMode}
                  size="small"
                  onChange={(e) => {
                    log.event('Mode Changed', {
                      mode: e.target.value,
                    });
                    setProspectMode(e.target.value as typeof prospectMode);
                  }}
                  sx={{
                    borderRadius: '1rem',
                    '--Switch-thumbHeight': '1.6rem',
                    '--Switch-thumbWidth': '3.5rem',
                    '--mui-palette-text-disabled': 'white',
                    '& .MuiSelect-select': {
                      width: 'var(--Switch-thumbWidth)',
                      fontSize: '0.8rem',
                      fontWeight: 400,
                      padding: '0.15rem 0.75rem',
                      color: 'white',
                    },
                    '&.Mui-disabled .MuiSelect-icon': {
                      display: 'none',
                    },
                    '& .MuiSelect-icon': {
                      color: 'white',
                    },
                  }}
                  {...props}
                >
                  <MenuItem value="code_volume">Treating</MenuItem>
                  <MenuItem
                    value="receiving"
                    hidden={relationship === 'inbound' || !relationship}
                  >
                    Referred
                  </MenuItem>
                  <MenuItem
                    value="sending"
                    hidden={relationship === 'outbound'}
                  >
                    Referred
                  </MenuItem>
                </Select>
              ),
            }
          : {}
      }
      sx={
        !legacy
          ? {
              width: '8rem',
              '& .MuiSwitch-thumb': {
                transform: 'translate(0, 0)',
              },
              '&.Mui-checked .MuiSwitch-thumb': {
                transform: 'translate(20px, 0)',
              },
            }
          : {}
      }
    />
  );
};

export default RelationshipSwitch;
