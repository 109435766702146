import useSWR from 'swr';
import { getNewSavedSearches } from 'src/api';
import { QUERY_KEYS } from './constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { useSite } from 'src/context';
/**
 * Custom hook to fetch saved searches for the selected company.
 * @returns The result of the query.
 */

// TODO: replace existing useGetSavedSearches with this hook
export default function useGetNewSavedSearches() {
  const { state } = useSite();

  const queryParams = state?.savedSearch?.queryParams
    ? getSearchQueryParamsForAPI(state?.savedSearch?.queryParams)
    : '';
  return useSWR(
    [QUERY_KEYS.saved_searches, queryParams],
    () => getNewSavedSearches(queryParams),
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
    }
  );
}
