import { useState } from 'react';
import { Tags, TypeIcon } from 'src/components';
import {
  Link,
  Button,
  Box,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  Typography,
  styled,
} from '@mui/material';
import {
  faExternalLinkAlt,
  faNoteSticky,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { useLogging } from 'src/context';
import { format as formatDate } from 'date-fns';
import { COLOR_MAP } from 'src/utils/constants/scss-variables.constants';
import { amber } from '@mui/material/colors';
import { exists, hasContent } from 'src/utils';
import theme from 'src/styles/theme';
import { useFlags } from 'launchdarkly-react-client-sdk';

const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '1.1rem',
  height: '100%',
  marginRight: '.5rem',
}));

const CustomContent = ({ notes }) => {
  return (
    <>
      <Box
        sx={{
          overflowY: 'auto',
          maxHeight: 250,
          maxWidth: 350,
          p: '1rem',
        }}
      >
        {notes.map((note, i) => {
          const isFirst = i === 0;

          return (
            <div
              key={`note_${note.id}`}
              style={{
                borderTop: isFirst ? 'none' : '1px solid',
                borderColor: COLOR_MAP['gray-lighter'],
                padding: '10px 10px 5px',
                paddingTop: isFirst ? 0 : '10px',
              }}
            >
              <Typography
                component={'time'}
                sx={{
                  flexShrink: 0,
                  fontSize: 10,
                  fontWeight: 500,
                }}
                dateTime={note.created_at}
              >
                {formatDate(new Date(note.created_at), 'PPp')}
              </Typography>
              <Typography sx={{ whiteSpace: 'pre-wrap', fontSize: '1rem' }}>
                &ldquo;{note.note}&rdquo;
              </Typography>
            </div>
          );
        })}
      </Box>
    </>
  );
};

const NoteTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    borderLeft: `2px solid ${theme.palette.highlight.main}`,
    boxShadow: theme.shadows[6],
  },
  [` .${tooltipClasses.arrow}:before`]: {
    backgroundColor: theme.palette.highlight.main,
  },
}));

const NameCell = ({ row }) => {
  const log = useLogging();
  const { sameTabRouting } = useFlags();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const showLink = row.type !== 'group_pac' && row.type !== 'system';
  const contentType = exists(row.type)
    ? row.type
    : exists(row.content_type)
    ? row.content_type
    : 'hcp';
  const linkUrl = `/profiles/${contentType}/${row?.provider_id}`;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {showLink ? (
          <Link
            href={linkUrl}
            target={sameTabRouting ? '_self' : '_blank'}
            rel="noopener noreferrer"
            sx={{ flexGrow: 1 }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {exists(row.type) && (
                <IconContainer>
                  <TypeIcon
                    type={row.type}
                    style={{
                      color: theme.palette.primary.main,
                    }}
                  />
                </IconContainer>
              )}
              {row.name || 'n/a'}
              <Icon
                icon={faExternalLinkAlt}
                size="sm"
                style={{ marginLeft: '0.25rem' }}
              />
            </Box>
          </Link>
        ) : (
          <>{row.name || 'n/a'}</>
        )}
        {hasContent(row.notes) && (
          <NoteTooltip
            title={<CustomContent notes={row.notes} />}
            placement="right"
            disableFocusListener
            onClose={() => setTooltipOpen(false)}
            open={tooltipOpen}
            arrow
          >
            <Button
              variant="text"
              endIcon={
                <Icon
                  icon={faNoteSticky}
                  style={{ fontSize: '1rem' }}
                  color={amber[300]}
                />
              }
              sx={{ fontSize: '0.75rem' }}
              onClick={() => {
                log.event('ListNotesClickedOpen');
                setTooltipOpen((state) => !state);
              }}
              size="small"
            >
              {row.notes.length} {row.notes.length === 1 ? 'Note' : 'Notes'}
            </Button>
          </NoteTooltip>
        )}
      </Box>
      {row.tags?.length > 0 && !exists(row.type) && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tags tags={row.tags} tagType="tag" size="small" />
        </Box>
      )}
    </Box>
  );
};

export default NameCell;
