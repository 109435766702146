export const SiteActionTypes: { [key: string]: string } = {
  SET_SEARCH: 'SET_SEARCH',
  SET_SELECTED_TYPE: 'SET_SELECTED_TYPE',
  SET_SELECTED_DATE: 'SET_SELECTED_DATE',
  SET_SELECTED_SEARCHES: 'SET_SELECTED_SEARCHES',
};

export const SavedSearchActionTypes: { [key: string]: string } = {
  // Search Folders
  SET_ACTIVE_FOLDER: 'SET_ACTIVE_FOLDER',
  SET_FOLDERS: 'SET_FOLDERS',
  SET_TEMP_FOLDER: 'SET_TEMP_FOLDER',
  REMOVE_TEMP_FOLDER: 'REMOVE_TEMP_FOLDER',
  // Saved Search
  SET_TEMP_SAVED_SEARCH: 'SET_TEMP_SAVED_SEARCH',
  REMOVE_TEMP_SAVED_SEARCH: 'REMOVE_TEMP_SAVED_SEARCH',
  SET_MOVED_SAVED_SEARCH: 'SET_MOVED_SAVED_SEARCH',
  SET_PREVIOUS_SAVED_SEARCH: 'SET_PREVIOUS_SAVED_SEARCH',
  // Accordion
  SET_ACCORDION: 'SET_ACCORDION',
  // queryParams
  SET_QUERY_PARAMS: 'SET_QUERY_PARAMS',
};

export const ProfileActionTypes: { [key: string]: string } = {
  OPEN_PROFILE_DRAWER: 'OPEN_PROFILE_DRAWER',
  CLOSE_PROFILE_DRAWER: 'CLOSE_PROFILE_DRAWER',
  SET_CURRENT_PROVIDER: 'SET_CURRENT_PROVIDER',
  SET_QUERY_PARAMS: 'SET_QUERY_PARAMS',
};
