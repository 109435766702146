import React from 'react';
import { Box } from '@mui/joy';

interface MarkerCircleProps {
  featureId: number;
  sizeAsText: string;
  isHovered: boolean;
  onMouseOut: () => void;
  handleHoverMenu: (e: any, featureId: number) => void;
}

const MarkerCircle = ({
  featureId,
  sizeAsText,
  isHovered,
  onMouseOut,
  handleHoverMenu,
}: MarkerCircleProps) => {
  return (
    <Box
      onMouseOver={(e) => handleHoverMenu(e, featureId)}
      onMouseLeave={onMouseOut}
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: isHovered ? '#2391d9' : '#693cbd',
        color: 'white',
        border: '2px solid white',
      }}
    >
      {sizeAsText}
    </Box>
  );
};

export default MarkerCircle;
