import { Box, Typography } from '@mui/joy';
import React from 'react';
import { NewGridVolumeColumnMenu } from '../TableControls/components';
interface NewTableSecondaryHeaderProps {
  columns: any[];
  columnVisibilityModel: any;
  setColumnVisibilityModel: any;
  showVolumeMenu?: boolean;
  isMobile?: boolean;
}

const NewTableSecondaryHeader = ({
  isMobile = false,
}: NewTableSecondaryHeaderProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '0.5rem',
        width: '100%',
      }}
    >
      <Box
        sx={{
          flex: 1,
          flexShrink: 0,
        }}
      >
        <Typography
          sx={{
            color: 'var(--text-primary, #171A1C)',
            fontFamily: 'Inter',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '155%' /* 31px */,
          }}
        >
          Volume
        </Typography>
        <Typography
          sx={{
            color: 'var(--neutral-600, #555E68)',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '144%' /* 17.28px */,
          }}
        >
          Show number of patients or claims for your pre-saved targeting
          criteria during a given timeframe.
        </Typography>
      </Box>
    </Box>
  );
};

export default NewTableSecondaryHeader;
