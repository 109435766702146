import { Container } from '@mui/joy';
import { useAuth } from 'src/context';
import ProfileSummary from './components/ProfileSummary/ProfileSummary';
import { PayerMix } from 'src/modules/Profile/components/Views/components/PayerMix';
import ProfileCenterDetails from './components/ProfileDetails/ProfileCenterDetails';
import ProfileMap from './components/ProfileMap';
import ProfileProfessionalMix from './components/ProfileProfessionalMix/ProfileProfessionalMix';
import ProfileExecTeam from './components/ProfileExecTeam';
import { Notes } from 'src/modules/Profile/components';
import ProfileCenterOrganizations from './components/ProfileCenterOrganizations';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ProfileTopProviders, GridContainer, GridColumn } from './components';

const CenterProfile = ({
  singleColumn = false,
}: {
  singleColumn?: boolean;
}) => {
  const { user } = useAuth();
  const { useProfileAiSummary, newProfileHeader } = useFlags();

  const userAiProvider = user?.company?.sites_summary && useProfileAiSummary;

  return (
    <Container maxWidth="lg" disableGutters sx={{ overflow: 'hidden' }}>
      <GridContainer singleColumn={singleColumn}>
        <GridColumn>
          {userAiProvider && <ProfileSummary />}
          {!newProfileHeader && <ProfileMap />}
          <ProfileCenterOrganizations />
          <ProfileExecTeam />
          <ProfileCenterDetails />
        </GridColumn>
        <GridColumn>
          <ProfileTopProviders />
          <PayerMix />
          <Notes />
          <ProfileProfessionalMix />
        </GridColumn>
      </GridContainer>
    </Container>
  );
};

export default CenterProfile;
