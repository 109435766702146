import { useQuery, UseQueryResult } from 'react-query';
import { request } from 'src/api/request';
import { INTEGRATION_KEYS } from './constants';

interface GetCrmObjectsProps {
  crm: string;
  crmType: string;
  writable_only?: boolean;
  isThirdParty?: boolean;
}

const useGetCrmObjects = ({
  crm,
  crmType,
  writable_only = true,
  isThirdParty = false,
}: GetCrmObjectsProps) => {
  return useQuery(
    [INTEGRATION_KEYS.GET_CRM_OBJECTS, crmType, crm, writable_only],
    async () => {
      return await request({
        url: `/v1/integrations/crm-object/${crmType?.toLowerCase()}/destination-fields/?crm=${crm}&writable_only=${writable_only}`,
        method: 'GET',
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled: !isThirdParty && !!crmType && !!crm,
    }
  ) as UseQueryResult<MedScout.CRMApiObject[]>;
};

export default useGetCrmObjects;
