import { useEffect } from 'react';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { COLOR_MAP } from 'src/utils/constants/scss-variables.constants';
import { useLogging, useMedScoutMap } from 'src/context';
import { calculateCirclePolygon } from 'src/components/Discovery/utils/calculateCirclePolygon';

const CircleContent = () => {
  const log = useLogging();
  const map = useMap();
  const drawing = useMapsLibrary('drawing');

  const {
    currentCircleRef,
    currentOverlay,
    currentTerritory,
    drawingManagerRef,
    drawingMode,
    isEditing,
    radius,
    setRadius,
    setTerritoryPolygons,
  } = useMedScoutMap();

  // Sets the radius if its changed on the territory panel
  useEffect(() => {
    if (!currentCircleRef.current) return;

    // get the current radius from the circle
    const currentRadius = currentCircleRef.current.getRadius();
    if (currentRadius !== radius) {
      currentCircleRef.current.setRadius(radius);
    }
  }, [radius, currentCircleRef]);

  const handleDrawnCircle = () => {
    if (!drawingMode || drawingMode !== 'RADIUS') return;
    if (currentOverlay) currentOverlay.setMap(null);

    const maps = google?.maps;

    drawingManagerRef.current = new drawing.DrawingManager({
      drawingMode: null,
      drawingControl: false,
      circleOptions: {
        strokeColor: COLOR_MAP['blue-dark'],
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: COLOR_MAP['blue-dark'],
        fillOpacity: 0.25,
        editable: true,
        draggable: true,
      },
    });

    drawingManagerRef?.current.setMap(map);

    let debounceTimeout;

    // Function to initialize the circle
    const initializeCircle = (center, circleRadius) => {
      if (!center || !circleRadius) return;
      if (currentCircleRef.current) {
        currentCircleRef.current?.setMap(null);
      }

      const circle = new maps.Circle({
        strokeColor: COLOR_MAP['blue-dark'],
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: COLOR_MAP['blue-dark'],
        fillOpacity: 0.25,
        editable: true,
        draggable: true,
        radius: circleRadius,
        center: center,
      });

      circle.setMap(map);
      currentCircleRef.current = circle;

      const updateCircleState = () => {
        if (debounceTimeout) clearTimeout(debounceTimeout);

        debounceTimeout = setTimeout(() => {
          const updatedCircle = {
            center: {
              lat: circle.getCenter().lat(),
              lng: circle.getCenter().lng(),
            },
            radius: circle.getRadius(),
          };

          // Calculate polygon points for the circle
          const polygonPoints = calculateCirclePolygon(
            circle.getCenter(),
            circle.getRadius(),
            100
          );

          setTerritoryPolygons({
            [drawingMode]: { ...updatedCircle, points: polygonPoints },
          });

          setRadius(circle.getRadius());
        }, 150);
      };

      circle.addListener('center_changed', () => {
        requestAnimationFrame(updateCircleState);
      });

      circle.addListener('radius_changed', () => {
        requestAnimationFrame(updateCircleState);
      });

      updateCircleState();
    };

    maps.event.addListener(map, 'click', (event) => {
      drawingManagerRef?.current?.setDrawingMode(null);
      initializeCircle(event.latLng, radius);
      log.event('Create Circle Territory');
    });

    // TODO: Allow multiple circles in the future
    // make sure that currentTerritory is a circle
    const isCircle = currentTerritory?.source?.circles[0]?.center;

    // If editing and is a circle, initialize the circle with the current territory
    if (isEditing && isCircle) {
      const source = currentTerritory.source?.circles[0];
      if (source) {
        initializeCircle(source.center, source.radius);
        log.event('Edit Circle Territory', {
          territory: currentTerritory,
        });
      }
    }
  };

  useEffect(() => {
    if (!map || !drawing) return;

    handleDrawnCircle();

    return () => {
      const maps = google?.maps;
      if (maps) {
        // Clear the current overlay and circle
        maps.event.clearListeners(map, 'click');
        currentCircleRef.current?.setMap(null);
        drawingManagerRef.current?.setMap(null);
      }
    };
  }, [drawingMode, map, drawing]);

  return null;
};

export default CircleContent;
