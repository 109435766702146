import { ReactNode } from 'react';

const TableRowItem = ({
  children,
  width,
}: {
  children?: ReactNode;
  width?: string;
}) => {
  return (
    <td
      style={{
        width: width,
        display: 'flex',
        padding: '1.5rem 1rem!important',
        alignItems: 'center',
        alignSelf: 'stretch',
        height: '56px',
      }}
    >
      {children}
    </td>
  );
};

export default TableRowItem;
