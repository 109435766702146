export const activityData = [
  {
    label: 'Phone Call',
    value: 'call',
  },
  {
    label: 'Conference',
    value: 'conference',
  },
  {
    label: 'Note',
    value: 'note',
  },
  {
    label: 'Office Visit',
    value: 'visit',
  },
  {
    label: 'Text Message',
    value: 'text',
  },
];

export const activityMap = {
  call: 'Phone Call',
  conference: 'Conference',
  note: 'Note',
  visit: 'Office Visit',
  text: 'Text Message',
};
