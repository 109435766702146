import { exists } from '../exists/exists';

export const titleCase = (str: string) => {
  if (!exists(str)) return '';
  return str
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/-/g, ' ')
    .replace(
      /(^|\s|[^a-zA-Z_])([a-z])/g,
      (match, prefix, letter) => prefix + letter.toUpperCase()
    );
};
