import { Select, Option, styled, Typography, Box } from '@mui/joy';
import { useProfile } from 'src/context';

const SelectorLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '1.125rem' /* 18px */,
  paddingBottom: '0.5rem',
}));

const SelectorDescription = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '1.5rem' /* 24px */,
  marginTop: '0.35rem',
}));

const EducationToggle = ({ selectedOption, setSelectedOption }) => {
  const { details } = useProfile();
  const handleSelectionChange = (event, newValue) => {
    setSelectedOption(newValue);
  };
  const menuItems = [
    { value: 'Residency', label: 'Residency' },
    { value: 'Fellowship', label: 'Fellowship' },
    { value: 'Internship', label: 'Internship' },
    { value: 'Medical School', label: 'Medical School' },
  ];

  return (
    <Box sx={{ p: 0 }}>
      <SelectorLabel>Providers who completed:</SelectorLabel>
      <Box sx={{ display: 'flex', gap: '0.5rem' }}>
        <Select
          value={selectedOption}
          onChange={handleSelectionChange}
          sx={{ minWidth: '250px' }}
        >
          {menuItems.map((item) => (
            <Option key={item.value} value={item?.value}>
              {item?.label}
            </Option>
          ))}
        </Select>
        <SelectorDescription>
          within 4 years of {details?.name}
        </SelectorDescription>
      </Box>
    </Box>
  );
};

export default EducationToggle;
