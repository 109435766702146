import useSWR from 'swr';
import { QUERY_KEY } from './constants';
import { getProfileSummary } from './profileApi';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { useProspectSearch } from 'src/context';

interface UseGetSummaryProps {
  id: string;
}

const useGetSummary = ({ id }: UseGetSummaryProps) => {
  const { prospectSearch } = useProspectSearch();
  const queryParams = getSearchQueryParamsForAPI({
    saved_search: prospectSearch?.id,
    biographical: 1,
  });
  return useSWR(
    [QUERY_KEY.SUMMARY, id, queryParams],
    () => getProfileSummary(id, queryParams),
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false, //TODO:  we may want to remove this, but it was causing a lot of re-renders
    }
  );
};

export default useGetSummary;
