import {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
  Dispatch,
} from 'react';
import { useRouter } from 'next/router';
import { useProspectSearch } from 'src/context';
import { useGetProfileTabData } from 'src/api/profiles/profiles';

const defaultFilters = {
  years: {
    title: 'Years',
    filters: [],
    selectedDict: {},
  },
  categories: {
    title: 'Category',
    filters: [],
    selectedDict: {},
  },
  drug: {
    title: 'Drug',
    filters: [],
    selectedDict: {},
  },
  paymentCategories: {
    title: 'Payment Type',
    filters: [],
    selectedDict: {},
  },
  code: {
    title: 'Latest Related Search Codes',
    filters: [],
    selectedDict: {},
  },
  sources: {
    title: 'Payer',
    filters: [],
    selectedDict: {},
  },
  paymentSources: {
    title: 'Company',
    filters: [],
    selectedDict: {},
  },
  place_of_service: {
    title: 'Place of Service',
    filters: [],
    selectedDict: {},
  },
  // For payments view
  product_types: {
    title: 'Product Type',
    filters: [],
    selectedDict: {},
  },
  product_names: {
    title: 'Product Name',
    filters: [],
    selectedDict: {},
  },
};
export type FilterSet = typeof defaultFilters;
const FilterContext = createContext<[FilterSet, Dispatch<FilterSet>]>([
  defaultFilters,
  () => null,
]);
// TODO: Convert to useReducer
export const FilterProvider = (props) => {
  const filterState = useState(defaultFilters);
  const { data } = useGetProfileTabData({ filters: null });
  const router = useRouter();
  const { view, categories } = router.query;
  const { prospectSearch, prospectFilters, isCustom } = useProspectSearch();
  const [, setFilters] = filterState;

  const truncatedText = (text, length) => {
    return text.length > length ? text.substring(0, length) + '...' : text;
  };

  const searchName = useMemo(() => {
    return isCustom || !prospectSearch?.id
      ? truncatedText(
          prospectFilters
            .filter((f) => f.context !== 'spec')
            .map((filter) => filter.title)
            .join(', '),
          25
        )
      : prospectSearch?.search_name || prospectSearch?.name || 'Saved Search';
  }, [prospectSearch, prospectFilters, isCustom]);

  const parseAttributeFilters = useCallback(
    (allFilters) => {
      if (!allFilters) return;
      const searchFilters = prospectFilters.filter((f) => f.context !== 'spec');
      const attributeFilters = Object.keys(allFilters).reduce((accum, key) => {
        const filters = allFilters[key];

        // Payments category special case
        let conditionalKey = key;
        if (key === 'categories' && view === 'payments') {
          conditionalKey = 'paymentCategories';
        }
        if (key === 'sources' && view === 'payments') {
          conditionalKey = 'paymentSources';
        }

        accum[key] = {
          title: defaultFilters[conditionalKey].title,
          filters: filters.sort((a, b) => b.value - a.value),
          selectedDict: createSelectedDict(filters || [], searchFilters, view),
        };

        return accum;
      }, {} as FilterSet);

      return attributeFilters;
    },
    [prospectFilters, view]
  );

  const parseCodeFilters = useCallback(() => {
    if (view === 'payments' || Object.keys(prospectFilters).length === 0) {
      return;
    }

    // Apply all codes by default
    const appliedCodes = [
      ...prospectFilters.filter(
        (f) => f.context === view && f.context !== 'spec'
      ),
    ];

    return {
      code: {
        title: searchName,
        filters: appliedCodes,
        selectedDict: createSelectedDict(appliedCodes, prospectFilters, view),
      },
    };
  }, [view, searchName, prospectFilters]);

  const parsePrescriptionFilters = useCallback(() => {
    if (view !== 'prescriptions' || Object.keys(prospectFilters).length === 0) {
      return;
    }

    // Apply all prescriptions by default
    const appliedPrescriptions = [
      ...prospectFilters.filter((f) => f.context === 'drug'),
    ];

    return {
      drug: {
        title: 'Drug',
        filters: appliedPrescriptions,
        selectedDict: createSelectedDict(
          appliedPrescriptions,
          prospectFilters,
          view
        ),
      },
    };
  }, [view, prospectFilters]);

  const parseCategoryFilters = useCallback(
    (attributeFilters) => {
      if (!categories || !attributeFilters.categories) return;

      const appliedCategories = Array.isArray(categories)
        ? categories.reduce((accum, v) => {
            accum[v] = true;
            return accum;
          }, {})
        : { [categories]: true };
      const categoryFilters = {
        categories: {
          title: attributeFilters.categories.title,
          filters: attributeFilters.categories.filters,
          selectedDict: {
            ...attributeFilters.categories.selectedDict,
            ...appliedCategories,
          },
        },
      };

      return categoryFilters;
    },
    [categories]
  );

  useEffect(() => {
    if (!data?.filters) return;

    const attributeFilters = parseAttributeFilters(data.filters);
    const codeFilters = parseCodeFilters();
    const prescriptionFilters = parsePrescriptionFilters();
    const categoryFilters = parseCategoryFilters(attributeFilters);

    setFilters({
      ...codeFilters,
      ...prescriptionFilters,
      ...attributeFilters,
      ...categoryFilters,
    });

    return () => setFilters(defaultFilters);
  }, [
    data,
    setFilters,
    parseAttributeFilters,
    parseCodeFilters,
    parsePrescriptionFilters,
    parseCategoryFilters,
  ]);

  return <FilterContext.Provider value={filterState} {...props} />;
};

export const useFilters = () => {
  const context = useContext(FilterContext);
  const [filters, setFilters] = context;

  function toggleFilter(group, key) {
    const appliedFilters = { ...filters };
    const currentValue = filters[group].selectedDict[key];
    appliedFilters[group].selectedDict[key] = !currentValue;

    setFilters(appliedFilters);
  }

  function clearFilters(group) {
    const appliedFilters = { ...filters };

    appliedFilters[group].selectedDict = {};

    setFilters(appliedFilters);
  }

  if (!context) {
    throw new Error(
      'useFilters must be used inside a FilterProvider component'
    );
  }

  return { filters, setFilters, toggleFilter, clearFilters };
};

function createSelectedDict(allFilters, prospectFilters, view) {
  if (prospectFilters.length === 0) return {};
  // Match on ID
  const searchFilterValues = prospectFilters.map((filter) => filter.value);
  // Match on Title (payments)
  const searchFilterTitles = prospectFilters.map((filter) => filter.title);
  // Views in which we match on title
  const titleMatchViews = [
    'payments',
    // 'drug',
    'cpt',
    'icd',
    'icdp',
    'affiliations',
  ];

  return allFilters.reduce((result, { value, title }) => {
    result[value] = titleMatchViews.includes(view)
      ? searchFilterTitles.indexOf(title) >= 0
      : searchFilterValues.indexOf(value) >= 0;

    return result;
  }, {});
}
