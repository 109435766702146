import { Box, Typography } from '@mui/joy';
import { GridOverlay } from '@mui/x-data-grid-premium';

const NoVolumeOverlay = () => {
  return (
    <GridOverlay>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          padding: '0 1rem',
        }}
      >
        <Typography
          level="body-sm"
          color="neutral"
          sx={{ whiteSpace: 'break-spaces', textAlign: 'center' }}
        >
          No Saved Search filters applied.
        </Typography>
      </Box>
    </GridOverlay>
  );
};

export default NoVolumeOverlay;
