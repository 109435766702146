import { useMemo, useState } from 'react';
import {
  AddProviderDialog,
  AddToListButton,
  MedRMStatus,
  PushToCRMGroup,
} from 'src/components';
import ProspectListAndTagContent from './ProspectListAndTagContent';
import { useRouter } from 'next/router';
import { useGetMappings } from 'src/hooks';
import { Box, Container, Typography } from '@mui/joy';
import { useProfile } from 'src/context';

const ProfileHeader = ({ children, contentType }) => {
  const router = useRouter();
  const { type } = router.query as { type: string };
  const [newProviders, setNewProviders] = useState([]);
  const { details, refetch } = useProfile();
  const isHcpGroup = contentType === 'hcp-group';

  const { data: allMappings } = useGetMappings({
    sort: 'label',
    order: 'asc',
    page: 0,
    pageSize: 100,
  });

  const mappings = useMemo(() => {
    return allMappings?.results?.filter(
      ({ medscout_object_type, enabled }) =>
        medscout_object_type?.toLowerCase() === type && enabled
    );
  }, [allMappings?.results, type]);

  if (!details) return null;

  return (
    <Container maxWidth="xl">
      <Box sx={{ mt: '1rem', mb: '2rem' }}>
        <Typography level="h3">{children}</Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: mappings?.length > 0 ? 'space-between' : 'flex-end',
            marginTop: 1,
          }}
        >
          {mappings?.length > 0 && (
            <>
              <PushToCRMGroup provider={details} />
              <MedRMStatus provider={details} />
            </>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              flexGrow: 0,
            }}
          >
            {!isHcpGroup && (
              <AddToListButton
                onClick={() => {
                  setNewProviders([details]);
                }}
                variant="pill"
              />
            )}
            <ProspectListAndTagContent
              provider={details}
              hideProspectList={isHcpGroup}
            />
          </Box>
        </Box>
      </Box>

      {newProviders.length > 0 && (
        <AddProviderDialog
          onHide={() => {
            setNewProviders([]);
          }}
          newProviders={{
            providers: newProviders,
            ui_fromMap: false,
            onSuccess: () => {
              refetch();
            },
          }}
        />
      )}
    </Container>
  );
};

export default ProfileHeader;
