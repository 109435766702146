'use client';
import React from 'react';
import { Box, FormLabel, Input, styled } from '@mui/joy';
import { ManageSearchRounded } from '@mui/icons-material';
import { faBolt, faUserTie } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { useAuth } from 'src/context';
import { useCompanyUsers } from 'src/hooks';
import { activityData } from 'src/components/Activity/constants';
import RadioButtonSelect from './components/RadioButtonSelect';
import { MultiSelectMenu } from './components/MultiSelectMenu';

const FilterWrapper = styled(Box)({
  display: 'flex',
  gap: '0.5rem',
  width: '160px',
  minWidth: '120px',
  flexDirection: 'column',
  alignItems: 'flex-start',
});
interface ActivityTableToolbarProps {
  searchTerm: string;
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedReps: string[];
  setSelectedReps: any;
  selectedActivities: string[];
  setSelectedActivities: any;
  selectedTimeframe: string;
  setSelectedTimeframe: any;
}

const ActivityTableToolbar = ({
  searchTerm,
  onSearch,
  selectedReps,
  setSelectedReps,
  selectedActivities,
  setSelectedActivities,
  selectedTimeframe,
  setSelectedTimeframe,
}: ActivityTableToolbarProps) => {
  const { user } = useAuth();
  const companyId = user.company.id.toString();
  const isManager = user?.permissions?.is_manager || user.is_superuser;

  const { data: users } = useCompanyUsers(companyId);

  const representativeOptions = users?.results?.map((report) => {
    const fullName = `${report.first_name} ${report.last_name}`;
    return { value: report.id, label: fullName };
  });

  const handleTimeframeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e) return;
    const value = e.target.value;
    setSelectedTimeframe(value);
  };

  const setSelectedOptions = (optionType: string, options?: string[]) => {
    if (optionType === 'reps') {
      setSelectedReps(options);
    }
    if (optionType === 'activities') {
      setSelectedActivities(options);
    }
  };

  return (
    <Box sx={{ marginLeft: '1rem' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '0.5rem',
          padding: '1rem 0',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            width: '100%',
          }}
        >
          <Box
            sx={{
              flex: 0.2,
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
              minWidth: '17rem' /* 275px */,
            }}
          >
            <FormLabel htmlFor="search-input" sx={{ fontSize: '12px' }}>
              Search Targets
            </FormLabel>
            <Input
              id="search-input"
              type="search"
              variant="outlined"
              sx={{
                display: 'flex',
                borderRadius: '1rem',
                backgroundColor: '#EBECED',
                minHeight: '2rem',
                padding: '0.25rem .5rem',
                alignItems: 'center',
                alignSelf: 'stretch',
                fontSize: '0.875rem',
              }}
              size="sm"
              startDecorator={<ManageSearchRounded />}
              placeholder="Find a Target by name"
              value={searchTerm}
              onChange={onSearch}
            />
          </Box>
          {isManager ? (
            <FilterWrapper>
              <FormLabel sx={{ fontSize: '12px' }} htmlFor="rep-name-select">
                Rep. Name
              </FormLabel>
              <MultiSelectMenu
                header="Filter by Rep"
                optionType="reps"
                options={representativeOptions}
                enableSearch={true}
                selectedOptions={selectedReps}
                setSelectedOptions={setSelectedOptions}
                startDecorator={<Icon icon={faUserTie} />}
              />
            </FilterWrapper>
          ) : null}
          <FilterWrapper>
            <FormLabel sx={{ fontSize: '12px' }} htmlFor="activity-type-select">
              Type
            </FormLabel>
            <MultiSelectMenu
              header="Filter by Type"
              optionType="activities"
              options={activityData}
              isActivity={true}
              startDecorator={<Icon icon={faBolt} />}
              selectedOptions={selectedActivities}
              setSelectedOptions={setSelectedOptions}
            />
          </FilterWrapper>
          <FilterWrapper>
            <FormLabel htmlFor="timeframe-select" sx={{ fontSize: '12px' }}>
              Timeframe
            </FormLabel>
            <RadioButtonSelect
              header="Timeframe"
              selectOnChange={handleTimeframeChange}
              selectedOption={selectedTimeframe || 'current_week'}
            />
          </FilterWrapper>
        </Box>
      </Box>
    </Box>
  );
};

export default ActivityTableToolbar;
