import React from 'react';
import { Box } from '@mui/joy';

const GridColumn = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      {children}
    </Box>
  );
};

export default GridColumn;
