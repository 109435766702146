import { request } from 'src/api/request';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

export const getTerritory = async (id: string) => {
  if (!id) return;
  return await request({
    url: `v1/accounts/territories/${id}/`,
    data: {},
    method: 'GET',
  });
};

export const createTerritory = async (data: any) => {
  if (!data) return;

  const queryParams = getSearchQueryParamsForAPI({ preview: 0 });
  return await request({
    url: `v1/accounts/territories/${queryParams}`,
    data,
    method: 'POST',
  });
};

export const editTerritory = async (id: number, data: any) => {
  if (!data) return;

  return await request({
    url: `v1/accounts/territories/${id}/`,
    data,
    method: 'PATCH',
  });
};

export const deleteTerritory = async (id: number) => {
  if (!id) return;

  return await request({
    url: `v1/accounts/territories/${id}`,
    data: {},
    method: 'DELETE',
  });
};

export const convertStateData = async (
  data: any,
  bounding_box: number[],
  type: string
) => {
  if (!data) return;

  // convert state county or zip
  let queryParams = getSearchQueryParamsForAPI({
    bounding_box,
  });

  const searchIdParams = data
    .map(
      (id: string) =>
        `&${encodeURIComponent('within')}=${encodeURIComponent(id)}`
    )
    .join('');

  queryParams += searchIdParams;

  return await request({
    url: `v1/accounts/boundaries/${type}/?${queryParams}`,
    method: 'GET',
  });
};
