import { Box, Card, Typography } from '@mui/joy';
import { faHospital } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { hasContent } from 'src/utils';
import { useStore } from 'src/store/store';
import DetailsDisplay from './ProfileDetails/DetailsDisplay';
import InfoUnavailable from './InfoUnavailable';
import { headerStyle } from './styleConstants';
import { useProfile } from 'src/context';

const ProfileAffiliations = () => {
  const dataSourcesInfo = useStore((store: any) => store.dataSourcesInfo);
  const { details } = useProfile();

  const affiliations = details?.hcp?.affiliations || [];

  return (
    <>
      <Card sx={{ padding: 0 }}>
        <Box sx={headerStyle} aria-label="Hospital Affiliations">
          <Icon icon={faHospital} />
          <Typography sx={{ display: 'inline-block', marginLeft: '0.5rem' }}>
            Hospital Affiliations
          </Typography>
          <Typography level="body-xs" fontStyle={'italic'}>
            {dataSourcesInfo['hospitalAffiliations']?.subHeader}
          </Typography>
        </Box>
        <Box padding={'0 1rem 1rem 0'}>
          {hasContent(affiliations) ? (
            <Box sx={{ paddingLeft: '1rem' }}>
              <DetailsDisplay
                items={affiliations}
                options={{
                  basePath: '/profiles/center/',
                  displayKey: 'title',
                  idKey: 'value',
                }}
                isAffil={true}
              />
            </Box>
          ) : (
            <InfoUnavailable />
          )}
        </Box>
      </Card>
    </>
  );
};

export default ProfileAffiliations;
