import React from 'react';
import { styled } from '@mui/material';
import { GridColumnGroupHeaderParams } from '@mui/x-data-grid-premium';
import { Typography } from '@mui/joy';

interface GroupHeaderProps extends GridColumnGroupHeaderParams {
  icon?: React.ReactNode;
  isVolume?: boolean;
}

const StyledGroupHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isVolume',
})<{ isVolume: boolean }>(({ isVolume, theme }) => ({
  display: 'flex',
  height: '3.5rem',
  alignItems: 'center',
  alignSelf: 'stretch',
  border: 'none',
  width: '100vw !important',
  ...(isVolume && {
    margin: '0 0.3rem',
    borderBottom: `1px solid #005B38`,
  }),
}));

const GroupHeader = (props: GroupHeaderProps) => {
  const { icon, isVolume = false, ...params } = props;

  return (
    <StyledGroupHeader isVolume={isVolume}>
      <Typography
        level="title-sm"
        sx={{
          fontWeight: 600,
        }}
      >
        {params.headerName}
      </Typography>
    </StyledGroupHeader>
  );
};

export default GroupHeader;
