import { combineReducers } from '@reduxjs/toolkit';
import { siteReducer, initialSiteState } from './siteProviderReducer';
import {
  savedSearchReducer,
  initialSavedSearchState,
} from './savedSearchReducer';
import { profileReducer, initialProfileState } from './profileReducer';

export const initialState = {
  siteProvider: initialSiteState,
  savedSearch: initialSavedSearchState,
  profile: initialProfileState,
};

export const rootReducer = combineReducers({
  siteProvider: siteReducer,
  savedSearch: savedSearchReducer,
  profile: profileReducer,
});
