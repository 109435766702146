import { request } from 'src/api/request';

export const getNotes = async (providerId: string) => {
  if (!providerId) return;

  return await request({
    url: `v1/accounts/providers/${providerId}/notes/`,
    method: 'GET',
  });
};

export const getNote = async (providerId: number, noteId: number) => {
  if (!providerId || !noteId) return;

  return await request({
    url: `v1/accounts/providers/${providerId}/notes/${noteId}/`,
    method: 'GET',
  });
};

export const createNote = async (
  providerId: number,
  data: { note: string }
) => {
  if (!providerId || !data) return;

  return await request({
    url: `v1/accounts/providers/${providerId}/notes/`,
    method: 'POST',
    data,
  });
};

export const updateNote = async (
  providerId: number,
  noteId: number,
  data: { note: string }
) => {
  if (!providerId || !noteId || !data) return;

  return await request({
    url: `v1/accounts/providers/${providerId}/notes/${noteId}/`,
    method: 'PUT',
    data,
  });
};

export const deleteNote = async (providerId: number, noteId: number) => {
  if (!providerId || !noteId) return;

  return await request({
    url: `v1/accounts/providers/${providerId}/notes/${noteId}/`,
    method: 'DELETE',
  });
};
