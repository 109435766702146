import {
  createContext,
  useReducer,
  useContext,
  useCallback,
  Dispatch,
} from 'react';

const IntegrationLayoutActionTypes: { [key: string]: string } = {
  SET_SHOW_MAPPING_DIALOG: 'SET_SHOW_MAPPING_DIALOG',
  SET_SHOW_SETTINGS_DIALOG: 'SET_SHOW_SETTINGS_DIALOG',
  SET_SHOW_OPTIONS_DIALOG: 'SET_SHOW_OPTIONS_DIALOG',
};

type IntegrationLayoutControlState = {
  showMappingDialog: boolean;
  showSettingsDialog: boolean;
  showOptionsDialog: boolean;
};

type IntegrationLayoutActions = {
  type: keyof typeof IntegrationLayoutActionTypes;
  payload: any;
};

const initialState: IntegrationLayoutControlState = {
  showMappingDialog: false,
  showSettingsDialog: false,
  showOptionsDialog: false,
};

const IntegrationLayoutControlContext = createContext<{
  state: IntegrationLayoutControlState;
  dispatch: Dispatch<IntegrationLayoutActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const integrationLayoutReducer = (
  state: IntegrationLayoutControlState,
  action: IntegrationLayoutActions
) => {
  const newState = { ...state };

  switch (action.type) {
    case IntegrationLayoutActionTypes.SET_SHOW_MAPPING_DIALOG:
      newState.showMappingDialog = action.payload;
      break;
    case IntegrationLayoutActionTypes.SET_SHOW_SETTINGS_DIALOG:
      newState.showSettingsDialog = action.payload;
      break;
    case IntegrationLayoutActionTypes.SET_SHOW_OPTIONS_DIALOG:
      newState.showOptionsDialog = action.payload;
      break;
  }

  return newState;
};

export const IntegrationLayoutProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(integrationLayoutReducer, initialState);

  return (
    <IntegrationLayoutControlContext.Provider value={{ state, dispatch }}>
      {children}
    </IntegrationLayoutControlContext.Provider>
  );
};

export const useIntegrationLayout = () => {
  const { state, dispatch } = useContext(IntegrationLayoutControlContext);

  const setShowMappingDialog = useCallback(
    (show: boolean) => {
      dispatch({
        type: IntegrationLayoutActionTypes.SET_SHOW_MAPPING_DIALOG,
        payload: show,
      });
    },
    [dispatch]
  );

  const setShowSettingsDialog = useCallback(
    (show: boolean) => {
      dispatch({
        type: IntegrationLayoutActionTypes.SET_SHOW_SETTINGS_DIALOG,
        payload: show,
      });
    },
    [dispatch]
  );

  const setShowOptionsDialog = useCallback(
    (show: boolean) => {
      dispatch({
        type: IntegrationLayoutActionTypes.SET_SHOW_OPTIONS_DIALOG,
        payload: show,
      });
    },
    [dispatch]
  );

  return {
    ...state,
    setShowMappingDialog,
    setShowSettingsDialog,
    setShowOptionsDialog,
  };
};
