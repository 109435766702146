import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import {
  FormControl,
  FormLabel,
  Autocomplete,
  AutocompleteOption,
  CircularProgress,
  Typography,
} from '@mui/joy';
import { useSearchAddresses } from 'src/hooks';
import { AddressType } from './types';

interface DataIssueAddressSearch {
  setAddressData: (value: object) => void;
}

const DataIssueAddressSearch = ({ setAddressData }: DataIssueAddressSearch) => {
  const [open, setOpen] = useState(false);
  const [userInput, setUserInput] = useState('');
  const [debouncedUserInput] = useDebounce(userInput, 500);

  const { data = {}, isLoading } = useSearchAddresses({
    search: debouncedUserInput,
    enabled: debouncedUserInput.length > 2,
  });

  // get search results off of result payload
  // also provide default states for loading or typing
  let {
    results: searchResults = [
      {
        practice_name: isLoading
          ? 'Loading...'
          : 'Start typing (i.e. 12 Main St)',
        id: 0,
      },
    ],
  } = data;

  if (searchResults.length === 0) {
    searchResults = [{ practice_name: 'No results found', id: 0 }];
  }

  const handleOptionChange = (event, value) => {
    setAddressData(value);
  };

  return (
    <FormControl id="address-search">
      <FormLabel>Search for an existing address</FormLabel>
      <Autocomplete
        freeSolo
        placeholder="by Street Address"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        // handles selection change
        onChange={handleOptionChange}
        // handles search input change
        onInputChange={(event, value) => setUserInput(value)}
        // handles which item to render as 'selected'
        isOptionEqualToValue={(option: AddressType, value: AddressType) =>
          option.id === value.id
        }
        getOptionLabel={(option: AddressType) =>
          option.address_line_1 || 'Invalid'
        }
        getOptionKey={(option: AddressType) => option.id}
        renderOption={(props, option: AddressType) => (
          <AutocompleteOption {...props}>
            <div>
              <Typography level="title-sm">{option.practice_name}</Typography>
              <Typography level="body-md">{option.address_line_1}</Typography>
              {option.city && (
                <Typography level="body-xs">
                  {option.city}, {option.state} {option.zip_code}
                </Typography>
              )}
            </div>
          </AutocompleteOption>
        )}
        options={searchResults}
        loading={isLoading}
        endDecorator={
          isLoading ? (
            <CircularProgress
              size="sm"
              sx={{ bgcolor: 'background.surface' }}
            />
          ) : null
        }
      />
    </FormControl>
  );
};

export { DataIssueAddressSearch };
