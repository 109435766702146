import { Tags } from 'src/components';
import { titleCase } from 'src/utils';

const SmartListTypeCell = ({ value }) => {
  if (!value) return null;
  const fullText = titleCase(value);
  const tag = {
    id: 123,
    label: value,
    fullText: fullText,
    FormattedText: () => <span>{fullText}</span>,
    bgColor: '#F7E7A7',
  };

  return <Tags tags={[tag]} tagType="list" />;
};

export default SmartListTypeCell;
