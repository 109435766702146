import useSWR from 'swr';
import { QUERY_KEYS } from './constants';
import { getReferringOut } from 'src/api';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

const useGetReferringOut = ({
  providerId,
  page,
  pageSize,
  sort,
  order,
  sort_search,
  sort_value,
  q,
  volume_type,
  dateRange,
  enabledSearchIds = [],
}) => {
  let queryParams = getSearchQueryParamsForAPI({
    sort,
    order,
    sort_search,
    sort_value,
    page: page + 1,
    pageSize,
    q,
    volume_type,
    dateRange,
  });

  const searchIdParams = enabledSearchIds
    .map(
      (id) => `&${encodeURIComponent('saved_search')}=${encodeURIComponent(id)}`
    )
    .join('');

  queryParams += searchIdParams;

  return useSWR(
    [QUERY_KEYS.GET_REFERRAL, providerId, queryParams],
    () => getReferringOut({ provider_id: providerId, queryParams }),
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
};

export default useGetReferringOut;
