import React, { useCallback, useRef } from 'react';
import {
  AdvancedMarker,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';
import { Box, Dropdown, Menu, Tooltip, Typography } from '@mui/joy';
import { useOnClickOutside } from 'src/hooks';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons';
import { useProspectSearch } from 'src/context';
import { MarkerCircle } from '../MarkerCircle';
import { useFlags } from 'launchdarkly-react-client-sdk';

type TreeClusterMarkerProps = {
  clusterId: number;
  onMarkerClick?: (marker: any, clusterId: number) => void;
  position: any;
  size: number;
  sizeAsText: string;
  isHovered?: boolean;
  onMouseOver?: (clusterId: number) => void;
  onMouseOut?: () => void;
  clusterLeaves?: any[];
  addToList?: (details: any) => void;
};

const FeaturesClusterMarker = ({
  position,
  size,
  sizeAsText,
  isHovered = false,
  onMarkerClick,
  onMouseOver,
  onMouseOut,
  clusterId,
  clusterLeaves,
  addToList,
}: TreeClusterMarkerProps) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [markerRef, marker] = useAdvancedMarkerRef();
  const { prospectType } = useProspectSearch();
  const prospectTypeMap = {
    HCP: 'Providers',
    C: 'Sites',
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { sameTabRouting } = useFlags();

  const handleClick = useCallback(
    () => onMarkerClick && onMarkerClick(marker!, clusterId),
    [onMarkerClick, marker, clusterId]
  );

  const handleHoverMenu = (event: React.MouseEvent<HTMLElement>, clusterId) => {
    onMouseOver(clusterId);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onMouseOut();
  };

  useOnClickOutside(menuRef, anchorEl, handleClose);

  return (
    <AdvancedMarker
      ref={markerRef}
      position={position}
      zIndex={size}
      onClick={handleClick}
      style={{
        width: '35px',
        height: '35px',
        borderRadius: '50%',
        cursor: 'pointer',
      }}
    >
      <MarkerCircle
        featureId={clusterId}
        sizeAsText={sizeAsText}
        isHovered={isHovered}
        onMouseOut={onMouseOut}
        handleHoverMenu={handleHoverMenu}
      />
      <Dropdown>
        <Menu
          ref={menuRef}
          placement="top"
          anchorEl={anchorEl}
          open={Boolean(anchorEl) && isHovered && clusterLeaves?.length > 2}
          onMouseLeave={onMouseOut}
          sx={{
            boxShadow: 'sm',
            flexGrow: 0,
            maxHeight: 300,
            overflow: 'auto',
          }}
        >
          {clusterLeaves?.map((leaf, index) => {
            return (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '0.5rem',
                  borderBottom: '1px solid #dadde9',
                  '&:last-of-type': {
                    borderBottom: 'none',
                  },
                }}
              >
                <Box>
                  <a
                    href={`/profiles/${leaf?.details?.content_type}/${leaf?.details?.provider_id}`}
                    target={sameTabRouting ? '_self' : '_blank'}
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: 'none',
                      width: '100%',
                    }}
                  >
                    <Typography
                      level="title-sm"
                      color="primary"
                      sx={{
                        textDecoration: 'none',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.25rem',
                        '&:hover': {
                          color: 'primary.700',
                        },
                      }}
                    >
                      {index ? `${index + 1}) ` : `${1}) `}
                      {leaf?.details?.name}
                      <Icon
                        icon={faExternalLinkAlt}
                        size="sm"
                        color="inherit"
                      />
                    </Typography>
                  </a>
                  <Typography level="body-xs">
                    {leaf?.details?.specialty}
                  </Typography>
                  <Typography level="body-xs">
                    {leaf?.details?.address_line_1}
                  </Typography>
                  <Typography level="body-xs">
                    {leaf?.details?.address_line_2}
                  </Typography>
                  <Typography level="body-xs">
                    {leaf?.details?.city}, {leaf?.details?.state}
                  </Typography>
                  <Typography
                    level="title-sm"
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      addToList(leaf?.details);
                    }}
                    sx={{
                      textDecoration: 'none',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.25rem',
                      '&:hover': {
                        cursor: 'pointer',
                        color: 'primary.700',
                      },
                    }}
                  >
                    Add to List
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Menu>
      </Dropdown>
    </AdvancedMarker>
  );
};

export default FeaturesClusterMarker;
