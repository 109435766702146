import { request } from 'src/api/request';

export const getProfileSummary = async (id: string, params: any) => {
  if (!id || !params) throw new Error('No id or params provided');

  return await request({
    url: `v1/ai/generate-provider-summary/${id}/${params}`,
    method: 'GET',
  });
};

export const reportBadSummary = async (id: string) => {
  if (!id) throw new Error('No id provided');

  return await request({
    url: `v1/ai/provider-summaries/${id}/`,
    method: 'PATCH',
    data: {
      thumbs_down: true,
    },
  });
};
