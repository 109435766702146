import { useFilters } from '../../context';
import {
  styled,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Box,
  Button,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faBackspace } from '@fortawesome/pro-solid-svg-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';

const StyledLabel = styled(FormControlLabel)(() => ({
  '& .MuiFormControlLabel-label': {
    fontSize: '0.75rem',
    fontWeight: 400,
  },
}));

const FilterGroup = ({ group, name }) => {
  const { newProfileHeader } = useFlags();
  const { toggleFilter, clearFilters } = useFilters();

  if (!group.filters?.length) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: newProfileHeader ? '0 1rem 0 0' : '1rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
          pr: 1,
        }}
        data-testid="dataFilterTitle"
      >
        <span>{group.title}</span>
        <Button
          endIcon={<Icon icon={faBackspace} />}
          size="small"
          sx={{
            color: grey[600],
            textTransform: 'uppercase',
            fontSize: '0.65rem',
            '&.MuiButton-endIcon': {
              ml: 0,
            },
            justifyContent: 'flex-end',
          }}
          onClick={() => clearFilters(name)}
        >
          <Box sx={{ display: { xs: 'none', lg: 'inline' } }}>Clear</Box>
        </Button>
      </Box>
      <FormGroup>
        {group.filters
          ?.sort((a, b) => {
            return a.title < b.title ? -1 : 1;
          })
          .map(({ title, value: key, context }) => {
            const contextIdentifiersToInclude = {
              cpt: true,
              hcpcs: true,
              icd: true,
              drg: true,
              apc: true,
              icdp: true,
            };
            const includeContext = contextIdentifiersToInclude[context];
            const conditionalTrademark = context === 'cpt' ? '®' : '';
            const display = includeContext
              ? `${context.toUpperCase()}${conditionalTrademark}-${title}`
              : title;

            return (
              <StyledLabel
                key={`${name}-${title}-${key}`}
                control={
                  <Checkbox
                    checked={group.selectedDict[key] || false}
                    onChange={() => {
                      toggleFilter(name, key);
                    }}
                    id={`${name}-${title}-${key}`}
                  />
                }
                label={display}
              />
            );
          })}
      </FormGroup>
    </Box>
  );
};

export default FilterGroup;
