import useSWR from 'swr';
import { getHexUrl } from 'src/api';
import { QUERY_KEYS } from './constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

interface GetLeakageReportProps {
  providerId: string;
}

const useGetLeakageReport = ({ providerId }: GetLeakageReportProps) => {
  const queryParams = getSearchQueryParamsForAPI({
    provider_id: providerId,
  });

  const shouldFetch = providerId;
  return useSWR(
    shouldFetch ? [QUERY_KEYS.GET_LEAKAGE_REPORT, queryParams] : null,
    () =>
      getHexUrl({
        reportId: 'hospital_readmission_rates',
        queryParams,
      }),
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
};

export default useGetLeakageReport;
