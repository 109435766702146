import { styled, Box } from '@mui/material';

const Section = styled(Box)`
  background-color: white;
  border: 1px solid #e0e0e0;
  margin-bottom: 1rem;
  padding: 1rem 0;
  border-radius: 6px;
`;

export default Section;
