import React from 'react';
import { motion } from 'framer-motion';
import MedAISingleSparkle from './MedAISingleSparkle';
import { Box } from '@mui/joy';

const duration = 2; // duration in seconds
import { Variant } from 'framer-motion';

const scaleAnimation = {
  grow: { scale: 1.5 },
  shrink: { scale: 1 },
  transition: {
    duration: duration / 2,
    ease: 'easeInOut',
    repeat: Infinity,
    repeatType: 'mirror' as const,
  } as Variant,
};

const MedAILoading = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '130px',
        padding: '1.5rem 0',
      }}
    >
      {isLoading && (
        <Box
          sx={{
            position: 'relative',
            width: '100px',
          }}
        >
          <motion.div
            style={{ position: 'absolute', top: -35, left: 45 }}
            variants={scaleAnimation}
            initial="shrink"
            animate="grow"
            transition={scaleAnimation.transition}
          >
            <MedAISingleSparkle width={75} height={50} />
          </motion.div>
          <motion.div
            style={{ position: 'absolute', top: 0, left: 0 }}
            variants={scaleAnimation}
            initial="shrink"
            animate="grow"
            transition={{
              ...scaleAnimation.transition,
              delay: duration / 2, // Delay to sync with the other star
            }}
          >
            <MedAISingleSparkle width={75} height={50} />
          </motion.div>
        </Box>
      )}
    </Box>
  );
};

export default MedAILoading;
