import { useEffect } from 'react';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useMedScoutMap, useLogging } from 'src/context';
import { COLOR_MAP } from 'src/utils/constants/scss-variables.constants';

const DrawContent = () => {
  const log = useLogging();
  const map = useMap();
  const drawing = useMapsLibrary('drawing');

  const {
    currentCircleRef,
    currentOverlay,
    currentTerritory,
    drawingManagerRef,
    drawingMode,
    isEditing,
    setCurrentOverlay,
    setTerritoryPolygons,
  } = useMedScoutMap();

  const handleDrawnTerritory = () => {
    if (!drawingMode || drawingMode !== 'DRAW') return;
    if (currentCircleRef?.current) currentCircleRef.current.setMap(null);

    const maps = google?.maps;

    // Function to log updated coordinates
    const logUpdatedCoordinates = (polygon) => {
      const updatedCoords = [];
      polygon.getPaths().forEach((path) => {
        path.forEach((latLng) => {
          updatedCoords.push([latLng.lng(), latLng.lat()]);
        });
      });

      // Update the state with the updated polygon coordinates
      setTerritoryPolygons({
        [drawingMode]: updatedCoords,
      });
    };

    // Function to listen for changes in polygon paths
    const listenForPathChanges = (polygon) => {
      const paths = polygon.getPaths();

      paths.forEach((path, pathIndex) => {
        // Listen for modifications to the polygon paths
        path.addListener('set_at', () => logUpdatedCoordinates(polygon)); // When a vertex is moved
        path.addListener('insert_at', () => logUpdatedCoordinates(polygon)); // When a vertex is added
        path.addListener('remove_at', () => logUpdatedCoordinates(polygon)); // When a vertex is removed
      });
    };

    const isNew = !!currentTerritory?.polygons;
    const paths = isEditing
      ? isNew
        ? currentTerritory?.polygons
        : currentTerritory?.old_polygons
      : [];

    // Create or update the overlay for the current territory
    const overlay = new maps.Polygon({
      paths: paths,
      editable: true,
      draggable: true, // Optional: allows dragging the entire polygon
      strokeColor: COLOR_MAP['blue-dark'],
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: COLOR_MAP['blue-dark'],
      fillOpacity: 0.25,
    });

    // If editing, set the existing polygon on the map
    if (isEditing) {
      setCurrentOverlay(overlay);
      overlay.setMap(map);
      listenForPathChanges(overlay);
      logUpdatedCoordinates(overlay); // Log initial coordinates
      log.event('Edit Drawn Territory', { territory: currentTerritory });
    } else {
      // Initialize DrawingManager for creating a new polygon
      drawingManagerRef.current = new drawing.DrawingManager({
        drawingMode: maps.drawing.OverlayType.POLYGON,
        drawingControl: false,
        polygonOptions: {
          strokeColor: COLOR_MAP['blue-dark'],
          strokeOpacity: 0.8,
          strokeWeight: 3,
          fillColor: COLOR_MAP['blue-dark'],
          fillOpacity: 0.25,
          editable: true,
          clickable: true,
        },
      });

      // Set DrawingManager on the map
      drawingManagerRef?.current?.setMap(map);
      log.event('Create Drawn Territory', { territory: currentTerritory });

      // Listen for the overlaycomplete event to handle polygon creation
      maps.event.addListener(
        drawingManagerRef?.current,
        'overlaycomplete',
        (event) => {
          if (event.type === maps.drawing.OverlayType.POLYGON) {
            const newPoly = event.overlay;
            const tempCoords = [];

            // Capture the polygon's coordinates
            newPoly.getPaths().forEach((path) => {
              path.forEach((latLng) => {
                tempCoords.push([latLng.lng(), latLng.lat()]);
              });
            });

            if (tempCoords.length === 0) {
              newPoly.setMap(null); // Remove the incomplete polygon
              setTerritoryPolygons({ [drawingMode]: [] }); // Clear the polygon coordinates
              return;
            } else {
              // Add the new polygon coordinates to territoryPolygons['DRAW']
              setTerritoryPolygons({ [drawingMode]: tempCoords });

              // Keep the polygon on the map
              setCurrentOverlay(newPoly);
              newPoly.setMap(map);

              // Apply listeners to new polygon paths
              listenForPathChanges(newPoly);

              // Log coordinates after creation
              logUpdatedCoordinates(newPoly);

              // Allow the polygon to be edited after creation
              newPoly.setEditable(true);
              newPoly.setDraggable(true);

              // Cleanup DrawingManager after creating a polygon
              if (drawingManagerRef.current)
                drawingManagerRef?.current?.setMap(null);
            }
          }
          drawingManagerRef?.current?.setDrawingMode(null); // Disable drawing mode
        }
      );
    }
  };
  useEffect(() => {
    if (!map || !drawing) return;

    handleDrawnTerritory();

    return () => {
      const maps = google?.maps;
      // Clear the current overlay and draw
      maps.event?.clearListeners(map, 'overlaycomplete');
      currentOverlay?.setMap(null);
      drawingManagerRef?.current?.setMap(null);
    };
  }, [drawingMode, map, drawing]);

  return null;
};

export default DrawContent;
