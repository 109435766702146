import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  Modal,
  ModalDialog,
  Typography,
  Button,
  RadioGroup,
  Radio,
  Box,
  Sheet,
  DialogActions,
  CircularProgress,
  Input,
} from '@mui/joy';
import { createList, useGetProviderLists } from 'src/hooks';
import { AddRounded, CloseRounded } from '@mui/icons-material';
import { useLogging, useAuth } from 'src/context';
import { Lists } from './components';

interface AddToListDialogProps {
  open: boolean;
  onClose: () => void;
  onAddProvider: (listId: number) => void;
}

const AddToListDialog = ({
  open,
  onClose,
  onAddProvider,
}: AddToListDialogProps) => {
  const { user } = useAuth();
  const log = useLogging();

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm();

  const [isCreatingList, setIsCreatingList] = React.useState(false);
  const [selectedProviderListId, setSelectedProviderListId] =
    React.useState(null);

  const { data: providerLists, mutate, isLoading } = useGetProviderLists();

  const handleAddList = async () => {
    setIsCreatingList(true);
    setSelectedProviderListId('new-list-id');
  };

  const handleCancel = () => {
    reset();
    setIsCreatingList(false);
    setSelectedProviderListId(null);
    onClose();
  };

  const onSubmit = async (data) => {
    if (!data.listName) {
      setError('listName', {
        type: 'required',
        message: 'List name is required',
      });
      log.exception('Error creating new list: name not provided');
      return;
    }
    try {
      const response = await createList(data.listName);
      setIsCreatingList(false);
      await setSelectedProviderListId(response.id);
      log.event('List created', {
        name: data.listName,
      });
      handleAddProviderToList(response?.id);
    } catch (err) {
      log.exception(`Error creating new list: ${err}`);
    } finally {
      reset();
      mutate();
    }
  };

  const handleAddProviderToList = (listId: number) => {
    onAddProvider(listId);
    handleCancel();
  };

  const handleSelectList = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedProviderListId(e.target.value);
    setIsCreatingList(false);
  };

  const groupedLists = useMemo(() => {
    // group by owner, owner is lists and others are shared lists
    if (!providerLists?.results?.length) return [];
    return providerLists.results.reduce(
      (acc, list) => {
        if (list.owner.id === user.id) {
          acc.owned.push(list);
        } else {
          acc.shared.push(list);
        }
        return acc;
      },
      { owned: [], shared: [] }
    );
  }, [providerLists, user]) as { owned: any[]; shared: any[] };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography level="title-lg">Add to List</Typography>
          <CloseRounded fontSize="small" onClick={onClose} />
        </Box>
        <Sheet
          sx={{
            maxHeight: '25rem',
            width: 500,
            overflow: 'hidden',
            overflowY: 'auto',
          }}
        >
          <Box sx={{ padding: '0.5rem 0' }}>
            {isLoading && <CircularProgress color="neutral" />}
            <RadioGroup
              name="provider-lists"
              value={selectedProviderListId}
              onChange={handleSelectList}
            >
              <Lists title="Owned" lists={groupedLists.owned} />
              <Lists title="Shared" lists={groupedLists.shared} />
              {isCreatingList && (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '0.5rem',
                    padding: '0.25rem 0',
                    alignItems: 'center',
                  }}
                >
                  <Radio size="sm" value="new-list-id" />
                  <Input
                    variant="outlined"
                    size="sm"
                    placeholder="List Name"
                    {...register('listName', { required: true })}
                    error={!!errors.listName}
                    autoFocus
                  />
                </Box>
              )}
            </RadioGroup>
          </Box>
        </Sheet>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', gap: '0.5rem' }}>
            <Button variant="outlined" color="neutral" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="solid"
              disabled={
                isCreatingList ? !isValid || !isDirty : !selectedProviderListId
              }
              onClick={
                isCreatingList
                  ? handleSubmit(onSubmit)
                  : () => handleAddProviderToList(selectedProviderListId)
              }
            >
              {isCreatingList ? 'Create List and Add' : 'Add to List'}
            </Button>
          </Box>
          <Button
            startDecorator={<AddRounded />}
            variant="plain"
            color="primary"
            onClick={handleAddList}
          >
            New List
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default AddToListDialog;
