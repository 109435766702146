import {
  GridToolbarExportContainer,
  GridExcelExportMenuItem,
  GridPrintExportMenuItem,
  GridCsvExportMenuItem,
  gridFilteredSortedRowIdsSelector,
} from '@mui/x-data-grid-premium';
import { useLogging, useProfile } from 'src/context';
import { useRouter } from 'next/router';
import { format as dateFormat } from 'date-fns';
import { kebabCase } from 'src/utils';

const ExportCommands = () => {
  const log = useLogging();
  const { details } = useProfile();
  const router = useRouter();
  const { view, relationship } = router.query;

  const fileName = `${kebabCase(details?.name)}_${relationship || 'inbound'}_${
    view === 'patient_patterns' ? 'referrals' : 'affiliations'
  }_MedScout_${dateFormat(Date.now(), 'M_d_Y')}`;

  return (
    <GridToolbarExportContainer>
      <GridCsvExportMenuItem
        options={{
          fileName,
          getRowsToExport: ({ apiRef }) => {
            const rows = gridFilteredSortedRowIdsSelector(apiRef);
            log.event('Export', {
              type: 'prospects',
              source: 'referral',
              format: 'CSV',
              count: rows.length,
              context: details?.provider_id,
            });

            return rows;
          },
        }}
      />
      <GridExcelExportMenuItem
        options={{
          fileName,
          getRowsToExport: ({ apiRef }) => {
            const rows = gridFilteredSortedRowIdsSelector(apiRef);
            log.event('Export', {
              type: 'prospects',
              source: 'referral',
              format: 'XSLX',
              count: rows.length,
              context: details?.provider_id,
            });

            return rows;
          },
        }}
      />
      <GridPrintExportMenuItem />
    </GridToolbarExportContainer>
  );
};

export default ExportCommands;
