import { useQuery, UseQueryResult } from 'react-query';
import { request } from 'src/api/request';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { QUERY_KEYS } from './constants';
import { MapService } from 'src/services';
import { useAuth } from 'src/context';

/**
 * Custom hook for fetching search results.
 *
 * @param {Object} options - The options for fetching search results.
 * @param {Array<number>} options.selectedTerritoryIds - The selected territory IDs.
 * @param {string} options.filterQueryParamString - The filter query parameter string.
 * @param {Array<any>} options.prospectLists - The prospect lists.
 * @param {number} options.page - The page number.
 * @param {number} options.perPage - The number of results per page.
 * @param {string} options.sortBy - The sorting criteria.
 * @param {boolean} options.enabled - Indicates whether the hook is enabled.
 * @param {boolean} options.updateOnMapMove - Indicates whether to update on map move.
 * @param {Object} options.mapState - The map state.
 * @param {string} options.company_id - The company ID.
 * @returns {UseQueryResult<MedScout.SearchResult, { message: string } | null>} The query result.
 */
export default function useGetSearchResults({
  selectedTerritoryIds,
  filterQueryParamString,
  prospectLists,
  page = 1,
  perPage = 50,
  sortBy,
  sort,
  order,
  sort_search,
  sort_value,
  enabled,
  updateOnMapMove = true,
  mapState,
  company_id,
  dateRange,
  shouldRefetch,
}: {
  selectedTerritoryIds?;
  filterQueryParamString: string;
  prospectLists?;
  page?: number;
  perPage: number;
  sortBy?;
  sort?;
  order?;
  sort_search?;
  sort_value?;
  enabled: boolean;
  updateOnMapMove?: boolean;
  mapState?;
  company_id?: string;
  dateRange?: {
    lte: string;
    gte: string;
  };
  shouldRefetch?: boolean;
}): UseQueryResult<MedScout.SearchResult, { message: string } | null> {
  const bounds = MapService.getBoundsFromMapState(mapState);
  const { refreshLocalUser } = useAuth();
  const isProspectList = prospectLists?.length > 0;
  const params = getSearchQueryParamsForAPI(
    {
      p: page,
      per_page: perPage,
      sort_by: sortBy,
      sort,
      order,
      sort_search,
      sort_value,
      map: selectedTerritoryIds,
      bounds:
        selectedTerritoryIds?.length === 0 || updateOnMapMove ? bounds : null,
      company_id,
      prospect_lists: isProspectList ? prospectLists : null,
      dateRange,
    },
    filterQueryParamString
  );

  return useQuery(
    [QUERY_KEYS.search, params],
    async () => {
      return request({
        url: `/v1/query${params}`,
        data: {},
        method: 'GET',
      });
    },
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      retry: false,
      staleTime: 60_000,
      refetchInterval: shouldRefetch ? 60000 : false, // 60000 ms = 1 minute
      onSuccess: async (response: any) => {
        if (response?.status === 205) {
          await refreshLocalUser();
        }

        return response;
      },
    }
  );
}
