import React from 'react';
import { useRouter } from 'next/router';
import {
  Box,
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from '@mui/joy';
import { deleteNote, useGetNotes } from 'src/hooks';
import { useLogging, useNotification } from 'src/context';

const DeleteNoteModal = ({ open, onClose, note }) => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const router = useRouter();
  const { id } = router.query as { id: string };
  const { mutate } = useGetNotes(id);

  const [isLoading, setIsLoading] = React.useState(false);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await deleteNote(Number(id), note.id);
      mutate();
      log.event('profileDeleteNoteSubmitted', {
        tags: {
          source: 'profile',
          providerId: id,
          noteId: note.id,
        },
      });
      setNotification({
        title: 'Success',
        message: 'Note deleted successfully',
        type: 'success',
      });
    } catch (err) {
      log.exception('Error deleting note', {
        tags: {
          source: 'profile',
          error: err,
        },
      });
      setNotification({
        title: 'Error',
        message: 'Error deleting note',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
      mutate();
      onClose();
    }
  };
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog>
        Delete Note
        <ModalClose onClick={onClose} />
        <Typography level="title-md">
          Are you sure you want to delete this note?
        </Typography>
        <Box
          sx={{
            border: '1px solid',
            borderColor: 'neutral.300',
            p: 2,
            borderRadius: 'sm',
          }}
        >
          <Typography level="body-md">{note.note}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 1,
          }}
        >
          <Button variant="outlined" color="neutral" onClick={onClose}>
            Cancel
          </Button>
          <Button
            loading={isLoading}
            variant="solid"
            color="danger"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  );
};

export default DeleteNoteModal;
