import React from 'react';
import { Box, Typography } from '@mui/joy';

const BetaFlag = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#97E8DB99',
        padding: '0 0.25rem',
        borderRadius: 'xs',
        gap: '0.5rem',
      }}
    >
      <Typography
        sx={{
          color: '#00A783',
          fontSize: '0.625rem',
          fontWeight: 700,
          lineHeight: '0.888rem',
          loadingBrim: 'both',
          textEdge: 'cap',
        }}
      >
        Beta
      </Typography>
    </Box>
  );
};

export default BetaFlag;
