import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

interface UseSearchAddresses {
  search: string;
  enabled: boolean;
}

const useSearchAddresses = ({ search = '', enabled }: UseSearchAddresses) => {
  const queryParams = getSearchQueryParamsForAPI({
    search,
  });
  return useQuery(
    [QUERY_KEYS.searchAddresses, search],
    async () => {
      return await request({
        url: `/v1/hcp/address/${queryParams}`,
        method: 'GET',
      });
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 20_000,
      enabled,
    }
  );
};

export default useSearchAddresses;
