import { createContext, useContext, useMemo, useCallback } from 'react';
import * as Sentry from '@sentry/nextjs';
import { env } from 'src/constants';
import { AnalyticsBrowser } from '@segment/analytics-next';
import context from 'react-bootstrap/esm/AccordionContext';

const AnalyticsContext = createContext(undefined);

export const AnalyticsProvider = (props) => {
  const analytics = useMemo(
    () => AnalyticsBrowser.load({ writeKey: env.ANALYTICS_ID }),
    []
  );

  return <AnalyticsContext.Provider value={analytics} {...props} />;
};

export const useLogging = () => {
  const analytics = useContext(AnalyticsContext);

  if (!analytics)
    throw new Error('useLogging must be inside an AnalyticsProvider');

  const identify = useCallback(
    (user: MedScout.User) => {
      // Initialize user in Sentry
      Sentry.setUser({
        id: `${user.id}`,
        email: user.email,
        username: user.username,
      });

      // Track user in Segment
      analytics.identify(`${user.id}`, {
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        username: user.username,
        company: user.company.name,
        department: user.company.department,
        isManager: user.company.manager,
        isStaff: user.is_staff,
        isActive: user.is_active,
        isLIREligible: user.is_lir_eligible,
      });

      // Associate user with company in Segment
      analytics.group(`${user.company.id}`, {
        name: user.company.name,
      });
    },
    [analytics]
  );

  return {
    event: analytics.track,
    page: analytics.page,
    identify,
    alert: Sentry.captureMessage,
    exception: Sentry.captureException,
    context: Sentry.setContext,
  };
};
