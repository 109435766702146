import { createContext, ReactNode, useContext, useReducer } from 'react';

const ProfileActionTypes: { [key: string]: string } = {
  SET_VOLUME_BY_LOCATION_QUERY_PARAMS: 'SET_VOLUME_BY_LOCATION_QUERY_PARAMS',
  SET_SITE_PROVIDER_QUERY_PARAMS: 'SET_SITE_PROVIDER_QUERY_PARAMS',
};

type QueryParams = {
  cpt: { title: string; value: string }[];
  hcpcs: { title: string; value: string }[];
  icd: { title: string; value: string }[];
  drg: { title: string; value: string }[];
  icdp: { title: string; value: string }[];
};

type ProfileState = {
  volumeByLocationQueryParams: QueryParams;
  siteProviderQueryParams: QueryParams;
};

const volumeByLocationState = {
  cpt: [],
  hcpcs: [],
  icd: [],
  drg: [],
  icdp: [],
};

const siteProviderState = {
  cpt: [],
  hcpcs: [],
  icd: [],
  drg: [],
  icdp: [],
};

const initialState: ProfileState = {
  volumeByLocationQueryParams: volumeByLocationState,
  siteProviderQueryParams: siteProviderState,
};

const ProfileContext = createContext<{
  state: ProfileState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

const profileReducer = (state: ProfileState, action: any) => {
  switch (action.type) {
    case ProfileActionTypes.SET_VOLUME_BY_LOCATION_QUERY_PARAMS: {
      return {
        ...state,
        volumeByLocationQueryParams: action.payload,
      };
    }
    case ProfileActionTypes.SET_SITE_PROVIDER_QUERY_PARAMS: {
      return {
        ...state,
        siteProviderQueryParams: action.payload,
      };
    }
    default:
      return state;
  }
};

export const NewProfileProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(profileReducer, initialState);

  return (
    <ProfileContext.Provider value={{ state, dispatch }}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useNewProfile = () => {
  const context = useContext(ProfileContext);

  if (!context)
    throw new Error(
      'useProfile must be used within a NewProfileProvider component'
    );

  const {
    state: { volumeByLocationQueryParams, siteProviderQueryParams },
    dispatch,
  } = context;

  const setVolumeByLocationQueryParams = (payload: QueryParams) => {
    dispatch({
      type: ProfileActionTypes.SET_VOLUME_BY_LOCATION_QUERY_PARAMS,
      payload,
    });
  };

  const setSiteProviderQueryParams = (payload: QueryParams) => {
    dispatch({
      type: ProfileActionTypes.SET_SITE_PROVIDER_QUERY_PARAMS,
      payload,
    });
  };

  return {
    volumeByLocationQueryParams,
    setVolumeByLocationQueryParams,
    siteProviderQueryParams,
    setSiteProviderQueryParams,
  };
};

const OldProfileContext = createContext(null);

export const OldProfileProvider = (props) => {
  return <OldProfileContext.Provider {...props} />;
};

export const useProfile = () => {
  const context = useContext(OldProfileContext);

  if (!context)
    throw new Error(
      'useProfile must be used within OldProfileProvider component'
    );

  return context;
};
