import { Box, Typography } from '@mui/joy';
import React from 'react';
import { getMapUrl } from './utils';
import { getTerritory } from 'src/hooks';
import { useMedScoutMap, useAuth, useLogging } from 'src/context';
import { useRouter } from 'next/router';
interface GoogleStaticMapProps {
  polygons: any[];
  id: string;
  size: string;
}

const GoogleStaticMap = ({ polygons, id, size }: GoogleStaticMapProps) => {
  const log = useLogging();
  const router = useRouter();
  const { user, setUser } = useAuth();
  const mapUrl = getMapUrl({ polygons, size });

  const { setCurrentTerritory } = useMedScoutMap();

  if (!mapUrl) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0.5rem',
        }}
      >
        <Typography level="body-md" sx={{ color: 'white' }}>
          No map data
        </Typography>
      </Box>
    );
  }

  const onClick = async () => {
    // use context to set current territory, what!?!?!
    const territoryData = await getTerritory(id);
    if (territoryData) {
      setCurrentTerritory(territoryData);
      setUser((oldUser) => ({
        ...oldUser,
        last_territory: territoryData,
      }));

      log.event('Static Map Clicked', {
        source: 'Google Static Map',
        territory: territoryData,
        user,
      });

      router.push(`/search/`);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      aria-label="View On Map"
      onClick={onClick}
    >
      <img
        src={mapUrl}
        alt="Map"
        width="100%"
        height="100%"
        aria-label="Map Image"
      />
    </Box>
  );
};

export default GoogleStaticMap;
