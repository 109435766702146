import { Box } from '@mui/material';
import { Tags, NewProviderListTags } from 'src/components';

const ProspectListAndTagContent = ({ provider, hideProspectList }) => {
  return (
    <Box>
      <Tags tags={provider.tags} tagType="tag" />
      {!hideProspectList && (
        <NewProviderListTags lists={provider.provider_lists} />
      )}
    </Box>
  );
};

export default ProspectListAndTagContent;
