import React from 'react';
import { styled, Box, CircularProgress } from '@mui/joy';

/**
 * TableLoadingIndicator - renders a loading indicator in the center of the table
 * @param {string} height - height of the table
 * @returns {JSX.Element}
 */

const TableLoadingIndicator = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        background: 'rgba(97, 97, 97, 0.1)',
      }}
    >
      <CircularProgress variant="soft" color="neutral" size="sm" />
    </Box>
  );
};

export default TableLoadingIndicator;
