import React from 'react';
import { Box } from '@mui/joy';
const InnerContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {children}
    </Box>
  );
};

export default InnerContainer;
