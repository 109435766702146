import { useMemo, useState } from 'react';
import {
  Link,
  Box,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import {
  faExternalLinkAlt,
  faNoteSticky,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { useLogging } from 'src/context';
import { format as formatDate } from 'date-fns';
import { COLOR_MAP } from 'src/utils/constants/scss-variables.constants';
import { IconButton, TypeIcon } from 'src/components';
import { amber } from '@mui/material/colors';
import { exists, hasContent } from 'src/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';

const CellContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  gap: '0.5rem',
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  fontSize: '0.9rem',
  fontWeight: 500,
  '& > p': {
    fontSize: '0.7rem',
    color: COLOR_MAP['gray-darker'],
  },
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '1.1rem',
  height: '100%',
}));

const CustomContent = ({ notes }) => {
  return (
    <>
      <Box
        sx={{
          overflowY: 'auto',
          maxHeight: 250,
          maxWidth: 350,
          p: '1rem',
        }}
      >
        {notes.map((note, i) => {
          const isFirst = i === 0;

          return (
            <div
              key={`note_${note.id}`}
              style={{
                borderTop: isFirst ? 'none' : '1px solid',
                borderColor: COLOR_MAP['gray-lighter'],
                padding: '10px 10px 5px',
                paddingTop: isFirst ? 0 : '10px',
              }}
            >
              <Typography
                component={'time'}
                sx={{
                  flexShrink: 0,
                  fontSize: 10,
                  fontWeight: 500,
                }}
                dateTime={note.created_at}
              >
                {formatDate(new Date(note.created_at), 'PPp')}
              </Typography>
              <Typography sx={{ whiteSpace: 'pre-wrap', fontSize: '1rem' }}>
                &ldquo;{note.note}&rdquo;
              </Typography>
            </div>
          );
        })}
      </Box>
    </>
  );
};

const NoteTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    borderLeft: `2px solid ${theme.palette.highlight.main}`,
    boxShadow: theme.shadows[6],
  },
  [` .${tooltipClasses.arrow}:before`]: {
    backgroundColor: theme.palette.highlight.main,
  },
}));

const ProviderNameCell = ({ row }) => {
  const { sameTabRouting } = useFlags();

  const theme = useTheme();
  const log = useLogging();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const hasNotes = exists(row.notes) && hasContent(row.notes);
  const provider = useMemo(() => {
    return row.provider ? row.provider : row;
  }, [row]);

  const type = provider?.object_type?.toLowerCase() || provider.content_type;

  return (
    <CellContainer onClick={(e) => e.stopPropagation()}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          gap: '0.5rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '0.5rem',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <IconContainer>
            <TypeIcon
              type={type}
              style={{
                color: theme.palette.primary.main,
              }}
            />
          </IconContainer>
          <ContentContainer>
            <Link
              href={`/profiles/${type || 'hcp'}/${provider.provider_id}`}
              target={sameTabRouting ? '_self' : '_blank'}
              rel="noopener noreferrer"
            >
              <span>{provider.name || 'n/a'}</span>
              <Icon
                icon={faExternalLinkAlt}
                size="sm"
                style={{ marginLeft: '0.25rem' }}
              />
            </Link>

            <p>
              {provider.city}, {provider.state}
            </p>
            <p>{provider?.specialty}</p>
          </ContentContainer>
          {hasNotes && (
            <NoteTooltip
              title={<CustomContent notes={provider.notes} />}
              placement="right"
              disableFocusListener
              onClose={() => setTooltipOpen(false)}
              open={tooltipOpen}
              arrow
            >
              <IconButton
                icon={faNoteSticky}
                sx={{ color: amber[300] }}
                onClick={() => {
                  log.event('ListNotesClickedOpen');
                  setTooltipOpen((state) => !state);
                }}
                size="small"
              />
            </NoteTooltip>
          )}
        </Box>
      </Box>
    </CellContainer>
  );
};

export default ProviderNameCell;
