import React, { useEffect, useState } from 'react';
import { useGetDataIssueSuggestions } from 'src/hooks';
import {
  Radio,
  RadioGroup,
  List,
  ListItem,
  FormLabel,
  FormHelperText,
  Chip,
  Grid,
} from '@mui/joy';
import { DataIssueEmail } from './DataIssueEmail';
import { SelectorLabel } from './DataIssueStyles';
import { DataIssueTypes } from './types';

const SuggestionItem = ({ item }) => {
  return (
    <ListItem variant="outlined" key={item} sx={{ boxShadow: 'xs' }}>
      <Radio
        overlay
        value={item.email}
        slotProps={{
          action: ({ checked }) => ({
            sx: (theme) => ({
              ...(checked && {
                inset: -1,
                border: '2px solid',
                borderColor: theme.vars.palette.primary[500],
              }),
            }),
          }),
        }}
      />
      {
        {
          email: <EmailSuggestion emailObject={item} />,
          other: <OtherSuggestion />,
        }[item.suggestionType]
      }
    </ListItem>
  );
};

const EmailSuggestion = ({ emailObject }) => {
  return (
    <Grid container sx={{ flexGrow: 1 }}>
      <Grid xs={6} md={8}>
        <FormLabel>{emailObject?.email}</FormLabel>
        <FormHelperText>
          {emailObject?.confidence && (
            <span>Confidence: {emailObject?.confidence}%</span>
          )}
        </FormHelperText>
      </Grid>
      <Grid xs={6} md={4}>
        <Grid container justifyContent="end">
          {emailObject?.is_primary && (
            <Chip variant="soft" color="primary" size="sm">
              Current Email
            </Chip>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const OtherSuggestion = () => {
  return (
    <div>
      <FormLabel>Other</FormLabel>
      <FormHelperText>Please specify below</FormHelperText>
    </div>
  );
};

interface DataIssueEmailSuggestionsProps {
  email: string;
  setEmail: (value: string) => void;
  emailId: string;
  setEmailId: (value: string) => void;
  issueType: DataIssueTypes;
  providerId: string;
  providerType: string;
}

const DataIssueEmailSuggestions = ({
  email,
  setEmail,
  emailId,
  setEmailId,
  issueType,
  providerId,
  providerType,
}: DataIssueEmailSuggestionsProps) => {
  const [userSuppliedEmail, setUserSuppliedEmail] = useState(email);
  const { data: suggestedEmails = [], isLoading } = useGetDataIssueSuggestions({
    issueType,
    providerId,
    providerType,
  });

  useEffect(() => {
    if (emailId) {
      setEmail(emailId);
      setEmailId(emailId);
    } else if (suggestedEmails.length > 0) {
      setEmail(suggestedEmails[0].email);
      setEmailId(suggestedEmails[0].email);
    } else {
      setEmail('');
      setEmailId('other');
    }
  }, [suggestedEmails, emailId, setEmailId, setEmail]);

  useEffect(() => {
    if (userSuppliedEmail) {
      setEmail(userSuppliedEmail);
    }
  }, [userSuppliedEmail, setEmail]);

  const handleChange = (selectedEmail) => {
    setEmail(selectedEmail);
    setEmailId(selectedEmail);
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <SelectorLabel>Select or enter a correction:</SelectorLabel>
      <RadioGroup
        aria-label="Data Issue Suggestion Selector"
        name="suggestion"
        onChange={(e) => handleChange(e.target.value)}
        value={emailId}
      >
        <List
          sx={{
            minWidth: 240,
            '--List-gap': '0.5rem',
            '--ListItem-paddingY': '1rem',
            '--ListItem-radius': '8px',
            '--ListItemDecorator-size': '32px',
          }}
        >
          {suggestedEmails.map((item, index) => (
            <SuggestionItem
              key={index}
              item={{ ...item, suggestionType: issueType }}
            />
          ))}
          <SuggestionItem item={{ email: 'other', suggestionType: 'other' }} />
        </List>
      </RadioGroup>
      {emailId === 'other' && (
        <DataIssueEmail
          email={userSuppliedEmail}
          setEmail={setUserSuppliedEmail}
        />
      )}
    </>
  );
};

export { DataIssueEmailSuggestions };
