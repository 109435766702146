import { Box } from '@mui/joy';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Map } from 'src/components';
import { useProfile } from 'src/context';

const ProfileMap = () => {
  const { details } = useProfile();
  const { newProfileHeader } = useFlags();
  const isHCP = details?.hcp;
  // center profiles have lat and lng reversed
  const lat = parseFloat(isHCP ? details?.lat : details?.lng);
  const lng = parseFloat(isHCP ? details?.lng : details?.lat);
  const marker = lat && lng ? { ...details, lat, lng } : null;

  return (
    <Box
      sx={{
        marginBottom: '1.5rem',
        overflow: 'hidden',
        borderRadius: '6px',
        border: '1px solid #E0E0E0',
        height: newProfileHeader ? '8rem' : '15.5rem',
        width: newProfileHeader ? '8rem' : 'auto',
      }}
    >
      <Map
        mapZoomCallback={null}
        points={[marker]}
        center={[{ lat, lng }]}
        disableClusters
        isProfileMap
      />
    </Box>
  );
};

export default ProfileMap;
