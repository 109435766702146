import { useState, useCallback, useLayoutEffect } from 'react';

function useSyncedRowHover(apiRef1, apiRef2) {
  const [hoveredRowId, setHoveredRowId] = useState(null);

  const handleRowHover = useCallback((params) => {
    setHoveredRowId(params.id);
  }, []);

  const handleRowHoverEnd = useCallback(() => {
    setHoveredRowId(null);
  }, []);

  useLayoutEffect(() => {
    const unsubscribes = [];

    if (apiRef1.current) {
      unsubscribes.push(
        apiRef1.current.subscribeEvent('rowMouseEnter', handleRowHover),
        apiRef1.current.subscribeEvent('rowMouseLeave', handleRowHoverEnd)
      );
    }

    if (apiRef2.current) {
      unsubscribes.push(
        apiRef2.current.subscribeEvent('rowMouseEnter', handleRowHover),
        apiRef2.current.subscribeEvent('rowMouseLeave', handleRowHoverEnd)
      );
    }

    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe && unsubscribe());
    };
  }, [apiRef1, apiRef2, handleRowHover, handleRowHoverEnd]);

  const isRowHovered = useCallback(
    (params) => (params.id === hoveredRowId ? 'Mui-hovered' : ''),
    [hoveredRowId]
  );

  return { isRowHovered };
}

export default useSyncedRowHover;
