import React, { useState, ReactElement, useRef, useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Input,
  Checkbox,
  Dropdown,
  Button,
} from '@mui/joy';
import { styled } from '@mui/material';
import { UnfoldMore } from '@mui/icons-material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faSearch, faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import { useOnClickOutside } from 'src/hooks';
import FilterPill from '../FilterPill';
import { displayActivityType } from 'src/components/Activity/Activity';
import { useDashboard } from 'src/context';

const DropdownButton = styled(Button)({
  borderRadius: '1rem',
  display: 'flex',
  alignItems: 'center',
  padding: '0.375rem 0.5rem 0.375rem 0.75rem',
  width: '160px',
});

const MenuContainer = styled(Menu)({
  display: 'flex',
  width: '240px',
  padding: 'var(--1, 0.5rem)',
  flexDirection: 'column',
  alignItems: 'flex-start',
  borderRadius: '0.5rem',
  border: '1px solid var(--neutral-outlined-Border, #CDD7E1)',
  background: 'var(--background-surface, #FBFCFE)',

  /* shadow-md */
  boxShadow:
    '0px 2px 8px -2px rgba(21, 21, 21, 0.08), 0px 6px 12px -2px rgba(21, 21, 21, 0.08)',
});

interface MultiSelectMenuProps {
  header: string;
  optionType: string;
  enableSearch?: boolean;
  options: { label: string; value: string }[];
  startDecorator: ReactElement;
  isActivity?: boolean;
  selectedOptions: string[];
  setSelectedOptions: any;
}

const MultiSelectMenu = ({
  header,
  optionType,
  enableSearch,
  options,
  startDecorator,
  isActivity = false,
  selectedOptions,
  setSelectedOptions,
}: MultiSelectMenuProps) => {
  const menuRef = useRef(null);
  const menuRef2 = useRef(null);
  const { userQueryParams, setUserQueryParams } = useDashboard();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [allSelected, setAllSelected] = useState(false);

  const [search, setSearch] = useState('');

  const [debouncedSearch] = useDebounce(search, 500);

  useEffect(() => {
    if (!enableSearch) return;
    setUserQueryParams({ ...userQueryParams, q: debouncedSearch });

    return () => {
      setUserQueryParams({ ...userQueryParams, q: '' });
    };
  }, [debouncedSearch]);

  useEffect(() => {
    if (selectedOptions?.length === options?.length) {
      //if all options are selected, change dropdown placeholder to All instead of filterpill
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [options?.length, selectedOptions?.length]);

  const handleOpenMenu = (e) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleMoreOptionsClick = (e) => {
    setAnchorEl2(anchorEl2 ? null : e.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
    setAnchorEl2(null); //if main menu closes ensure more option menu closes as well
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleSearch = (e) => {
    e.stopPropagation();
    setSearch(e.target.value);
  };

  const selectAll = (e) => {
    e.stopPropagation();
    setAllSelected(true);
    setSelectedOptions(
      optionType,
      options.map((opt) => opt.value)
    );
    setAnchorEl2(null);
  };

  const deselectAll = (e) => {
    e.stopPropagation();
    setAllSelected(false);
    setSelectedOptions(optionType, []);
    setAnchorEl2(null);
  };

  const handleCheckboxChange = (e) => {
    e.stopPropagation();
    const value = e.target?.value;

    // If the value is already in the selected options, remove it
    if (selectedOptions?.includes(value)) {
      setSelectedOptions(
        optionType,
        selectedOptions.filter((opt) => opt !== value)
      );
    } else {
      setSelectedOptions(optionType, [...selectedOptions, value]);
    }
  };

  const menuRefs = menuRef2 ? [menuRef, menuRef2] : menuRef;
  useOnClickOutside(menuRefs, anchorEl, handleClose1);
  useOnClickOutside(menuRef2, anchorEl2, handleClose2);

  return (
    <Dropdown>
      <DropdownButton
        onClick={handleOpenMenu}
        variant="outlined"
        color="neutral"
        startDecorator={startDecorator}
        endDecorator={
          <UnfoldMore
            fontSize="small"
            sx={{
              fontWeight: 200,
              color: 'var(--neutral-600, #6B7280)',
            }}
          />
        } // Font awesome does not have a similar icon
      >
        <Box sx={{ width: '100%' }}>
          {/* To ensure that contents take up full width */}
          {!allSelected && selectedOptions?.length > 0 ? (
            <FilterPill
              selectedOptions={selectedOptions}
              handleClearFilters={deselectAll}
            />
          ) : (
            <Typography
              level="body-sm"
              color="neutral"
              sx={{ fontWeight: 400, flex: '1 0 0', textAlign: 'left' }}
            >
              All
            </Typography>
          )}
        </Box>
      </DropdownButton>
      <MenuContainer
        ref={menuRef}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        placement="bottom-start"
        autoFocus={false}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0 0 0 var(--1, 0.5rem)',
            }}
          >
            <Typography level="body-sm" sx={{ fontWeight: 500 }}>
              {header}
            </Typography>
            <Dropdown>
              <IconButton
                size="sm"
                onClick={handleMoreOptionsClick}
                color="neutral"
                variant="soft"
                aria-label="More Options"
              >
                <Icon icon={faEllipsisV} />
              </IconButton>
              <Menu
                placement="bottom-end"
                anchorEl={anchorEl2}
                open={Boolean(anchorEl2)}
                ref={menuRef2}
                onClick={(e) => e.stopPropagation()}
                onClose={handleClose2}
              >
                <MenuItem
                  sx={{ color: 'var(--Neutral-800, #2C2E2F)' }}
                  onClick={selectAll}
                >
                  Select All
                </MenuItem>
                <MenuItem onClick={deselectAll}>Deselect All</MenuItem>
              </Menu>
            </Dropdown>
          </Box>
          {enableSearch && (
            <Box sx={{ padding: '.5rem 0' }}>
              <Input
                autoFocus
                id="search-options-input"
                type="search"
                variant="outlined"
                size="sm"
                startDecorator={<Icon icon={faSearch} />}
                placeholder="Search"
                sx={{
                  borderRadius: '3rem',
                  border: '1px solid var(--neutral-outlined-Border, #CDD7E1)',
                  background: 'var(--background-surface, #FBFCFE)',
                  /* shadow-xs */
                  boxShadow: '0px 1px 2px 0px rgba(21, 21, 21, 0.08)',
                  overflow: 'hidden',
                }}
                value={search}
                onChange={handleSearch}
                onFocus={(e) => e.target.select()}
              />
            </Box>
          )}
          <Box
            sx={{
              maxHeight: '200px',
              overflowY: 'auto',
            }}
          >
            {options?.map((option) => {
              const label = isActivity
                ? displayActivityType(option.value)
                : option.label;
              return (
                <Box
                  key={option.value}
                  sx={{
                    display: 'flex',
                    width: '100%',
                    margin: '0.375rem 0.5rem 0.375rem var(--1, 0.5rem)',
                    alignItems: 'center',
                    gap: '0.75rem',
                    height: '24px',
                  }}
                >
                  <Checkbox
                    variant="outlined"
                    color="neutral"
                    size="sm"
                    sx={{
                      alignItems: 'center',
                    }}
                    label={label}
                    value={option.value}
                    onChange={handleCheckboxChange}
                    checked={selectedOptions?.includes(option.value)}
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
      </MenuContainer>
    </Dropdown>
  );
};

export default MultiSelectMenu;
