import React, { useState } from 'react';
import {
  ContextCommands,
  AddToListButton,
  AddProviderDialog,
} from 'src/components';
import { useProspectSelection } from 'src/context';

const TableContextCommands = () => {
  const {
    state: [newProviders],
    selectedRows: [rowsAreSelected, setRowsAreSelected],
  } = useProspectSelection();
  const [showAddProviderDialog, setShowAddProviderDialog] = useState(false);
  return (
    <>
      <ContextCommands show={!!newProviders?.length}>
        <AddToListButton
          onClick={() => {
            setShowAddProviderDialog(true);
          }}
        />
      </ContextCommands>
      {showAddProviderDialog && (
        <AddProviderDialog
          onHide={() => {
            setRowsAreSelected(!rowsAreSelected);
            setShowAddProviderDialog(false);
          }}
          newProviders={{
            providers: newProviders,
            ui_fromMap: false,
            onSuccess: () => null,
          }}
        />
      )}
    </>
  );
};

export default TableContextCommands;
