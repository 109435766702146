import React, { useMemo } from 'react';
import { Typography, Tooltip, Box } from '@mui/joy';
import { useProspectSearch } from 'src/context';
import { useGetSavedSearches } from 'src/hooks';

const HeaderVolume = ({ field, ...props }) => {
  const { data: savedSearches } = useGetSavedSearches();
  const { prospectSearch } = useProspectSearch();

  const title = useMemo(() => {
    if (!savedSearches) return null;
    const search = savedSearches?.find((item) => {
      //TODO: This can be removed when we move to the new table structure
      if (field === 'volume') return item.id === prospectSearch?.id;
      return item.id?.toString() === field;
    });
    const cptCodes = search?.cpt?.map((item) => item.value).join(', ');
    const hcpcsCodes = search?.hcpcs?.map((item) => item.value).join(', ');
    const icd10Codes = search?.icd?.map((item) => item.value).join(', ');
    const specialty = search?.spec?.map((item) => item.title)?.join(', ');
    const drugs = search?.drug?.map((item) => item.title)?.join(', ');
    const payer = search?.payer?.map((item) => item.title)?.join(', ');
    const payments = search?.payments?.map((item) => item.title)?.join(', ');
    const sites = search?.site?.map((item) => item.title)?.join(', ');
    const systems = search?.system?.map((item) => item.title)?.join(', ');

    const formattedCodes = [
      cptCodes || hcpcsCodes || icd10Codes
        ? `Codes: ${[cptCodes, hcpcsCodes, icd10Codes]
            .filter(Boolean)
            .join(', ')}`
        : null,
      specialty ? `Specialties: ${specialty}` : null,
      drugs ? `Drugs: ${drugs}` : null,
      payer ? `Payer: ${payer}` : null,
      payments ? `Payments: ${payments}` : null,
      sites ? `Sites: ${sites}` : null,
      systems ? `Systems: ${systems}` : null,
    ]
      .filter(Boolean) // Filter out null/empty values
      .join('; '); // Join the non-empty sections with a semicolon

    return (
      <Box
        sx={{
          padding: '0.25rem',
          maxWidth: '20rem',
        }}
      >
        <Typography level="body-sm" sx={{ color: 'white' }}>
          {search?.name}
        </Typography>
        <Typography level="body-xs" sx={{ color: 'white' }}>
          {formattedCodes || 'No data available'}
        </Typography>
      </Box>
    );
  }, [savedSearches, field]);

  return (
    <Tooltip
      variant="solid"
      size="sm"
      color="primary"
      title={title}
      placement="top"
      arrow
      sx={{ padding: 0 }}
    >
      <Typography
        level="title-md"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          paddingRight: '0.5rem',
        }}
      >
        {props?.colDef?.headerName}
      </Typography>
    </Tooltip>
  );
};

export default HeaderVolume;
