import React, { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import {
  useProspectSearch,
  useLogging,
  useLayoutControl,
  useAuth,
  useDiscoveryMap,
  useTable,
  useSelectedProspects,
  useCRMButtonContext,
} from 'src/context';
// TODO: refactor these to hooks directory
import { useGetSearchResults } from 'src/hooks';
import { Box, Link, Card, CardContent } from '@mui/material';
import { Blade, ClientPagination } from 'src/components';
import ResultList from '../ResultComponents/ResultList';
import ResultCommands from '../ResultComponents/ResultCommands';
import { NO_TERRITORY_ACTIVE_SELECTION } from 'src/components/TerritoryPanel/constants';
import { sentenceCase } from 'src/utils';
import { useIntegrations } from 'src/modules/Settings/context';

const noop = () => null;

interface ResultsBladeProps {
  open: boolean;
  onClose: () => void;
  updateOnMapMove: boolean;
  addToList: (arg0: any, arg1: any, arg2: any) => void;
  contentType: 'HCP' | 'C';
  searchQueryParams: any;
  mapState: any;
  onResultHover: (arg0: any) => void;
  width: number;
}

const ResultsBlade = ({
  open,
  onClose,
  updateOnMapMove,
  addToList,
  contentType,
  searchQueryParams,
  mapState,
  onResultHover = noop,
  width,
}: ResultsBladeProps) => {
  const perPage = 500;
  const clientPerPage = 50;
  const selectAllCheckboxRef = useRef(null);
  const lastSearchQueryParams = useRef(null);
  const log = useLogging();
  const { prospectSearch, prospectVolumeType } = useProspectSearch();
  const { user } = useAuth();
  const { selectedTerritories } = useDiscoveryMap();
  const { dateRange } = useTable();
  const { clickedButtons } = useCRMButtonContext();
  const { mappings } = useIntegrations();
  const currentTable = dateRange?.every_table
    ? dateRange?.every_table
    : dateRange['discovery'];

  const { setShowFilterDrawer } = useLayoutControl();
  const [multiSelectById, setMultiSelectById] = useSelectedProspects();
  const selectedTerritoryIds = selectedTerritories
    .map((territory) => territory?.id)
    .filter((id) => id?.toString() !== NO_TERRITORY_ACTIVE_SELECTION.id);
  const [debouncedQueryParams] = useDebounce(searchQueryParams, 200);

  const [page, setPage] = useState(1);
  const [clientPage, setClientPage] = useState(1);
  const [clientFilteredResults, setClientFilteredResults] = useState<
    MedScout.SearchResult['results']
  >([]);
  const [sortOptions, setSortOptions] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [filteredResults, setFilteredResults] = useState<
    MedScout.SearchResult['results']
  >([]);

  useEffect(() => {
    if (sortBy) return;
    const defaultSort = {
      label: `Highest ${sentenceCase(prospectVolumeType)}`,
      key: `highest_${prospectVolumeType}`,
      sort: 'saved_search',
      sort_search: 'volume',
      sort_value: prospectVolumeType,
      order: 'desc',
    };

    setSortBy(defaultSort);
    return () => {
      setSortBy(null);
    };
  }, []);

  const handleClientPageChange = (
    e: React.ChangeEvent<unknown>,
    value: number
  ) => {
    if (!value || !filteredResults) return;
    const copyFilteredResults = [...filteredResults];

    const currentIndex = (value - 1) * clientPerPage;

    const newFilteredResults = copyFilteredResults.slice(
      currentIndex,
      currentIndex + clientPerPage
    );

    setClientPage(value);
    setClientFilteredResults(newFilteredResults);
  };

  // Special case for Elastic Search
  const handleDataChange = (newData) => {
    const doMatch = filteredResults.every((item) => {
      return newData.some((newItem) => {
        return newItem.id === item.id;
      });
    });

    if (!doMatch) setClientPage(1);
  };

  const multiAddClick = () => {
    log.event('multipleAddClicked', {
      count: Object.keys(multiSelectById).length,
    });
    const fromMap = false;
    const onSuccess = () => {
      if (selectAllCheckboxRef.current) {
        selectAllCheckboxRef.current.checked = false;
        selectAllCheckboxRef.current.indeterminate = false;
      }
      setMultiSelectById({});
    };
    const itemsToAdd = Object.keys(multiSelectById).map(
      (key) => multiSelectById[key]
    );
    addToList(itemsToAdd, fromMap, onSuccess);
  };

  useEffect(() => {
    lastSearchQueryParams.current = debouncedQueryParams?.queryParams;
    setPage(1);
  }, [debouncedQueryParams]);

  const enableSearch = !!prospectSearch?.id || debouncedQueryParams.enabled;
  const isThirdParty = mappings[0]?.crm;
  //only allow refetch if company is using third party crm AND push to crm buttons have been clicked
  const shouldRefetch = isThirdParty && clickedButtons.length > 0;

  const {
    data: searchResults,
    isFetching,
    isLoading,
    error,
    isError,
  } = useGetSearchResults({
    selectedTerritoryIds: selectedTerritoryIds,
    filterQueryParamString: debouncedQueryParams?.queryParams,
    page:
      lastSearchQueryParams.current === debouncedQueryParams?.queryParams
        ? page
        : 1,
    perPage,
    sort: sortBy?.sort,
    order: sortBy?.order,
    sort_search: sortBy?.sort_search || '',
    sort_value: sortBy?.sort_value || '',
    mapState,
    updateOnMapMove,
    enabled: !!enableSearch,
    company_id: user?.company?.id?.toString(),
    dateRange: {
      lte: currentTable?.lte,
      gte: currentTable?.gte,
    },
    shouldRefetch,
  });

  useEffect(() => {
    const prospectsWithUniqueTableId: MedScout.SearchResult['results'] = [];

    const dynamicSortOptions = {
      claims: {
        available: false,
        sortOptions: [
          {
            label: `Highest Claims`,
            key: `highest_claims`,
            sort: 'saved_search',
            sort_search: 'volume',
            sort_value: 'claims',
            order: 'desc',
          },
          {
            label: `Lowest Claims`,
            key: `lowest_claims`,
            sort: 'saved_search',
            sort_search: 'volume',
            sort_value: 'claims',
            order: 'asc',
          },
        ],
      },
      patients: {
        available: false,
        sortOptions: [
          {
            label: `Highest Patients`,
            key: `highest_patients`,
            sort: 'saved_search',
            sort_search: 'volume',
            sort_value: 'patients',
            order: 'desc',
          },
          {
            label: `Lowest Patients`,
            key: `lowest_patients`,
            sort: 'saved_search',
            sort_search: 'volume',
            sort_value: 'patients',
            order: 'asc',
          },
        ],
      },
      total_volume: {
        available: false,
        sortOptions: [
          {
            label: 'Highest Total Volume',
            key: 'highest_total_volume',
            sort: 'total_volume',
            order: 'desc',
          },
          {
            label: 'Lowest Total Volume',
            key: 'lowest_total_volume',
            sort: 'total_volume',
            order: 'asc',
          },
        ],
      },
      avg_reimbursement: {
        available: false,
        sortOptions: [
          {
            label: 'Highest Avg Reimbursement',
            key: 'highest_avg_reimbursement',
            sort: 'avg_reimbursement',
            order: 'desc',
          },
          {
            label: 'Lowest Avg Reimbursement',
            key: 'lowest_avg_reimbursement',
            sort: 'avg_reimbursement',
            order: 'asc',
          },
        ],
      },
      total_payments: {
        available: false,
        sortOptions: [
          {
            label: 'Highest Open Payments',
            key: 'highest_total_payments',
            sort: 'total_payments',
            order: 'desc',
          },
          {
            label: 'Lowest Open Payments',
            key: 'lowest_total_payments',
            sort: 'total_payments',
            order: 'asc',
          },
        ],
      },
      year: {
        available: false,
        sortOptions: [
          {
            label: 'Most Recent Year',
            key: 'most_recent_year',
            sort: 'year',
            order: 'desc',
          },
          {
            label: 'Oldest Year',
            key: 'oldest_year',
            sort: 'year',
            order: 'asc',
          },
        ],
      },
      specialty: {
        available: false,
        sortOptions: [
          {
            label: 'Specialty A-Z',
            key: 'highest_specialty',
            sort: 'specialty',
            order: 'asc',
          },
          {
            label: 'Specialty Z-A',
            key: 'lowest_specialty',
            sort: 'specialty',
            order: 'desc',
          },
        ],
      },
      system: {
        available: false,
        sortOptions: [
          {
            label: 'System A-Z',
            key: 'highest_system',
            sort: 'system',
            order: 'asc',
          },
          {
            label: 'System Z-A',
            key: 'highest_system',
            sort: 'system',
            order: 'desc',
          },
        ],
      },
      name: {
        available: true,
        sortOptions: [
          {
            label: 'Name A-Z',
            key: 'highest_name',
            sort: 'name',
            order: 'asc',
          },
          {
            label: 'Name Z-A',
            key: 'lowest_name',
            sort: 'name',
            order: 'desc',
          },
        ],
      },
      location: {
        available: true,
        sortHeader: 'Location',
        sortOptions: [
          {
            label: 'City A-Z',
            key: 'highest_city',
            sort: 'city',
            order: 'asc',
          },
          {
            label: 'City Z-A',
            key: 'lowest_city',
            sort: 'city',
            order: 'desc',
          },
          {
            label: 'State A-Z',
            key: 'highest_state',
            sort: 'state',
            order: 'asc',
          },
          {
            label: 'State Z-A',
            key: 'lowest_state',
            sort: 'state',
            order: 'desc',
          },
        ],
      },
      code: { available: false, sort_key: null },
      description: { available: false, sort_key: null },
      drug: { available: false, sort_key: null },
      generic: { available: false, sort_key: null },
    };
    let enableExpandableRows = false;

    if (searchResults?.results) {
      searchResults?.results.forEach((prospect, idx) => {
        // if details_params has more than 1 key it has aggregate data -- all details_params will contain type
        enableExpandableRows =
          enableExpandableRows ||
          Boolean(Object.keys(prospect.details_params).length > 1);
        dynamicSortOptions.code.available =
          dynamicSortOptions.code.available || Boolean(prospect.code);
        dynamicSortOptions.description.available =
          dynamicSortOptions.description.available ||
          Boolean(prospect.description);
        dynamicSortOptions.drug.available =
          dynamicSortOptions.drug.available || Boolean(prospect.drug);
        dynamicSortOptions.generic.available =
          dynamicSortOptions.generic.available || Boolean(prospect.generic);
        dynamicSortOptions[prospectVolumeType].available = true;
        dynamicSortOptions.avg_reimbursement.available =
          dynamicSortOptions.avg_reimbursement.available ||
          Boolean(prospect.avg_reimbursement);
        dynamicSortOptions.year.available =
          dynamicSortOptions.year.available || Boolean(prospect.year);
        dynamicSortOptions.specialty.available =
          dynamicSortOptions.specialty.available || Boolean(prospect.specialty);
        dynamicSortOptions.system.available =
          dynamicSortOptions.system.available || Boolean(prospect.system);
        dynamicSortOptions.total_volume.available =
          dynamicSortOptions.total_volume.available ||
          Boolean(prospect.total_volume);
        dynamicSortOptions.total_payments.available =
          dynamicSortOptions.total_payments.available ||
          Boolean(prospect.total_payments);

        // TODO remove when API returns content_type and unique_row_id
        const content_type = prospect.content_type || contentType;
        const tempProspect = {
          ...prospect,
          content_type,
          // data table requires a unique key,
          // due to multiple rows for a given year and/or codes being possible
          // it's important to construct a key taking these into account
          dataTableUniqueKey: `${contentType}_${prospect.id}_${
            prospect.year || ''
          }_${prospect.code || ''}_${prospect.total_volume || ''}_${idx}`,
        };
        prospectsWithUniqueTableId.push(tempProspect);
      });
    }

    setSortOptions(
      Object.keys(dynamicSortOptions)
        .filter((key) => dynamicSortOptions[key].available)
        .map((key) => dynamicSortOptions[key])
    );
    // This line is specific to Elastic Search
    handleDataChange(prospectsWithUniqueTableId);
    setFilteredResults(prospectsWithUniqueTableId);

    return () => {
      setSortOptions(null);
      setFilteredResults([]);
    };
  }, [searchResults, addToList, contentType, prospectVolumeType]);

  useEffect(() => {
    setMultiSelectById({});
  }, [isFetching]);

  useEffect(() => {
    if (filteredResults?.length > 0) {
      handleClientPageChange(null, clientPage);
    } else {
      setClientPage(1);
      setClientFilteredResults([]);
    }

    return () => {
      setClientPage(1);
      setClientFilteredResults([]);
    };
  }, [filteredResults]);

  const hasResults = Boolean(searchResults?.count);

  return (
    <Blade
      open={open}
      onClose={onClose}
      title={
        prospectSearch?.search_name || prospectSearch?.name || 'Search Results'
      }
      variant={open ? 'persistent' : 'temporary'}
      hideBackdrop={true}
      width={width}
      minWidth={width}
      maxWidth={width}
    >
      <Box
        sx={{
          maxHeight: '100%',
        }}
      >
        <ResultCommands
          {...{
            hasResults,
            sortOptions,
            searchResults: enableSearch ? searchResults : null,
            setSortBy,
            loading: isLoading || isFetching,
            selectAllCheckboxRef,
            setMultiSelectById,
            filteredResults: clientFilteredResults,
            sortBy,
            multiSelectById,
            multiAddClick,
          }}
        />
        {isError && (
          <Card sx={{ margin: '1rem' }} elevation={0}>
            <Card sx={{ margin: '1rem' }} elevation={0}>
              <CardContent>
                {error?.message || 'An error occurred while fetching results.'}
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowFilterDrawer(true);
                  }}
                >
                  Add or remove filters
                </Link>
                &nbsp;to view more results.
              </CardContent>
            </Card>
          </Card>
        )}

        {!hasResults && !isError && !isFetching && !isLoading ? (
          <Card sx={{ margin: '1rem' }} elevation={0}>
            <CardContent>
              There are no results that match your current filters and
              territory.{' '}
              <Link
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setShowFilterDrawer(true);
                }}
              >
                Add or remove filters
              </Link>
              &nbsp;to view more results.
            </CardContent>
          </Card>
        ) : (
          <>
            <ResultList
              results={enableSearch ? clientFilteredResults : []}
              onResultHover={onResultHover}
              selectAllCheckboxRef={selectAllCheckboxRef}
              loading={isLoading || isFetching}
            />
            <ClientPagination
              page={clientPage}
              perPage={clientPerPage}
              count={filteredResults?.length}
              onPageChange={handleClientPageChange}
            />
          </>
        )}
      </Box>
    </Blade>
  );
};

export default ResultsBlade;
