import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Tooltip,
} from '@mui/joy';
import { TypeIcon } from 'src/components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleRight,
  faExternalLinkAlt,
  faPlus,
  faQuestionCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { useLogging, useNotification } from 'src/context';
import { sentenceCase } from 'src/utils';
import Schedule from './Schedule';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { styled } from '@mui/material';
import { AddToListDialog } from 'src/components';
import { addItemsToList } from 'src/hooks';

const RowContainer = styled(AccordionSummary)({
  display: 'flex',
  height: 'var(--8, 4.25rem)',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  overflow: 'hidden',
  margin: 0,
});

const IconContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '24px',
  width: '24px',
  backgroundColor: '#F0F4F8',
  borderRadius: '50%',
  flex: '0 0 24px',
});

const LeftSideContainer = styled(Box)({
  display: 'flex',
  position: 'relative',
  flex: 1,
  alignItems: 'center',
  height: '100%',
  width: '100%',
  '&:hover .hover-button': {
    opacity: 1,
    pointerEvents: 'auto',
  },
});

const LeftSide = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '0.75rem',
  height: '100%',
  padding: '0 var(--2, 1rem)',
  cursor: 'pointer',
  flex: '1 0 0',
});

const RightSide = styled(Box)({
  display: 'flex',
  height: '110%',
  padding: '0 1rem',
  margin: '-1px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-end',
  backgroundColor: '#D6F6F1',
  // had to play around with this value to get the right alignment for longer values
  flex: '0.13 0 0',
});

const HoverButton = styled(Button)({
  position: 'absolute',
  top: '50%',
  right: '8px', // Adjust the position as needed
  transform: 'translateY(-50%)',
  opacity: 0,
  pointerEvents: 'none',
  transition: 'opacity 0.3s ease-in-out',
  boxShadow: '0px 1px 2px 0px rgba(21, 21, 21, 0.08)',
  background: 'var(--background-surface, #FBFCFE)',
});

const StyledLink = styled('a')({
  display: 'flex',
  alignItems: 'center',
  color: '#021B9C',
});

const MetaTypography = styled(Typography)({
  color: 'var(--text-tertiary, #555E68)',
  fontSize: '0.75rem',
  lineHeight: '1.095rem',
  fontWeight: 500,
});

const MetaContainer = styled(Box)({
  display: 'flex',
  gap: '0.5rem',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
});

interface LocationByDayProps {
  isFirst?: boolean;
  expanded: string[];
  setExpanded: React.Dispatch<React.SetStateAction<string[]>>;
  location: {
    provider_id: string;
    type: string;
    name: string;
    claims: string;
    percentage: number;
    obfuscated: boolean;
    schedule?: { [key: string]: { [key: string]: number } };
  };
}

const LocationByDay = ({
  location,
  isFirst = false,
  expanded,
  setExpanded,
}: LocationByDayProps) => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const { sameTabRouting } = useFlags();

  const [showProviderDialog, setShowProviderDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const providers = [location];

  useEffect(() => {
    if (isFirst) {
      setExpanded([location.provider_id]); // Optionally initialize the first accordion to be open
    }
  }, [isFirst]);

  const handleAccordionChange = (locationId: string) => {
    setExpanded((prevExpanded) => {
      // Toggle the clicked accordion ID
      if (prevExpanded.includes(locationId)) {
        return prevExpanded.filter((id) => id !== locationId); // Remove if already expanded
      } else {
        return [...prevExpanded, locationId]; // Add to the array
      }
    });
  };

  const formatLocationType = (type: string) => {
    return type === 'center' ? 'Hospital' : sentenceCase(type);
  };

  const handleAddProvider = async (listId: number) => {
    if (!listId) return;
    setIsLoading(true);
    try {
      await addItemsToList(listId, [{ ...location }]);
      log.event('addProvider', {
        source: 'ProfileSummary',
        count: providers?.length,
      });

      setNotification({
        title: 'Providers added',
        message: `Added ${providers?.length} prospects to the list.`,
        type: 'success',
      });
    } catch (err) {
      log.exception('addProvider', {
        tags: {
          source: 'ProfileSummary',
          count: providers?.length,
          error: err.message,
        },
      });

      setNotification({
        title: 'Error',
        message:
          err.message ||
          'An error occurred while adding providers to the list.',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isLessThanEleven = location?.claims && location?.claims === '<11';

  return (
    <Box sx={{ paddingBottom: '.5rem' }}>
      <Accordion
        key={location.provider_id}
        expanded={expanded.includes(location.provider_id)} // Check if the ID is in the expanded array
        onChange={() => handleAccordionChange(location.provider_id)}
        sx={{
          margin: '0 1rem .5rem',
          border: '1px solid #e0e0e0',
          borderRadius: 'sm',
          padding: 0,
          overflow: 'hidden',
        }}
      >
        <RowContainer indicator={null}>
          <LeftSideContainer>
            <LeftSide className="left-side">
              {/* Custom icon moved to the left */}
              <IconContainer>
                <Icon
                  icon={
                    expanded.includes(location.provider_id)
                      ? faAngleDown
                      : faAngleRight
                  }
                  style={{
                    color: '#636B74',
                    padding: 0,
                  }}
                />
              </IconContainer>
              <TypeIcon
                type={location.type}
                size="xl"
                style={{ margin: '0', color: '#021B9C' }}
              />
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  alignContent: 'center',
                }}
              >
                <StyledLink
                  href={`/profiles/${location.type}/${location.provider_id}`}
                  target={sameTabRouting ? '_self' : '_blank'}
                  rel="noopener noreferrer"
                  onClick={(e) => {
                    e.stopPropagation();
                    log.event('Location Clicked', {
                      source: 'web',
                      location: 'VolumeByLocation',
                      provider_id: location.provider_id,
                    });
                  }}
                >
                  <Typography level="body-sm" sx={{ color: '#021B9C' }}>
                    {location.name}
                  </Typography>
                  <Icon
                    icon={faExternalLinkAlt}
                    size="xs"
                    style={{ marginLeft: '.25rem', color: 'inherit' }}
                  />
                </StyledLink>
                <MetaContainer>
                  <MetaTypography>
                    {formatLocationType(location.type)}
                  </MetaTypography>
                </MetaContainer>
              </Box>
            </LeftSide>
            <HoverButton
              loading={isLoading}
              className="hover-button"
              variant="outlined"
              color="neutral"
              startDecorator={<Icon icon={faPlus} />}
              onClick={() => setShowProviderDialog(true)}
            >
              Add
            </HoverButton>
          </LeftSideContainer>
          <RightSide>
            <Tooltip
              variant="soft"
              size="md"
              color="neutral"
              title="Data can be any number 1 to 10"
              placement="top"
              arrow
              disableFocusListener={!isLessThanEleven}
              disableHoverListener={!isLessThanEleven}
              disableTouchListener={!isLessThanEleven}
              disableInteractive={!isLessThanEleven}
            >
              <Typography
                level="body-md"
                style={{
                  color: '#005B38',
                  fontWeight: 600,
                  cursor: isLessThanEleven ? 'pointer' : 'default',
                }}
              >
                {location?.claims && Number(location?.claims)?.toLocaleString()}
              </Typography>
            </Tooltip>
            <Tooltip
              variant="soft"
              size="md"
              color="neutral"
              title="of total Site volume"
              placement="top"
              arrow
            >
              {location?.percentage ? (
                <Typography
                  level="body-md"
                  style={{
                    color: '#005B38',
                    cursor: 'pointer',
                    fontWeight: 400,
                  }}
                >
                  {location?.percentage}%
                </Typography>
              ) : (
                <Icon icon={faQuestionCircle} size="sm" color="#636B74" />
              )}
            </Tooltip>
          </RightSide>
        </RowContainer>
        <AccordionDetails>
          <Schedule schedule={location.schedule} />
        </AccordionDetails>
      </Accordion>
      <AddToListDialog
        open={showProviderDialog}
        onClose={() => setShowProviderDialog(false)}
        onAddProvider={handleAddProvider}
      />
    </Box>
  );
};

export default LocationByDay;
