import { CSSProperties, ReactNode } from 'react';

const TableHeaderItem = ({
  children,
  styles,
}: {
  children?: ReactNode;
  styles?: CSSProperties;
}) => {
  return (
    <th
      style={{
        display: 'flex',
        padding: '1.5rem 1rem!important',
        alignItems: 'center',
        alignSelf: 'stretch',
        ...styles,
      }}
    >
      {children}
    </th>
  );
};

export default TableHeaderItem;
