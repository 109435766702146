import { env } from 'src/constants';
/**
 * Get Google Static Maps URL for given polygons
 * @param {Array} polygons - List of polygons
 * @param {String} size - Size of the map
 * @returns {String} - Google Static Maps URL
 */

const MAX_URL_LENGTH = 16384;
const MAX_POINTS = 256;
const tolerance = 0.1;

// Get polygon path string
const getPolygonPath = (polygon: any) => {
  return polygon.map((point: any) => `${point.lat},${point.lng}`).join('|');
};

// Get Euclidean distance between two points
const getEuclideanDistance = (point1, point2) => {
  return Math.sqrt(
    Math.pow(point2.lat - point1.lat, 2) + Math.pow(point2.lng - point1.lng, 2)
  );
};

// Get perpendicular distance from a point to a line
const getPerpendicularDistance = (point, lineStart, lineEnd) => {
  const area = Math.abs(
    0.5 *
      (lineStart.lat * lineEnd.lng +
        lineEnd.lat * point.lng +
        point.lat * lineStart.lng -
        lineEnd.lat * lineStart.lng -
        point.lat * lineEnd.lng -
        lineStart.lat * point.lng)
  );
  const bottom = getEuclideanDistance(lineStart, lineEnd);
  const height = (area / bottom) * 2;
  return height;
};

// Ramer-Douglas-Peucker algorithm
const rdpSimplify = (points, epsilon) => {
  const stack = [[0, points.length - 1]];
  const kept = Array(points.length).fill(false);
  kept[0] = kept[points.length - 1] = true;

  while (stack.length) {
    const [startIdx, endIdx] = stack.pop();
    let dmax = 0;
    let index = startIdx;

    for (let i = startIdx + 1; i < endIdx; i++) {
      const d = getPerpendicularDistance(
        points[i],
        points[startIdx],
        points[endIdx]
      );
      if (d > dmax) {
        index = i;
        dmax = d;
      }
    }

    if (dmax > epsilon) {
      kept[index] = true;
      stack.push([startIdx, index], [index, endIdx]);
    }
  }

  return points.filter((_, i) => kept[i]);
};

// Simplify polygon using Ramer-Douglas-Peucker algorithm
const simplifyPolygon = (polygon) => {
  return rdpSimplify(polygon, tolerance);
};

// Split polygon into multiple paths if it has too many points
const splitPolygon = (polygon, maxPoints) => {
  const chunks = [];
  for (let i = 0; i < polygon.length; i += maxPoints) {
    chunks.push(polygon.slice(i, i + maxPoints));
  }
  return chunks;
};

const lastPathMatchesFirstPath = (paths) => {
  const firstPath = paths[0];
  const lastPath = paths[paths.length - 1];

  // if not then add the first path to the end of the array
  if (firstPath !== lastPath) {
    paths.push(firstPath);
  }

  return paths;
};

// Get Google Static Maps URL for given polygons
export const getMapUrl = ({ polygons, size = '200x200' }) => {
  const paths = [];

  polygons.forEach((polygon) => {
    // Simplify polygon to reduce the number of points
    const simplifiedPolygon = simplifyPolygon(polygon);
    const verifiedPaths = lastPathMatchesFirstPath(simplifiedPolygon);
    // If polygon has too many points, split it into multiple paths
    if (verifiedPaths.length > MAX_POINTS) {
      const chunks = splitPolygon(verifiedPaths, MAX_POINTS);
      chunks.forEach((chunk) => {
        paths.push(`&path=weight:3|${getPolygonPath(chunk)}`);
      });
    } else {
      paths.push(`&path=weight:3|${getPolygonPath(verifiedPaths)}`);
    }
  });

  const baseUrl = `https://maps.googleapis.com/maps/api/staticmap?&size=${size}&maptype=roadmap&key=${env.GOOGLE_MAPS_KEY}`;
  let url = baseUrl + paths.join('');

  // If URL is too long, remove paths one by one until it fits within the limit
  while (url.length > MAX_URL_LENGTH && paths.length > 0) {
    paths.pop();
    url = baseUrl + paths.join('');
  }

  return url;
};
