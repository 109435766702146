import React, { useEffect, useState } from 'react';
import { Box, Input, Slider, Typography } from '@mui/joy';
import { useMedScoutMap } from 'src/context';

const METERS_PER_MILE = 1609.34;

const RadiusPanel = () => {
  const { radius, setRadius, currentCircleRef } = useMedScoutMap();

  // Local state for user input
  const [newRadius, setNewRadius] = useState(radius);

  // Effect to sync newRadius with radius on mount and whenever radius changes
  useEffect(() => {
    setNewRadius(radius);
  }, [radius]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (!isNaN(value)) {
      const updatedRadius = Math.round(value * METERS_PER_MILE);
      setNewRadius(updatedRadius);
      setRadius(updatedRadius); // Update the global state immediately
    }
  };

  const handleSliderChange = (_: any, value: number | number[]) => {
    const newValue = value as number;
    const updatedRadius = Math.round(newValue * METERS_PER_MILE);
    setNewRadius(updatedRadius);
    setRadius(updatedRadius); // Update the global state immediately
  };

  return (
    <Box>
      <Typography level="body-xs" sx={{ fontWeight: 600 }}>
        Radius (miles)
      </Typography>
      <Box sx={{ padding: '0.5rem 0' }}>
        <Input
          type="number"
          value={Math.round(newRadius / METERS_PER_MILE)}
          onChange={handleInputChange}
          sx={{ transition: 'all 0.3s ease' }} // Add transition effect to input
          disabled={currentCircleRef.current === null}
        />
      </Box>
      <Slider
        size="sm"
        aria-label="Always visible"
        step={5}
        max={300}
        marks={[
          // Add marks to the slider
          { value: 0, label: '0' },
          { value: 50, label: '50' },
          { value: 100, label: '100' },
          { value: 150, label: '150' },
          { value: 200, label: '200' },
          { value: 250, label: '250' },
          { value: 300, label: '300' },
        ]}
        value={Math.round(newRadius / METERS_PER_MILE)}
        onChange={handleSliderChange}
        sx={{
          marginTop: '-0.25rem',
          marginBottom: '1rem',
        }}
        disabled={currentCircleRef.current === null}
      />
    </Box>
  );
};

export default RadiusPanel;
