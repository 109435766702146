// Calculate the points of a polygon that approximates a circle
export const calculateCirclePolygon = (center, radius, numPoints) => {
  const points = [];
  const lat = center.lat();
  const lng = center.lng();

  for (let i = 0; i < numPoints; i++) {
    const angle = (i / numPoints) * (Math.PI * 2); // Full circle
    const pointLat = lat + (radius / 111300) * Math.sin(angle); // Convert meters to degrees
    const pointLng =
      lng +
      (radius / (111300 * Math.cos((lat * Math.PI) / 180))) * Math.cos(angle);
    points.push({ lat: pointLat, lng: pointLng });
  }

  return points;
};
