import React from 'react';
import { IconButton, VariantProp } from '@mui/joy';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

interface HoverButtonProps {
  icon: any;
  size?: 'sm' | 'md' | 'lg';
  sx?: any;
  variant?: VariantProp;
  isHovered: boolean;
  onClick: (e: any) => void;
}

const HoverButton = ({
  icon,
  onClick,
  sx,
  size = 'md',
  variant = 'plain',
  isHovered = false,
}: HoverButtonProps) => {
  return (
    <IconButton
      size={size}
      variant={variant}
      onClick={onClick}
      sx={{
        opacity: isHovered ? 1 : 0,
        transition: 'opacity 0.2 ease-in-out',
        ...sx,
      }}
    >
      <Icon icon={icon} style={{ color: '#636B74' }} />
    </IconButton>
  );
};

export default HoverButton;
