import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { Box, Typography, styled, Tooltip } from '@mui/material';
import NotFound from 'src/components/NotFound';
import { grey } from '@mui/material/colors';
import { LoadingIndicator } from 'src/components';
import { LineSvgProps } from '@nivo/line';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { DiagonalHatch } from 'src/components/SVG';
import { exists, handleNumericValue } from 'src/utils';

const ResponsiveLine = dynamic(
  () => import('@nivo/line').then((m) => m.ResponsiveLine),
  { ssr: false }
);
interface LineChartProps {
  data: any;
  searchValues: any;
}

interface RootProp {
  color?: string;
  elevation?: number;
  widthDepth?: number;
}

const lineTheme = {
  axis: {
    domain: {
      line: {
        stroke: grey[700],
        strokeWidth: 1,
      },
    },

    ticks: {
      line: {
        stroke: grey[700],
        strokeWidth: 1,
      },
    },
  },
};

const TooltipContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'elevation',
})<RootProp>(({ theme, elevation }) => ({
  background: 'white',
  padding: '0.5rem',
  border: `1px solid ${grey[200]}`,
  borderRadius: '0.25rem',
  boxShadow: theme.shadows[elevation || 1],
}));

const TooltipHeader = styled(Box)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1rem',
  marginBottom: '0.5rem',
  textAlign: 'center',
  borderBottom: `1px solid ${grey[200]}`,
}));

const TooltipBody = styled(Box)({
  marginBottom: '0.5rem',
  display: 'flex',
  gap: '0.5rem',
  alignItems: 'center',
});

const TooltipColorKey = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})<RootProp>(({ color }) => ({
  width: '0.75rem',
  height: '0.75rem',
  background: `${color || grey[700]}`,
}));

const LineChart: React.FC<LineChartProps & LineSvgProps> = ({
  data,
  searchValues,
  ...props
}) => {
  const [filteredChartData, setFilteredChartData] = useState([]);
  const [isAllHidden, setIsAllHidden] = useState(false);

  //find length of longest value along y axis
  const findMaxVolumeLength = (yData: { x: string; y: number }[]) => {
    if (!exists(yData)) return;
    const yValues = Object.values(yData).map((point) => point.y);
    const largestY = yValues.reduce((max, y) => (y > max ? y : max), 0);
    return largestY.toString().length;
  };

  const maxVolumeLength = findMaxVolumeLength(data?.results[0]?.data);

  //set left margin of chart based on maxVolumeLength
  const setLeftMargin = (volumeLength: number): number => {
    const marginMap: { [key: number]: number } = {
      5: 60,
      6: 65,
      7: 70,
      8: 75,
      9: 80,
    };

    return marginMap[volumeLength] ?? 50;
  };

  const leftMargin = setLeftMargin(maxVolumeLength);

  useEffect(() => {
    if (!data || !searchValues) return null;

    const filterData = () => {
      const filteredData = data.results.filter((result) => {
        return !searchValues.some((s) => s.id === result.id && s.hidden);
      });
      setFilteredChartData(filteredData);
    };

    setIsAllHidden(searchValues.every((s) => s.hidden));
    filterData();

    return () => {
      setFilteredChartData([]);
      setIsAllHidden(false);
    };
  }, [data, searchValues]);

  if (!filteredChartData) return <LoadingIndicator />;

  const overlayLayer = (props) => {
    const depth = data?.overlay_width || 0;
    // Removing the margin from the height and width so it sits on the chart.
    const height = props.height - props.margin.bottom - props.margin.top;
    const width = props.width - props.margin.left - props.margin.right;
    // props.xScale.domain() is the unique values on the x axis.
    const pointsCount = props.xScale.domain()?.length || 0;
    const xScaleValue = pointsCount - depth - 1; // because xScale is 0 based

    // domain()[value] is whatever value from the left.
    return (
      <g>
        <DiagonalHatch id="diagonalHatch" />
        <rect
          x={props.xScale(props.xScale.domain()[xScaleValue])}
          y={0}
          width={width - props.xScale(props.xScale.domain()[xScaleValue])}
          height={height}
          fill="url(#diagonalHatch)"
          opacity={0.3}
        />
      </g>
    );
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginRight: '45px', // match the chart margin
        }}
      >
        <Typography
          sx={{
            padding: '0.5rem 0 0.5rem 1rem',
            fontWeight: 600,
          }}
        >
          {data.card_name}
        </Typography>
        <Tooltip
          title={['Data is still populating for this time frame']}
          placement="left"
          arrow
        >
          <Icon icon={faInfoCircle} size="sm" color="inherit" />
        </Tooltip>
      </Box>
      <Box
        key={data.id}
        sx={{
          height: '300px',
          width: '100%',
          position: 'relative',
        }}
      >
        {!isAllHidden ? (
          <ResponsiveLine
            layers={[
              'grid',
              overlayLayer,
              'markers',
              'axes',
              'areas',
              'crosshair',
              'lines',
              'points',
              'slices',
              'mesh',
              'legends',
            ]}
            data={filteredChartData}
            theme={lineTheme}
            lineWidth={5}
            pointBorderWidth={5}
            pointSize={15}
            pointBorderColor={{ from: 'serieColor' }}
            pointColor={'white'}
            colors={filteredChartData.map((c) => {
              return c.color;
            })}
            enableGridX={false}
            enableGridY={false}
            margin={{ top: 10, right: 20, bottom: 50, left: leftMargin }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              tickValues: 5,
            }}
            isInteractive={true}
            enableSlices="x"
            sliceTooltip={({ slice }) => {
              return (
                <TooltipContainer elevation={2}>
                  <TooltipHeader>
                    {slice.points[0].data.xFormatted}
                  </TooltipHeader>
                  {slice.points.map((point) => {
                    return (
                      <TooltipBody key={point.id}>
                        <TooltipColorKey color={point.serieColor} />
                        <Typography sx={{ fontSize: '0.8rem' }}>
                          {handleNumericValue(point.data.yFormatted)}
                        </Typography>
                      </TooltipBody>
                    );
                  })}
                </TooltipContainer>
              );
            }}
            {...props}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <NotFound
              message="You must select at least one saved search."
              elevation={0}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LineChart;
