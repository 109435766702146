import React from 'react';
import { Box, Typography } from '@mui/joy';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faFolderXmark } from '@fortawesome/pro-solid-svg-icons';

const InvalidSavedSearch = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '25rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.5rem',
        padding: '0 1rem',
      }}
    >
      <Icon icon={faFolderXmark} size="3x" color="#9FA6AD" />
      <Typography
        level="body-md"
        sx={{
          color: 'var(--neutral-500, #636B74)',
          fontWeight: 400,
          textAlign: 'center',
        }}
      >
        Your selected search contains diagnosis codes that cannot be applied to
        this view.
      </Typography>
      <Typography
        level="body-md"
        sx={{
          color: 'var(--neutral-500, #636B74)',
          fontWeight: 700,
          textAlign: 'center',
        }}
      >
        Select a search containing procedure codes to see results.
      </Typography>
    </Box>
  );
};

export default InvalidSavedSearch;
