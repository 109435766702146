import { useFilters } from '../../context';
import { styled } from '@mui/material';
import FilterGroup from './FilterGroup';
import { useFlags } from 'launchdarkly-react-client-sdk';

const FilterContainer = styled('form', {
  shouldForwardProp: (prop) => prop !== 'flagged',
})<{ flagged: boolean }>(({ flagged }) => ({
  fontWeight: 500,
  overflow: ' hidden',
  width: '20rem',
  minWidth: '15rem',
  maxWidth: '20rem',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  background: flagged ? 'transparent' : '#fff',
}));

/**
 * Filter component, conditionally renders filter sets available
 * against provider data.
 *
 * @return {*}
 */
const DataFilters = () => {
  const { newProfileHeader } = useFlags();
  const { filters } = useFilters();
  const hasContent = Object.values(filters).some(
    (group) => group?.filters?.length > 0
  );
  if (!filters || !hasContent) return null;

  return (
    <FilterContainer
      data-testid="dataFilterContainer"
      onSubmit={(e) => e.preventDefault()}
      flagged={newProfileHeader}
    >
      {Object.keys(filters).map((name) => {
        const group = filters[name];
        return <FilterGroup key={name} group={group} name={name} />;
      })}
    </FilterContainer>
  );
};

export default DataFilters;
