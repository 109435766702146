import { Box, Grid, Typography } from '@mui/joy';
import { formatDayAbbr } from 'src/utils/formatDayAbbr/formatDayAbbr';

interface ScheduleProps {
  schedule: { [key: string]: { [key: string]: number } };
}

const bgColorByPercentage = (input: number | string): string => {
  if (typeof input === 'number') {
    if (input === 0) {
      return '#DDE7EE';
    } else if (input >= 1 && input <= 20) {
      return '#C7F7C7';
    } else if (input >= 21 && input <= 40) {
      return '#51BC51';
    } else if (input >= 41 && input <= 60) {
      return '#1F7A1F';
    } else if (input >= 61) {
      return '#0A470A';
    }
  } else {
    return '#C7F7C7';
  }
};

const textColorByPercentage = (input: number | string): string => {
  if (typeof input === 'number') {
    if (input === 0) {
      return 'disabled';
    } else if (input >= 1 && input <= 20) {
      return 'success.500';
    } else if (input >= 21 && input <= 40) {
      return 'success.200';
    } else if (input >= 41 && input <= 60) {
      return 'success.300';
    } else if (input >= 61) {
      return 'success.200';
    }
  } else {
    return 'success.500';
  }
};

const Schedule = ({ schedule }: ScheduleProps) => {
  return (
    <Box sx={{ backgroundColor: '#F0F4F8', padding: '1rem 1rem .5rem' }}>
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <Typography
            level="title-sm"
            sx={{ fontWeight: '600', paddingBottom: '.25rem' }}
          >
            Schedule
          </Typography>
        </Box>
        <Typography level="body-xs" sx={{ color: '#555E68' }}>
          Shows the highest concentration of days the provider is rendering
          services at this location.
        </Typography>
        <Grid
          container
          columns={7}
          columnSpacing={1}
          sx={{ marginTop: '.5rem' }}
        >
          {Object.entries(schedule).map(([day]) => {
            const formattedDay = formatDayAbbr(day);
            const percentageValue =
              schedule[day].percentage ?? schedule[day].claims;
            const formattedPercentage =
              typeof percentageValue === 'number'
                ? `${percentageValue}%`
                : percentageValue;
            return (
              <Grid xs={1} key={`${percentageValue}-${formattedDay}`}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '0',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      backgroundColor: bgColorByPercentage(percentageValue),
                      borderRadius: '.5rem',
                      maxWidth: '4rem',
                      padding: '.25rem 0',
                      width: '100%',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      textColor={textColorByPercentage(percentageValue)}
                    >
                      {formattedDay}
                    </Typography>
                  </Box>
                  <Typography
                    level="body-sm"
                    style={{
                      padding: '.5rem 0',
                      color: '#292B33',
                    }}
                  >
                    {formattedPercentage}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default Schedule;
