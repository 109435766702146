import { ProfileActionTypes } from './actionTypes';

export type DrawerState = {
  profileDrawerOpen: boolean;
  provider: {
    id: string;
    type: string;
  };
  queryParams?: {
    activities: string[];
  };
};

export const initialProfileState: DrawerState = {
  profileDrawerOpen: false,
  provider: {
    id: null,
    type: null,
  },
  queryParams: {
    activities: [],
  },
};

export const profileReducer = (
  state: DrawerState = initialProfileState,
  action: any
): DrawerState => {
  switch (action.type) {
    case ProfileActionTypes.OPEN_PROFILE_DRAWER:
      return {
        ...state,
        profileDrawerOpen: true,
      };
    case ProfileActionTypes.CLOSE_PROFILE_DRAWER:
      return {
        ...state,
        profileDrawerOpen: false,
      };
    case ProfileActionTypes.SET_CURRENT_PROVIDER:
      return {
        ...state,
        provider: action.payload,
      };
    case ProfileActionTypes.SET_QUERY_PARAMS:
      return {
        ...state,
        queryParams: action.payload,
      };
    default:
      return state;
  }
};
