import { Box, Typography } from '@mui/joy';
import { exists, formatPhoneNumber, hasContent } from 'src/utils';
import Linkify from 'react-linkify';
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import InfoUnavailable from '../InfoUnavailable';
import { contentStyles } from '../styleConstants';
import { useFlags } from 'launchdarkly-react-client-sdk';

type DetailsDisplayProps = {
  items: any[];
  options?: { [key: string]: string };
  isAffil?: boolean;
};

const DetailsDisplay: FC<DetailsDisplayProps> = ({
  items,
  options,
  isAffil,
}) => {
  const { sameTabRouting } = useFlags();

  return (
    <>
      {exists(items) ? (
        items.map((item, i) => {
          const isPhone = item.title === 'Phone';
          const formattedValue = isPhone
            ? formatPhoneNumber(item.value)
            : item.value;

          return (
            <Box key={`${item.title}-${i}`} sx={contentStyles(i, isAffil)}>
              {exists(options) ? (
                <Typography level="body-sm">
                  <a
                    href={`${options.basePath}${item[options.idKey]}`}
                    target={sameTabRouting ? '_self' : '_blank'}
                    rel="noopener noreferrer"
                  >
                    {item[options.displayKey]}{' '}
                    <Icon icon={faExternalLinkAlt} size="xs" />
                  </a>
                </Typography>
              ) : (
                <>
                  <Typography level="body-sm" sx={{ alignSelf: 'flex-start' }}>
                    {item.title}
                  </Typography>
                  <Typography level="body-sm" sx={{ color: '#3f4148' }}>
                    {isPhone && formattedValue ? (
                      <a href={`tel:${formattedValue}`}>{formattedValue}</a>
                    ) : (
                      <Linkify
                        componentDecorator={(href, text, key) => (
                          <a
                            href={href}
                            key={key}
                            target={sameTabRouting ? '_self' : '_blank'}
                            rel="noreferrer"
                          >
                            {text}
                          </a>
                        )}
                      >
                        {Array.isArray(formattedValue) &&
                        hasContent(formattedValue) ? (
                          formattedValue.map((value, index) => (
                            <Box sx={{ textAlign: 'right' }} key={index}>
                              {value}
                            </Box>
                          ))
                        ) : (
                          <Box sx={{ textAlign: 'right' }}>
                            {formattedValue}
                          </Box>
                        )}
                      </Linkify>
                    )}
                  </Typography>
                </>
              )}
            </Box>
          );
        })
      ) : (
        <InfoUnavailable />
      )}
    </>
  );
};

export default DetailsDisplay;
