import useSWR from 'swr';
import { getSavedSearches } from 'src/api';
import { QUERY_KEYS } from './constants';

/**
 * Custom hook to fetch saved searches for the selected company.
 * @returns The result of the query.
 */
export default function useGetSavedSearches() {
  return useSWR([QUERY_KEYS.saved_searches], () => getSavedSearches(), {
    revalidateOnFocus: false,
  });
}
