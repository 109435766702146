import { Box, Typography } from '@mui/joy';

const InfoUnavailable = () => {
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '0 0.5rem',
        height: '2rem',
        margin: '0 0.5rem 0.5rem',
      }}
    >
      <Typography level="body-sm" sx={{ color: '#3f4148', fontWeight: 500 }}>
        Not available
      </Typography>
    </Box>
  );
};

export default InfoUnavailable;
