import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Autocomplete, Box, Chip, Typography } from '@mui/joy';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faFolderBookmark,
  faClose,
  faChevronDown,
} from '@fortawesome/pro-solid-svg-icons';
import { useGetNewSavedSearches } from 'src/hooks';
import { useProspectSearch, useSite } from 'src/context';
import { useDebounce } from 'use-debounce';
import { CircularProgress } from '@mui/material';

const SavedSearchAutocomplete = ({ selectedSearches, setSelectedSearches }) => {
  const isInitialMount = useRef(true);
  const { prospectSearch } = useProspectSearch();
  const { state, dispatch } = useSite();
  const { data: savedSearches, isLoading } = useGetNewSavedSearches();
  const [localSelectedSearches, setLocalSelectedSearches] =
    useState(selectedSearches);

  const [search, setSearch] = useState('');
  const [lastProspectSearch, setLastProspectSearch] = useState(null);

  const [debouncedSearch] = useDebounce(search, 500);

  useEffect(() => {
    const handler = setTimeout(() => {
      const syncSearches = (newSearch) => {
        const deduplicatedSearches = [
          ...localSelectedSearches.filter(
            (search) => search.id !== newSearch.id
          ),
          newSearch,
        ];

        // if any element is an object, but does not have an id, we need to remove it
        const validSearches = deduplicatedSearches.filter(
          (search) => typeof search === 'string' || search.id
        );

        setLocalSelectedSearches(validSearches);
        setSelectedSearches(validSearches);
        setLastProspectSearch(newSearch);
      };

      if (isInitialMount.current) {
        // Handle initial mount logic
        const isIn = localSelectedSearches.some(
          (search) => search.id === prospectSearch?.id
        );
        if (prospectSearch && !isIn) syncSearches(prospectSearch);
        isInitialMount.current = false; // Mark initial mount as complete
      } else {
        // Handle updates
        if (lastProspectSearch?.id !== prospectSearch?.id) {
          syncSearches(prospectSearch);
        } else if (
          JSON.stringify(selectedSearches) !==
          JSON.stringify(localSelectedSearches)
        ) {
          setSelectedSearches(localSelectedSearches);
        }
      }
    }, 100); // Debounce delay of 100ms

    return () => clearTimeout(handler); // Cleanup timeout
  }, [
    localSelectedSearches,
    selectedSearches,
    prospectSearch,
    lastProspectSearch,
    setSelectedSearches,
  ]);

  useEffect(() => {
    dispatch({
      type: 'SET_QUERY_PARAMS',
      payload: {
        ...state.savedSearch.queryParams,
        q: debouncedSearch,
      },
    });
  }, [debouncedSearch]);

  // Update local state on selection change
  const handleSelectionChange = (event, newValue) => {
    setLocalSelectedSearches(newValue);
  };

  const sortedSavedSearches = useMemo(() => {
    if (!savedSearches) return [];
    return savedSearches.sort((a, b) =>
      a.search_name?.localeCompare(b.search_name)
    );
  }, [savedSearches]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: '0.5rem',
        width: '100%',
        minWidth: 210,
      }}
    >
      <Typography
        level="body-sm"
        sx={{
          fontSize: '0.875rem',
          fontWeight: 500,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          width: '100%',
        }}
      >
        View volume for:
      </Typography>
      <Autocomplete
        freeSolo
        multiple
        filterSelectedOptions
        placeholder="Add a Saved Search"
        // handles search input change
        onInputChange={(event, value) => setSearch(value)}
        value={localSelectedSearches}
        onChange={handleSelectionChange}
        limitTags={2}
        options={sortedSavedSearches}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionKey={(option) => option.id}
        getOptionLabel={(option) => option.search_name}
        startDecorator={<Icon icon={faFolderBookmark} />}
        renderTags={(tag, getTagProps) =>
          tag.map((option, index) => {
            return (
              <Chip
                key={index}
                endDecorator={<Icon icon={faClose} />}
                variant="plain"
                color="neutral"
                sx={{
                  minWidth: 0,
                  color: '#009976',
                  '& .MuiChip-action': {
                    background: '#D6F6F1 !important',
                    '&:hover': {
                      background: '#CBE9E4 !important',
                    },
                  },
                }}
                {...getTagProps({ index })}
              >
                {option.search_name}
              </Chip>
            );
          })
        }
        slotProps={{
          listbox: {
            placement: 'bottom',
            zIndex: 99999,
          },
        }}
        sx={{
          width: '100%',
          minWidth: 200,
          borderRadius: '0.5rem',
        }}
      />
    </Box>
  );
};

export default SavedSearchAutocomplete;
