import { request } from 'src/api/request';

interface GetHexUrlProps {
  reportId?: string;
  queryParams?: string;
}

export const getHexUrl = ({ reportId, queryParams }: GetHexUrlProps) => {
  return request({
    url: `v1/admin/hex/embedded-link/${reportId}/${queryParams}`,
    method: 'get',
  });
};
