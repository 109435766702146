// TODO: this will be moved into Site Provider when those changes are merged
import { createContext, ReactNode, useContext, useReducer } from 'react';

export const ActivityActionTypes: { [key: string]: string } = {
  SET_ACTIVITY: 'SET_ACTIVITY',
  SET_SHOW_DIALOG: 'SET_SHOW_DIALOG',
};

type ActivityState = {
  activity: number;
  showDialog: boolean;
};

const initialState: ActivityState = {
  activity: 1,
  showDialog: false,
};

const ActivityContext = createContext<{
  state: ActivityState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

const activityReducer = (state: ActivityState, action: any) => {
  switch (action.type) {
    case ActivityActionTypes.SET_ACTIVITY: {
      return {
        ...state,
        activity: action.payload.activity,
      };
    }
    case ActivityActionTypes.SET_SHOW_DIALOG: {
      return {
        ...state,
        showDialog: action.payload.showDialog,
      };
    }
    default:
      return state;
  }
};

export const ActivityProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(activityReducer, initialState);

  return (
    <ActivityContext.Provider value={{ state, dispatch }}>
      {children}
    </ActivityContext.Provider>
  );
};

export const useActivity = () => {
  const context = useContext(ActivityContext);
  if (!context) {
    throw new Error('useActivity must be used within a ActivityProvider');
  }
  return context;
};
