import React, { useEffect } from 'react';
import { useLogging, useNotification, useAuth, useProfile } from 'src/context';
import { Box, Button, Select, Textarea, Option } from '@mui/joy';
import { styled } from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/pro-solid-svg-icons';
import { useGetEntityActivities } from 'src/hooks';
import { createActivity, updateActivity } from 'src/api';
import { activityData } from 'src/components/Activity/constants';
import { ActivitySelectOption } from 'src/components/Activity';

const ActivityContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '100%',
  padding: '0.75rem',
  borderRadius: '0.75rem',
  border: '1px solid var(--Neutral-300, #CACCCD)',
  background: 'var(--Common-White, #FFF)',
  /* shadow-lg */
  boxShadow:
    '0px 2px 8px -2px rgba(21, 21, 21, 0.08), 0px 12px 16px -4px rgba(21, 21, 21, 0.08)',
});

const AddEditActivity = ({ item = null, onClose }) => {
  const { user } = useAuth();
  const log = useLogging();
  const { setNotification } = useNotification();
  const { details: providerDetails } = useProfile();
  const { data, mutate } = useGetEntityActivities(providerDetails?.provider_id);

  const [activity, setActivity] = React.useState({
    label: 'Phone Call',
    value: 'call',
  });
  const [details, setDetails] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const isEditing = !!item;

  useEffect(() => {
    if (!item) return;
    const activity = activityData.find(
      (i) => i.value === item.type?.toLowerCase()
    );
    setActivity(activity);
    setDetails(item.details);
  }, [item]);

  const handleActivityChange = (e, activity: string) => {
    const newActivity = activityData?.find((item) => item.value === activity);
    setActivity(newActivity);
  };

  const handleDetailsChange = (e) => {
    setDetails(e.target.value);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      if (isEditing) {
        await updateActivity({
          activityId: item.id,
          data: {
            type: activity?.value,
            details,
          },
        });
        // optimistically update with submission
        mutate((data) => {
          return {
            ...data,
            results: data.results?.map((result) => {
              if (result.id === item.id) {
                return {
                  ...result,
                  type: activity?.value,
                  details,
                };
              }
              return result;
            }),
          };
        });
        log.event('Update Activity', {
          location: 'Profile',
          data: {
            type: activity?.value,
            details,
          },
          user: user,
        });
      } else {
        const response = await createActivity({
          providerId: providerDetails?.provider_id,
          data: {
            type: activity?.value,
            details,
          },
        });

        // optimistically update with response
        if (response) {
          mutate((data) => {
            // update data.results with new activity
            return {
              ...data,
              results: [response, ...data.results].sort(
                (a, b) =>
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime()
              ),
            };
          });
        }

        log.event('Add Activity', {
          location: 'Profile',
          data: response,
          user: user,
        });
      }
    } catch (err) {
      log.exception('Failed to save activity', {
        tags: {
          location: 'Profile',
          error: err,
        },
      });
      setNotification({
        title: 'Error',
        message: 'Failed to save activity',
        type: 'error',
      });
    } finally {
      mutate();
      onClose();
      setIsLoading(false);
    }
  };

  return (
    <ActivityContainer>
      <Select
        value={activity?.value}
        onChange={handleActivityChange}
        slotProps={{
          listbox: { sx: { zIndex: 9999 } },
        }}
        renderValue={(value) => {
          const selectedActivity = activityData.find(
            (item) => item.value === value.value
          );
          return <ActivitySelectOption item={selectedActivity} />;
        }}
      >
        {activityData.map((item) => (
          <Option key={item.value} value={item.value}>
            <ActivitySelectOption item={item} />
          </Option>
        ))}
      </Select>
      <Textarea
        color="neutral"
        variant="outlined"
        placeholder="Add new activity..."
        value={details}
        onChange={handleDetailsChange}
        autoFocus
        onFocus={(e) => e.target.select()}
        sx={{
          width: '100%',
          height: '100%',
          minHeight: '3.75rem',
          maxHeight: '15rem',
          padding: '0.5rem',
          background: '#F5F7F8',
        }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '0.5rem' }}>
        <Button color="neutral" variant="soft" onClick={onClose}>
          {isEditing ? 'Discard Changes' : 'Cancel'}
        </Button>
        <Button
          variant="solid"
          loading={isLoading}
          sx={{
            backgroundColor: '#2391D9',
          }}
          startDecorator={!isEditing && <Icon icon={faBolt} />}
          onClick={onSubmit}
          disabled={!details || isLoading}
        >
          {isEditing ? 'Save' : `Log ${activity?.label}`}
        </Button>
      </Box>
    </ActivityContainer>
  );
};

export default AddEditActivity;
