import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import {
  Box,
  Button,
  Card,
  CardActions,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/joy';
import { faThumbsDown, faCopy } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { useLogging, useNotification, useProfile } from 'src/context';
import { medAICard } from '../styleConstants';
import {
  useGetSummary,
  useGetFocused,
  reportBadSummary,
} from 'src/components/Profiles/hooks';
import MedAISparkles from './MedAISparkles';
import MedAILoading from './MedAILoading';
import NewAddToListButton from './NewAddToListButton';

const ProfileSummary = () => {
  const log = useLogging();
  const { setNotification } = useNotification();

  const { details } = useProfile();

  const [showMoreInsights, setShowMoreInsights] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isReported, setIsReported] = useState(false);

  const { data: summaryData, isLoading: isSummaryLoading } = useGetSummary({
    id: details?.provider_id,
  });

  const { data: focusedData, isLoading: isFocusedLoading } = useGetFocused({
    id: details?.provider_id,
  });

  // sets the tooltip values back to defaults
  useEffect(() => {
    if (isCopied) {
      const timeout = setTimeout(() => {
        setIsCopied(false);
      }, 5000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isCopied]);

  const handleViewMoreInsights = () => {
    setShowMoreInsights(true);
  };

  const handleReportInaccurateSummary = async () => {
    if (!focusedData?.summary_id) {
      setNotification({
        title: 'Error',
        message: 'No id found for summary',
        type: 'error',
      });
      return;
    }
    try {
      await reportBadSummary(focusedData?.summary_id);
      log.event('Reported inaccurate summary', {
        source: 'ProfileSummary',
        provider: focusedData?.summary_id,
      });
    } catch (err) {
      log.exception('Error reporting inaccurate summary', {
        tags: {
          source: 'ProfileSummary',
          error: err,
        },
      });
      setNotification({
        title: 'Error',
        message: 'Error reporting inaccurate summary',
        type: 'error',
      });
    } finally {
      setIsReported(true);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(
        showMoreInsights && !isFocusedLoading
          ? focusedData?.summary
          : summaryData?.summary
      )
      .then(() => {
        setIsCopied(true);
        log.event('Copied to clipboard', {
          source: 'ProfileSummary',
          provider: details?.provider_id,
        });
      })
      .catch((err) => {
        log.exception('Error copying to clipboard', {
          tags: {
            source: 'ProfileSummary',
            provider: details?.provider_id,
            error: err,
          },
        });

        setNotification({
          title: 'Error',
          message: 'Error copying to clipboard',
          type: 'error',
        });
      });
  };

  return (
    <Card sx={medAICard}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        aria-label="Organization"
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <MedAISparkles width={31} height={24} />
          <Typography
            level="title-md"
            sx={{
              fontWeight: 600,
            }}
          >
            MedScout Intelligence
          </Typography>
        </Box>
      </Box>

      {!isSummaryLoading && !summaryData?.summary ? (
        <Typography
          sx={{ paddingLeft: '3.5rem', paddingBottom: '1rem' }}
          level="title-sm"
        >
          Summary unavailable
        </Typography>
      ) : (
        <Box sx={{ paddingTop: '0.5rem' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <Typography
              sx={{
                color: '#292B33',
                fontSize: '0.938rem',
                fontWeight: 400,
                lineHeight: '1.406rem',
              }}
            >
              {summaryData?.summary}
            </Typography>
            {showMoreInsights && !isFocusedLoading && (
              <Typography
                sx={{
                  color: '#292B33',
                  fontSize: '0.938rem',
                  fontWeight: 400,
                  lineHeight: '1.406rem',
                }}
              >
                {focusedData?.summary}
              </Typography>
            )}
          </Box>
          {(isSummaryLoading || (showMoreInsights && isFocusedLoading)) && (
            <MedAILoading
              isLoading={showMoreInsights ? isFocusedLoading : isSummaryLoading}
            />
          )}
        </Box>
      )}

      <CardActions
        sx={{
          paddingTop: '0.5rem',
        }}
      >
        {showMoreInsights && !isFocusedLoading ? (
          <motion.div
            style={{ width: '100%' }}
            transition={{
              y: {
                duration: 0.4,
              },
            }}
            animate={{
              y: ['100%', '0%', '35%', '0%'],
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <NewAddToListButton providers={[details]} />
              <Box
                sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
              >
                <Tooltip
                  title={isCopied ? 'Copied!' : 'Copy Summary'}
                  color={isCopied ? 'success' : 'primary'}
                  placement="top"
                  arrow
                >
                  <IconButton
                    variant="plain"
                    aria-label="Copy to clipboard"
                    onClick={copyToClipboard}
                    sx={{
                      '&:hover': {
                        background: 'transparent !important',
                      },
                    }}
                  >
                    <Icon icon={faCopy} color="#489EE3" size="lg" />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={isReported ? 'Thank you!' : 'Unhelpful'}
                  color={isReported ? 'success' : 'primary'}
                  placement="top"
                  arrow
                >
                  {/* Put in a span so the tooltip is valid for the disabled button */}
                  <span>
                    <IconButton
                      variant="plain"
                      aria-label="Report inaccurate summary"
                      onClick={handleReportInaccurateSummary}
                      disabled={isReported}
                      sx={{
                        '&:hover': {
                          background: 'transparent !important',
                        },
                      }}
                    >
                      <Icon
                        icon={faThumbsDown}
                        color={isReported ? '#76baea' : '#489EE3'}
                        size="lg"
                        onClick={handleReportInaccurateSummary}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            </Box>
          </motion.div>
        ) : (
          !isSummaryLoading &&
          !showMoreInsights && (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
              }}
            >
              <Button
                variant="plain"
                sx={{
                  padding: 0,
                  textDecoration: 'underline',
                  color: '#021B9C',
                  ':hover': { background: 'none' },
                }}
                onClick={handleViewMoreInsights}
              >
                View more insights
              </Button>
            </Box>
          )
        )}
      </CardActions>
    </Card>
  );
};

export default ProfileSummary;
