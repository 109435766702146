import React, { useMemo } from 'react';
import { Autocomplete } from '@mui/joy';
import { useGetSavedSearchesById } from 'src/hooks';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import { useLogging, useNewProfile } from 'src/context';
import NewFilterOption from './NewFilterOption';
import { useRouter } from 'next/router';

interface FilterBySavedSearchProps {
  selectedSavedSearch: any;
  setSelectedSavedSearch: (search: any) => void;
  context: 'provider' | 'site';
}

const FilterBySavedSearch = ({
  selectedSavedSearch,
  setSelectedSavedSearch,
  context,
}: FilterBySavedSearchProps) => {
  const router = useRouter();
  const { id } = router.query as { id: string };
  const log = useLogging();
  const { setSiteProviderQueryParams, setVolumeByLocationQueryParams } =
    useNewProfile();

  // query params to use
  const queryParamsMap = {
    provider: setVolumeByLocationQueryParams,
    site: setSiteProviderQueryParams,
  };

  const locationMap = {
    provider: 'LocationMix',
    site: 'TopProviders',
  };

  const serializerMap = {
    provider: null,
    site: 'top_providers',
  };

  const { data: savedSearches } = useGetSavedSearchesById({
    provider_id: id || null,
    serializer: serializerMap[context],
  });

  const orderedSearches = useMemo(() => {
    if (!savedSearches) return [];

    return savedSearches
      .sort((a, b) => {
        // First, sort by `search_name` alphabetically
        if (a.search_name < b.search_name) return -1;
        if (a.search_name > b.search_name) return 1;
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      })
      .sort((a, b) => {
        // Then, prioritize items with non-zero `matching_providers` and order them by count
        if (a.matching_providers && b.matching_providers) {
          return b.matching_providers - a.matching_providers;
        }
        if (a.matching_providers) return -1;
        if (b.matching_providers) return 1;
        return 0;
      });
  }, [savedSearches]);

  const handleSavedSearchClick = (e, search) => {
    e.stopPropagation();
    setSelectedSavedSearch(search);
    let cpt = [];
    let hcpcs = [];
    let icd = [];
    let drg = [];
    let icdp = [];

    // Add cpt and hcpcs arrays to the newValues array
    if (search?.filters?.cpt?.length > 0) {
      cpt = [...search.filters.cpt];
    }

    if (search?.filters?.hcpcs?.length > 0) {
      hcpcs = [...search.filters.hcpcs];
    }

    if (search?.filters?.icd?.length > 0) {
      icd = [...search.filters.icd];
    }

    if (search?.filters?.drg?.length > 0) {
      drg = [...search.filters.drg];
    }

    if (search?.filters?.icdp?.length > 0) {
      icdp = [...search.filters.icdp];
    }

    queryParamsMap[context]({
      cpt,
      hcpcs,
      icd,
      drg,
      icdp,
    });

    log.event('Saved Search Clicked', {
      source: locationMap[context],
      search: search,
    });
  };

  const isValidSavedSearch = useMemo(() => {
    if (!selectedSavedSearch) return true;

    const hasProcedureCodes =
      selectedSavedSearch?.filters?.cpt?.length > 0 ||
      selectedSavedSearch?.filters?.hcpcs?.length > 0 ||
      selectedSavedSearch?.filters?.icd?.length > 0 ||
      selectedSavedSearch?.filters?.drg?.length > 0 ||
      selectedSavedSearch?.filters?.icdp?.length > 0;

    return hasProcedureCodes;
  }, [selectedSavedSearch]);

  return (
    <Autocomplete
      sx={{ width: '100%', marginBottom: '0.25rem' }}
      placeholder="Filter by a saved search..."
      value={selectedSavedSearch}
      options={orderedSearches || []}
      getOptionKey={(option: any) => option.id}
      getOptionLabel={(option: any) => option.search_name || option.name}
      getOptionDisabled={(option: any) => {
        return context === 'site' && option.matching_providers === 0;
      }}
      isOptionEqualToValue={(option, value) => {
        return option.id === value?.id;
      }}
      renderOption={(props, option) => {
        const isSelected = selectedSavedSearch?.id === option.id;
        const isDisabled =
          context === 'site' && option.matching_providers === 0;
        return (
          <NewFilterOption
            key={`option_${option.id}`}
            props={props}
            option={option}
            isSelected={isSelected}
            isDisabled={isDisabled}
          />
        );
      }}
      slotProps={{
        listbox: {
          sx: {
            width: '100%',
            maxHeight: '20rem',
            overflow: 'hidden',
            overflowY: 'auto',
          },
        },
        input: {
          sx: {
            fontSize: '1rem', // Change the font size of the input field here
            fontWeight: 400,
            lineHeight: '1.5rem',
            color: 'text.primary',
            ...(!isValidSavedSearch && {
              color: 'text.disabled', // Ensure disabled text color
              opacity: 0.5, // Adjust opacity for disabled look
            }),
          },
        },
      }}
      startDecorator={<Icon icon={faFilter} color="#555E68" />}
      onChange={handleSavedSearchClick}
    />
  );
};

export default FilterBySavedSearch;
