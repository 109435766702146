import { Box, Typography } from '@mui/joy';
import React from 'react';
import { formatPhoneNumber } from 'src/utils';

const InfoRow = ({ item }) => {
  const isPhone = item.title === 'Phone';
  const value = isPhone ? formatPhoneNumber(item.value) : item.value;
  return (
    <Box
      sx={{
        display: 'flex',
        height: '1.2rem',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
      }}
    >
      <Typography
        sx={{
          color: '#134170',
          fontFamily: 'Inter',
          fontSize: '0.875rem' /* 14px */,
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '146%' /* 20.44px */,
        }}
      >
        {item.title}
      </Typography>
      <Typography
        sx={{
          color: 'var(--Common-White, #FFF)',
          textAlign: 'right',
          fontFamily: 'Inter',
          fontSize: '0.875rem' /* 14px */,
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '146%' /* 20.44px */,
        }}
      >
        {value || 'N/A'}
      </Typography>
    </Box>
  );
};

export default InfoRow;
