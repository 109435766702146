import { Box, IconButton, Typography } from '@mui/joy';
import React from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-solid-svg-icons';

const FilterPill = ({ selectedOptions, handleClearFilters }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        maxHeight: '1.5rem',
        padding: '0 0 0 .5rem',
        alignItems: 'center',
        borderRadius: '1.5rem',
        background: 'var(--Neutral-200, #DDDFE0)',
        whiteSpace: 'nowrap', // Prevent text wrapping
        flexGrow: 1, // Allow the Box to expand
      }}
    >
      <Typography
        sx={{
          color: 'var(--Neutral-800, #2C2E2F)',
          fontSize: '.875rem' /* 14px */,
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '150%' /* 21px */,
        }}
      >
        {selectedOptions.length} Filter
        {selectedOptions.length > 1 ? 's' : ''}
      </Typography>
      <IconButton
        onClick={handleClearFilters}
        color="neutral"
        variant="outlined"
        aria-label="Clear Filters"
        sx={{ border: 'none' }}
      >
        <Icon icon={faClose} size="sm" />
      </IconButton>
    </Box>
  );
};

export default FilterPill;
