export const formatDayAbbr = (day: string): string => {
  switch (day.toLowerCase()) {
    case 'tuesday':
      return 'Tu';
    case 'thursday':
      return 'Th';
    case 'saturday':
      return 'Sa';
    case 'sunday':
      return 'Su';
    default:
      return day.charAt(0).toUpperCase();
  }
};
