import { request } from 'src/api/request';

export const getActivities = async ({ queryString }) => {
  return request({
    url: `v1/medrm/activity/${queryString}`,
    method: 'GET',
  });
};

export const getEntityActivities = async ({ providerId }) => {
  if (!providerId) return null;
  return request({
    url: `v1/medrm/activity/entity/${providerId}/`,
    method: 'GET',
  });
};

export const getActivityById = async ({ activityId }) => {
  if (!activityId) return null;
  return request({
    url: `v1/medrm/activity/${activityId}/`,
    method: 'GET',
  });
};

export const createActivity = async ({ providerId, data }) => {
  if (!providerId || !data) return null;
  return request({
    url: `v1/medrm/activity/entity/${providerId}/`,
    method: 'POST',
    data,
  });
};

export const updateActivity = async ({ activityId, data }) => {
  if (!activityId || !data) return null;
  return request({
    url: `v1/medrm/activity/${activityId}/`,
    method: 'PATCH',
    data,
  });
};

export const deleteActivity = async ({ activityId }) => {
  if (!activityId) return null;
  return request({
    url: `v1/medrm/activity/${activityId}/`,
    method: 'DELETE',
  });
};
