import React, {
  createContext,
  useReducer,
  ReactNode,
  Dispatch,
  useContext,
} from 'react';

type State = {
  clickedButtons: string[];
};

const CRMButtonActionTypes: { [key: string]: string } = {
  CLICK_BUTTON: 'CLICK_BUTTON',
  ENABLE_BUTTON: 'ENABLE_BUTTON',
};
type ButtonActions = {
  type: keyof typeof CRMButtonActionTypes;
  payload: string;
};

const initialState: State = {
  clickedButtons: [],
};

const CRMButtonReducer = (state: State, action: ButtonActions): State => {
  switch (action.type) {
    case CRMButtonActionTypes.CLICK_BUTTON:
      return {
        ...state,
        clickedButtons: [...state.clickedButtons, action.payload],
      };
    case CRMButtonActionTypes.ENABLE_BUTTON:
      return {
        ...state,
        clickedButtons: state.clickedButtons.filter(
          (id) => id !== action.payload
        ),
      };
    default:
      return state;
  }
};

const PushToCRMButtonContext = createContext<{
  state: State;
  dispatch: Dispatch<ButtonActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const useCRMButtonContext = () => {
  const context = useContext(PushToCRMButtonContext);

  if (!context)
    throw new Error(
      'useCRMButtonContext must be used within PushToCRMButtonProvider component'
    );

  const {
    state: { clickedButtons },
    dispatch,
  } = context;

  const setClickedButtons = (payload: string) => {
    dispatch({
      type: CRMButtonActionTypes.CLICK_BUTTON,
      payload,
    });
  };

  const reenableButton = (payload: string) => {
    dispatch({
      type: CRMButtonActionTypes.ENABLE_BUTTON,
      payload,
    });
  };

  return { clickedButtons, setClickedButtons, reenableButton };
};

const PushToCRMButtonProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(CRMButtonReducer, initialState);

  return (
    <PushToCRMButtonContext.Provider value={{ state, dispatch }}>
      {children}
    </PushToCRMButtonContext.Provider>
  );
};

export { PushToCRMButtonContext, PushToCRMButtonProvider };
