import React from 'react';
import { Box } from '@mui/joy';

const GridContainer = ({
  singleColumn = false,
  children,
}: {
  singleColumn?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: singleColumn
          ? '1fr'
          : { xs: '1fr', md: '1fr 1fr' },
        gap: '1rem',
        width: '100%',
      }}
    >
      {children}
    </Box>
  );
};

export default GridContainer;
