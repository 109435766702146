import { Link, Grid, Box, Checkbox, useTheme, styled } from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { grey } from '@mui/material/colors';
import {
  faUpRightFromSquare,
  faExternalLinkAlt,
} from '@fortawesome/pro-solid-svg-icons';
import {
  TypeIcon,
  Tags,
  PushToCRMGroup,
  MedRMStatus,
  NewProviderListTags,
} from 'src/components';
import { useProspectSearch } from 'src/context';
import ProspectDetailsCell from './ProspectDetailsCell';
import { useFlags } from 'launchdarkly-react-client-sdk';

const StyledHeader = styled('h2')`
  font-size: 1rem;
  font-weight: 600;
  color: ${grey[600]};
  white-space: nowrap;

  & em {
    color: ${grey[900]};
  }
`;

const StyledParagraph = styled('p')`
  font-size: 0.65rem;
  font-weight: 400;
  color: ${grey[600]};
  white-space: nowrap;

  & em {
    font-weight: 600;
    color: ${grey[900]};
  }
`;

interface ProspectSearchResultProps {
  result;
  select?: boolean;
  handleSelect?: (e: React.ChangeEvent<HTMLInputElement>, result) => void;
  variant?: 'compact' | 'regular' | 'expanded';
  loading?: boolean;
}

const ProspectSearchResult: React.FC<ProspectSearchResultProps> = ({
  result,
  select,
  handleSelect,
  variant = 'regular',
  loading = false,
}) => {
  const { sameTabRouting } = useFlags();

  const { prospectMode } = useProspectSearch();
  const theme = useTheme();
  const hasLists = result.provider_lists?.length > 0;
  const hasTags = result.tags?.length > 0;

  // For Discovery only
  const baseLink = `/profiles/${result.content_type?.toLowerCase()}/${
    result.provider_id
  }`;
  const queryString =
    prospectMode === 'sending'
      ? `/patient_patterns?relationship=outbound&mode=receiving`
      : '';
  switch (variant) {
    case 'regular':
      return (
        <>
          <Box
            display="grid"
            gridTemplateColumns="0.75fr 5.25fr 3fr 3fr"
            alignItems="flex-start"
            pr={1}
            sx={{ width: '100%' }}
            data-testid={`searchResultItem_${result.id}`}
          >
            <Box sx={{ maxWidth: '2.5rem' }}>
              <Checkbox
                aria-label="Provider"
                id={result.provider_id}
                checked={select}
                onChange={(e) => handleSelect(e, result)}
              />
            </Box>
            <Box>
              <p
                style={{
                  alignSelf: 'center',
                  fontSize: '1rem',
                  paddingTop: '0.5rem',
                  paddingBottom: 0,
                  fontWeight: 500,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <Link
                  href={`${baseLink}${queryString}`}
                  target={sameTabRouting ? '_self' : '_blank'}
                  rel="noopener noreferrer"
                  aria-label="View Profile"
                >
                  <TypeIcon type={result.type || result.content_type} />
                  {result.name || 'n/a'}
                  <Icon
                    icon={faExternalLinkAlt}
                    size="sm"
                    style={{ marginLeft: '0.5rem' }}
                  />
                </Link>
              </p>
              <p>
                {result.city}, {result.state}
              </p>

              <p>{result.specialty}</p>
            </Box>
            <Box
              style={{
                display: 'flex',
                padding: '0.5rem 0',
                alignContent: 'center',
                width: '100%',
                height: '100%',
              }}
            >
              <MedRMStatus provider={result} />
            </Box>
            <Box
              style={{
                padding: '0.5rem 0 0.5rem 0.5rem',
                textAlign: 'right',
                width: '100%',
              }}
            >
              <ProspectDetailsCell result={result} loading={loading} />
            </Box>
          </Box>

          <Box
            sx={{
              px: 1,
              pb: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              background: `linear-gradient(white, ${theme.palette.highlight.main}22)`,
            }}
          >
            <Box sx={{ maxWidth: '50%' }}>
              {(hasLists || hasTags) && (
                <>
                  <Tags
                    tagType="tag"
                    tags={result.tags}
                    sx={{ fontSize: '0.75rem', pb: 1 }}
                  />
                  <NewProviderListTags lists={result.provider_lists} />
                </>
              )}
            </Box>
            <Box sx={{ maxWidth: '50%' }}>
              <PushToCRMGroup provider={result} />
            </Box>
          </Box>
        </>
      );
    case 'compact':
      return (
        <Link
          href={`/profiles/${result.type}/${result.provider_id}`}
          target={sameTabRouting ? '_self' : '_blank'}
          rel="noopener noreferrer"
          sx={{
            width: '100%',
            height: '100%',
            textDecoration: 'none',
          }}
        >
          <Grid display="flex" flexDirection="row">
            <Grid
              item
              xs={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
              p="0.5rem 0.75rem 0.5rem 0.75rem"
            >
              <TypeIcon
                type={result.type}
                style={{
                  color: grey[700],
                }}
              />
            </Grid>
            <Grid item flex={1} display="flex" flexDirection="column">
              <StyledHeader
                dangerouslySetInnerHTML={{
                  __html: result.name,
                }}
              ></StyledHeader>
              <StyledParagraph
                dangerouslySetInnerHTML={{
                  __html: `${result.city}, ${result.state}`,
                }}
              ></StyledParagraph>
              <StyledParagraph
                dangerouslySetInnerHTML={{
                  __html: result.specialty,
                }}
              ></StyledParagraph>
            </Grid>
            <Grid
              item
              xs={2}
              display="flex"
              alignItems="flex-start"
              justifyContent="center"
              p="0.5rem 0.75rem 0.5rem 0.75rem"
            >
              <Icon icon={faUpRightFromSquare} fontSize="0.8rem" />
            </Grid>
          </Grid>
        </Link>
      );
  }
};

export default ProspectSearchResult;
