import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import type {} from '@mui/x-data-grid-premium/themeAugmentation';

const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#103D80',
      light: '#efeff1',
      dark: '#081450',
    },
    action: {
      hover: '#103D80',
    },
    highlight: {
      light: '#E0EDEB',
      main: '#32d9c3',
      dark: '#4F6370',
    },
    fill: {
      main: '#2C3D49',
      dark: '#1E2A35',
      light: '#4C6A80',
    },
    filter: {
      cpt: { main: '#6AA4DE', light: '#B8D5F0' },
      hcpcs: { main: '#B583BE', light: '#D7BCDC' },
      icd: { main: '#6B9993', light: '#ADD9D3' },
      drg: { main: '#D07575', light: '#F2C2C2' },
      drug: { main: '#D07575', light: '#F2C2C2' },
      apc: { main: '#806A5D', light: '#D9BDAD' },
      icdp: { main: '#526384', light: '#B8C7E5' },
      year: { main: '#998F6A', light: '#CCC8B8' },
      spec: { main: '#7D3647', light: '#E5B8C3' },
    },
    chart: [
      { main: '#6AA4DE' },
      { main: '#B583BE' },
      { main: '#6B9993' },
      { main: '#D07575' },
      { main: '#806A5D' },
      { main: '#526384' },
      { main: '#998F6A' },
      { main: '#7D3647' },
      { main: '#ea5545' },
      { main: '#f46a9b' },
      { main: '#ef9b20' },
      { main: '#edbf33' },
      { main: '#ede15b' },
      { main: '#bdcf32' },
      { main: '#87bc45' },
      { main: '#27aeef' },
      { main: '#b33dc6' },
    ],
    territory: [
      { main: '#6929C4' },
      { main: '#9f1853' },
      { main: '#198038' },
      { main: '#b28600' },
      { main: '#8a3800' },
      { main: '#1192e8' },
      { main: '#fa4d56' },
      { main: '#002d9c' },
      { main: '#009d9a' },
      { main: '#a56eff' },
      { main: '#005d5d' },
      { main: '#570408' },
      { main: '#ee538b' },
      { main: '#012749' },
      { main: '#87bc45' },
      { main: '#27aeef' },
      { main: '#b33dc6' },
    ],
  },
});

export default createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif',
    h2: {
      fontSize: '1.75rem',
      fontWeight: '600',
      padding: '0.5rem 0',
      textTransform: 'capitalize',
    },
    h4: {
      fontSize: '1.15rem',
      fontWeight: '400',
      padding: '0.5rem 0',
    },
    h5: {
      fontStyle: 'normal',
      fontSize: '1.15rem',
      fontWeight: '400',
    },
    h6: {
      fontStyle: 'italic',
      fontSize: '0.75rem',
    },
    dialogTitle: {
      color: grey[600],
      fontWeight: '400',
      textAlign: 'left',
    },
    listGroup: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0.25rem 0.75rem 0.25rem 1rem',
      backgroundColor: lightTheme.palette.primary.light,
      fontWeight: '600',
      borderTop: `1px solid ${grey[300]}`,
      borderBottom: `1px solid ${grey[300]}`,
      whiteSpace: 'nowrap',
    },
    volumeData: {
      fontSize: '1rem',
      fontWeight: '600',
      color: lightTheme.palette.fill.dark,
    },
    subtitle2: {
      textAlign: 'left',
      fontSize: '1.1em',
      fontWeight: 600,
      paddingTop: '5px',
      paddingBottom: '5px',
    },
  },
  palette: {
    ...lightTheme.palette,
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          // '& .MuiDataGrid-main, & .MuiDataGrid-footerContainer': {
          //   backgroundColor: 'white',
          // },
          // '& .MuiDataGrid-toolbarContainer': {
          //   justifyContent: 'space-between',
          //   padding: '0',
          // },
          // '& .MuiDataGrid-row:hover, & .Mui-hovered': {
          //   backgroundColor: grey[100],
          //   '&.Mui-hovered': {
          //     backgroundColor: grey[200],
          //   },
          // },
          // '& .MuiDataGrid-lightHeader': {
          //   backgroundColor: '#E5F3FB',
          // },
          // '& .MuiDataGrid-lightCell': {
          //   backgroundColor: '#E5F3FB',
          //   borderLeft: `1px solid #E5F3FB`,
          //   borderRight: `1px solid #E5F3FB`,
          // },
          // '& .MuiDataGrid-columnHeaders': {
          //   minHeight: 'unset !important',
          //   '& .MuiDataGrid-columnSeparator': {
          //     color: '#E5F3FB',
          //   },
          // },
          // '& .MuiDataGrid-groupHeader, & .MuiDataGrid-columnHeader': {
          //   paddingTop: '0 !important',
          // },
          // '& .MuiDataGrid-groupHeader': {
          //   backgroundColor: '#E5F3FB',
          // },
          // '& .MuiDataGrid-columnHeadersInner>div:first-of-type, & .MuiDataGrid-groupHeader, & .MuiDataGrid-columnHeader--emptyGroup':
          //   {
          //     // display: 'none !important',
          //     height: '0 !important',
          //   },
          // '& .MuiDataGrid-columnHeadersInner>div:only-child': {
          //   height: 'inherit !important',
          // },
          // '& .MuiDataGrid-columnHeaderTitleContainer': {
          //   border: 'none !important',
          // },
          // '& .MuiDataGrid-columnHeaderTitle': {
          //   color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
          //   /* title-sm */
          //   fontSize: '1rem',
          //   fontStyle: 'normal',
          //   fontWeight: 600,
          //   lineHeight: '142%' /* 19.88px */,
          // },
          // '& .MuiDataGrid-menuIcon *': {
          //   display: 'none !important',
          // },
          // '& .MuiDataGrid-pinnedColumnHeaders>div': {
          //   height: 'unset !important',
          // },
          // '& .MuiDataGrid-pinnedColumnHeaders, &  .MuiDataGrid-pinnedColumnHeaders--right':
          //   {
          //     backgroundColor: '#E5F3FB',
          //   },
          // '& .MuiDataGrid-pinnedColumn, & .MuiDataGrid-pinnedColumns--right': {
          //   backgroundColor: '#E5F3FB',
          // },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: lightTheme.palette.primary.light,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: '600',
          textTransform: 'capitalize',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          padding: '0 1rem',
          borderBottom: `3px solid ${lightTheme.palette.highlight.main}`,
          boxShadow: 'inset 0 -3px 5px 0 rgba(0, 0, 0, 0.25)',
          backgroundColor: lightTheme.palette.fill.main,
          '& .MuiTab-root': {
            color: 'white',
            opacity: '0.5',
            textTransform: 'capitalize',
            minHeight: '3.25rem',
            '&.Mui-selected, &:hover': {
              color: 'white',
              opacity: '1',
            },
          },
          '& .MuiTabs-indicator': {
            height: '3px',
            backgroundColor: lightTheme.palette.highlight.main,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          width: '100%',
          minWidth: '30rem',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '1rem',
          fontSize: '1.25rem',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '1.6rem',
          backgroundColor: lightTheme.palette.primary.light,
          borderBottom: `1px solid ${grey[300]}`,
          color: grey[600],
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          borderTop: `1px solid ${grey[300]}`,
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            color: 'white',
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        message: {
          color: 'white',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          color: 'white',
          '& .MuiAlert-icon': {
            color: 'white',
          },
          '& .MuiAlert-message': {
            color: 'white',
          },
          '& .MuiAlert-action': {
            color: 'white',
          },
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          '& .MuiStepConnector-line': {
            borderColor: lightTheme.palette.grey[500],
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          width: '1.25rem',
          height: '1.25rem',
          borderRadius: '50%',
          marginY: '-4px',
          color: lightTheme.palette.grey[500],
          active: {
            color: lightTheme.palette.highlight.main,
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            color: '#616161',
          },
        },
        label: {
          color: lightTheme.palette.grey[500],
          fontWeight: 500,
          fontSize: '0.8rem',
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          '& .MuiStepConnector-line': {
            borderColor: lightTheme.palette.grey[500],
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          '& .MuiAutocomplete-option.Mui-focused': {
            backgroundColor: `${lightTheme.palette.highlight.light} !important`,
            borderLeft: `4px solid ${lightTheme.palette.highlight.main}`,
          },
        },
      },
    },
  },
});
