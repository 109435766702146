import { Box, Card, Tooltip, Typography } from '@mui/joy';
import { faInfoCircle, faUserMd } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { hasContent } from 'src/utils';
import InfoUnavailable from '../InfoUnavailable';
import { headerStyle } from '../styleConstants';
import ProfessionalMixList from './ProfessionalMixList';
import DataInfoPopover from 'src/components/DataInfoPopover';
import { useProfile } from 'src/context';

const ProfileProfessionalMix = () => {
  const { details } = useProfile();

  const mixes = details?.center?.professional_mix || [];

  return (
    <>
      <Card sx={{ marginBottom: '1.5rem', padding: 0 }}>
        <Box sx={headerStyle} aria-label="Hospital Affiliations">
          <Icon icon={faUserMd} />
          <Typography
            sx={{
              display: 'inline-block',
              marginLeft: '0.5rem',
            }}
          >
            Healthcare Professional Mix
            <Tooltip
              placement="top"
              variant="outlined"
              arrow
              title={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: 320,
                    justifyContent: 'center',
                    p: 1,
                  }}
                >
                  <DataInfoPopover view="centerProfile" />
                </Box>
              }
            >
              <Icon
                icon={faInfoCircle}
                size="xs"
                style={{
                  color: '#a9aaad',
                  marginLeft: '0.25rem',
                }}
              />
            </Tooltip>
          </Typography>
        </Box>
        <Box padding={'0 1rem 1rem 0'}>
          {hasContent(mixes) ? (
            <ProfessionalMixList professionalMixes={mixes} />
          ) : (
            <InfoUnavailable />
          )}
        </Box>
      </Card>
    </>
  );
};

export default ProfileProfessionalMix;
