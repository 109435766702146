import {
  Toolbar,
  Box,
  styled,
  useTheme,
  useMediaQuery,
  AppBar,
} from '@mui/material';
import {
  NextLinkComposed,
  SearchShade,
  ImpersonationBar,
} from 'src/components';
import ModuleTabs from './ModuleTabs';
import AccountMenu from './AccountMenu';
import GlobalSearch from '../GlobalSearch';
import { Container } from '@mui/joy';

const UserGroup = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding-left: 1rem;
`;

const Brand = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        pr: { xs: 0, md: 2 },
        pb: '0.25rem',
      }}
    >
      <NextLinkComposed to="/search">
        {!isMobile && (
          <img
            src={'/nav_logo.png'}
            alt="MedScout, Inc."
            style={{ height: '1.75rem' }}
            aria-label="MedScout Logo"
          />
        )}
        {isMobile && (
          <img
            src={'/medscout_logo_icon.svg'}
            alt="MedScout, Inc."
            style={{ height: '2.75rem', marginBottom: '-0.25rem' }}
          />
        )}
      </NextLinkComposed>
    </Box>
  );
};

/**
 * Persistant brand and navigation to top level application modules.
 * Includes account dropdown and superuser company context selector.
 *
 * @param {*}
 * @return {*}
 */
const PrimaryNav = () => {
  return (
    <>
      <AppBar
        className="nav-app-bar"
        position="static"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Container maxWidth={false}>
          <Toolbar variant="dense" disableGutters>
            <Brand />
            <SearchShade />
            <ModuleTabs />
            <UserGroup>
              <GlobalSearch />
              <AccountMenu />
            </UserGroup>
          </Toolbar>
        </Container>
      </AppBar>
      <ImpersonationBar />
    </>
  );
};

export default PrimaryNav;
