import { Box, Card, Typography } from '@mui/joy';
import { faUserTie } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { hasContent } from 'src/utils';
import { headerStyle } from './styleConstants';
import InfoUnavailable from './InfoUnavailable';
import DetailsDisplay from './ProfileDetails/DetailsDisplay';
import { useProfile } from 'src/context';

const ProfileExecTeam = () => {
  const { details } = useProfile();
  const team = details?.center?.boards || [];

  return (
    <Card sx={{ padding: 0 }}>
      <Box sx={headerStyle} aria-label="Organization">
        <Icon icon={faUserTie} />
        <Typography
          sx={{
            display: 'inline-block',
            marginLeft: '0.5rem',
          }}
        >
          Executive Team
        </Typography>
      </Box>
      <Box padding={'0 1rem 1rem'}>
        {hasContent(team) ? (
          <DetailsDisplay items={team} />
        ) : (
          <InfoUnavailable />
        )}
      </Box>
    </Card>
  );
};

export default ProfileExecTeam;
