import React, { useMemo } from 'react';
import { Box, Divider } from '@mui/joy';
import { styled } from '@mui/material';
import { SavedSearchHeader, Section } from './components';
import { useLogging, useSite, useAuth } from 'src/context';
import { SavedSearchActionTypes } from 'src/context/reducers/actionTypes';

const SavedSearchContainer = styled(Box)({
  position: 'absolute',
  height: '100%',
  width: '25.5rem',
  backgroundColor: 'white',
  borderRight: '1px solid #ccc',
  overflow: 'hidden',
});

const SectionsContainer = styled(Box)({
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  paddingBottom: 'var(--1, 0.5rem)',
});

const NewSearchMenu = () => {
  const { user } = useAuth();
  const log = useLogging();
  const sections = ['PERSONAL', 'COMPANY'];
  const { state, dispatch } = useSite();

  const toggleAccordion = (index) => {
    // index can be 0 or 1
    const newIndex =
      state?.savedSearch?.openAccordion === index
        ? index === 0
          ? 1
          : 0
        : index;
    dispatch({
      type: SavedSearchActionTypes.SET_ACCORDION,
      payload: newIndex,
    });
    log.event('toggleSavedSearchAccordion', {
      location: 'SavedSearch',
      action: 'onSubmit',
      object: index === 0 ? 'PERSONAL' : 'COMPANY',
      user: user,
    });
  };

  // group by type
  const groupedData = useMemo(() => {
    if (!state.savedSearch?.folders) return [];
    const folders = state.savedSearch.folders;
    const tempFolders = state.savedSearch?.tempFolders || [];

    const combined = [...tempFolders, ...folders];
    // group by type to remain in an array
    const grouped = combined?.reduce((acc, item) => {
      if (!acc[item.type]) {
        acc[item.type] = [];
      }

      acc[item.type].push(item);
      return acc;
    }, {});

    return Object.entries(grouped).map(([type, results]) => ({
      type,
      results,
    }));
  }, [state?.savedSearch?.folders, state?.savedSearch?.tempFolders]);

  return (
    <SavedSearchContainer>
      <SavedSearchHeader />
      <Divider />
      <SectionsContainer>
        {sections.map((name, index) => {
          const section = groupedData.find((group) => group.type === name);
          return (
            <Section
              key={index}
              index={index}
              name={name}
              section={section}
              toggleAccordion={toggleAccordion}
            />
          );
        })}
      </SectionsContainer>
    </SavedSearchContainer>
  );
};

export default NewSearchMenu;
