import React from 'react';
import Image from 'next/image';
import salesforce from '/public/brands/salesforce.svg';
import hubspot from '/public/brands/hubspot.svg';
import dynamics from '/public/brands/dynamics.svg';
import pipedrive from '/public/brands/pipedrive.svg';
import zoho_allblue from '/public/brands/zoho_allblue.svg';
import medrm from '/public/brands/medrm.svg';
import { faArrowRightArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

interface BrandIconProps {
  brand: string;
  height?: string;
  width?: string;
  invert?: boolean;
}

const BrandIcon = ({ brand, height, width, invert }: BrandIconProps) => {
  const isThirdParty = brand === 'third_party';
  const iconType = {
    salesforce: salesforce,
    hubspot: hubspot,
    microsoftdynamics: dynamics,
    zohocrm: zoho_allblue,
    pipedrive: pipedrive,
    medrm: medrm,
  };

  return isThirdParty ? (
    <Icon
      icon={faArrowRightArrowLeft}
      color={invert ? 'white' : '#133D80'}
      style={{
        width: width || '2rem',
        height: height || '1.8rem',
      }}
      aria-label={`${brand} icon`}
    />
  ) : brand && iconType[brand] ? (
    <Image
      src={iconType[brand]}
      alt={`${brand} icon`}
      style={{
        width: width || '1.6rem',
        height: height || '1.6rem',
        filter: invert ? 'brightness(0) invert(1)' : 'none',
      }}
      aria-label={`${brand} icon`}
    />
  ) : null;
};

export default BrandIcon;
